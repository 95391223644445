import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const saveAnswer = (data, token) => {
  return axios
      .post(env.apiLink+"answers", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getAnswers = (token, para = {}) => {
  const query = objectToQueryString(para);
  return axios
      .get(env.apiLink+"answers?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getAnswerDetail = (id, token) => {
  return axios
      .get(env.apiLink+"answers/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}



export { saveAnswer, getAnswers, getAnswerDetail }