const splitter = '<!------------->'

const replaceMediaBeforeEdit = (finalHtmlEdit) => {
    // console.log('*********************************************')
    // console.log(finalHtmlEdit)
    while (finalHtmlEdit.includes('&amp;')) {
        finalHtmlEdit = finalHtmlEdit.replace('&amp;', '&')
    }    
    // console.log(finalHtmlEdit)
    // console.log('*********************************************')
    const h1s = document.getElementById('desc-div').getElementsByTagName('h1');
    for (let h1 of h1s) {
        const as = h1.getElementsByTagName('a');
        if (as.length > 0) {
            const text = as[0].text
            const src = as[0].toString()
            if (text == '[Audio]') {
                const str = '<h1><a href="'+src+'">'+text+'</a></h1>'
                console.log(str)
                finalHtmlEdit = finalHtmlEdit.replace(str, splitter+'<audio controls><source src="'+src+'" /></audio>'+splitter)
            }
            if (text == '[Video]') {
                const str = '<h1><a href="'+src+'">'+text+'</a></h1>'
                console.log(str)
                finalHtmlEdit = finalHtmlEdit.replace(str, splitter+'<video controls><source src="'+src+'" /></video>'+splitter)
            }
        }
    }
    return finalHtmlEdit;
}

export { splitter, replaceMediaBeforeEdit }