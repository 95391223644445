import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import { useHistory, Link } from "react-router-dom"
import axios from "axios"
import NavBar from "./NavBar"
import env from "../env"
import { getCareers } from "../endpoints/career"
import "./styles/careers.css"
import justphoto from '../assets/image/justphoto.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faBookOpen } from '@fortawesome/free-solid-svg-icons'


const Careers = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    getCareers({}, cookies["auth"].token).then(res => {
        setCareers(res.data.data);
    })
  }, [cookies]);

  
  return (
    <>
      <NavBar />
      <div className="navOverlay">
        <div>
          <p className="textC">Course Series</p>
          <div className="cardC">
            {careers.map(c => {
                return (
                  <div className="cardsC">
                      <div>
                          <div class="imgAndDes">
                              <img src={justphoto} className="imgC" alt="" />
                              <div class="desC">
                                  {c.careerDescription}
                              </div>                        
                          </div>
                          <div className="textAndBtnC">
                            <p className="textCareers">{c.careerName}</p>
                            <Link to={'/careers/'+c.id}><button class="rounded-sm text-xs mt-1 mb-3 h-8 w-20 bg-gray-800 text-white">OPEN</button></Link>
                          </div>
                          <div className="fontAwesomeC">
                            <div className="fontAwesome">
                              <FontAwesomeIcon icon={faUserAlt} />
                              <p>0</p>
                            </div>
                            <div className="fontAwesome">
                              <FontAwesomeIcon icon={faBookOpen} />
                              <p>{c.careertopics.length}</p>
                            </div>   
                          </div>                     
                      </div>
                  </div>
                )
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Careers;
