import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import AdminNavBar from "../AdminNavBar";
import env from "../../env";
import { getUser } from "../../endpoints/users";
import Login from "../Login";
import { showYmDhm } from "../../common/timeFunc.js";

const UserDetail = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
	const { userId } = useParams();
	const [token, setToken] = useState("");
	const [currentUser, setCurrentUser] = useState({});
	const [user , setUser] = useState({});

  useEffect(() => {
		setCurrentUser(cookies["auth"].user);    
    setToken(cookies["auth"].token);		
		getUser(userId, cookies["auth"].token).then(res => {
			setUser(res.data.data);
		});
  }, [cookies]);

  
  return (
    <>
      <AdminNavBar />
      <div className="m-10 flex flex-col">
				<Link to="/admin/users" className="font-bold text-4xl hover:no-underline">&#8592;</Link>
        <span>Name : {user.name}</span>
				<span>Email : {user.email}</span>
				<span>Suspend : {(user.suspend != false)? 'Suspended' : 'False'}</span>
				<span>Register Date : {(typeof user.userlogs != 'undefined' && user.userlogs.length > 0) && showYmDhm(user.userlogs[0].createdAt)}</span>
				<span>Register Location : {(typeof user.userlogs != 'undefined' && user.userlogs.length > 0) && user.userlogs[0].city + '/' + user.userlogs[0].country}</span>
				{(typeof user.userlogs != 'undefined' && user.userlogs.length > 0) &&
					<>
					<span className="underline">Access Logs</span>
					<table>
						<tr>
							<th>IP</th>
							<th>City</th>
							<th>Country</th>
							<th>Timezone</th>
							<th>Lat/Lng</th>
							<th>Status</th>
							<th>Access Date</th>
						</tr>	
						{user.userlogs.map(log => {
							return (
								<tr key={log.id}>
									<td>{log.ip}</td>
									<td>{log.city}</td>
									<td>{log.country}</td>
									<td>{log.timezone}</td>
									<td>{log.latlng}</td>
									<td>{log.status}</td>
									<td>{showYmDhm(log.createdAt)}</td>
								</tr>
							)
						})}					
					</table>
					</>
				}
      </div>
    </>
  );
};
export default UserDetail;
