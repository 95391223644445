const showMmSsFromSec = (sec) => {
  let timerStr = "";
  if (sec > 59) {
    let mm = Math.floor(sec / 60);
    let ss = sec % 60;
    timerStr = ((mm < 10) ? '0' : '') + mm + ':' + ((ss < 10) ? '0' : '') + ss;
  } else {
    if (sec > 9) {
      timerStr = "00:" + sec;
    } else {
      timerStr = "00:0" + sec;
    }
  }
  return timerStr;
}

const showYmD = (date) => {
  const dateObj = new Date(date);
  return dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear();
}

const showYmDhm = (date) => {
  const dateObj = new Date(date);
  return dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear() + ' ' + dateObj.getHours() + ':' + dateObj.getMinutes();
}

export { showMmSsFromSec, showYmD, showYmDhm }