import { deleteQuestion } from "../endpoints/questions";
import '../components/styles/userQuestion.css'

const QuestionDetail = (props) => {
  const question = props.question;
  const no = props.no;
  const index = no-1;
  const topicId = question.topicId;

  const handleDeleteQuestion = async (id) => {
    // if (props.answerCnt > 0) {
    //   alert('Sorry you cannot update/delete, because someone already answered!');
    //   return false;
    // }
    await deleteQuestion(id, props.token);
    props.loadQuestions(topicId);
  }

  const showMark = (mark) => {
    document.getElementsByClassName(mark)[index].classList.remove('invisible');
  } 

  const hideMark = (mark) => {
    document.getElementsByClassName(mark)[index].classList.add('invisible');
  } 

  return (
    <div id={'q-'+index} className={((props.parent === "userQuestion")? "hidden " : "") + "question-box mb-2"}>
      {/* Question {no}  */}
      <div className="questionDetailMain">
        <p className="questinHeader">
          {/* {question.questionCode} :  */}
          {question.questionTitle}</p>
        <div className="w-full">
          {(question.questionType == '1') &&
            <>
              <div className="w-full" dangerouslySetInnerHTML={{ __html: question.description}}></div>
            </>
          }
        </div>
      <br />
      {(props.parent === "userQuestion")?
      <div>
        <div className="questinAndAns"> 
          <input onClick={() => props.chooseAnswer((no-1), 1) } type="radio" class="option-input radio" name="example"/>
          <p className="mt-2"> &nbsp; {question.answer1}</p>
        </div>
        <div className="questinAndAns"> 
          <input onClick={() => props.chooseAnswer((no-1), 2) } type="radio" class="option-input radio" name="example"/>
          <p className="mt-2"> &nbsp; {question.answer2}</p>
        </div>
        <div className="questinAndAns"> 
          <input onClick={() => props.chooseAnswer((no-1), 3) } type="radio" class="option-input radio" name="example"/>
          <p className="mt-2"> &nbsp; {question.answer3}</p>
        </div>
        <div className="questinAndAns"> 
          <input onClick={() => props.chooseAnswer((no-1), 4) } type="radio" class="option-input radio" name="example"/>
          <input id={"null-answer-radio-"+index} type="radio" onClick={() => props.chooseAnswer((no-1), null)} type="radio" class="option-input radio hidden" style={{display: 'none'}} name="example" />
          <p className="mt-2"> &nbsp; {question.answer4}</p>
        </div>
      </div> : ''
      }
      {(props.parent == "adminQuestion")?
        <>
          <div className="flex">
            <div>Best Answer : &nbsp; </div>
            <div>Ans{question.bestAnswer}</div>
          </div>
          <button className="bg-yellow-200 p-1 mt-2 w-12 rounded text-gray-600" onClick={() => props.editQuestion(index, question.id)}>Edit</button>
          <button className="bg-red-200 p-1 ml-2 mt-2 w-14 rounded text-gray-600" onClick={() => handleDeleteQuestion(question.id)}>Delete</button>
        </>
        : ''
      }
      </div>
    </div>
  );
};

export default QuestionDetail;