import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const saveCareer = (data, token) => {
  return axios
      .post(env.apiLink+"career", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const saveCareerCourse = (data, token) => {
  return axios
      .post(env.apiLink+"career/courses", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteCareer = (id, token) => {
  return axios
      .delete(env.apiLink+"career/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteCareerCourse = (id, token) => {
  return axios
      .delete(env.apiLink+"career/course/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const getCareers = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"career?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const getCareerDetail = (id, token) => {
  return axios
      .get(env.apiLink+"career/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const getCareerCourses = (id, token) => {
  return axios
      .get(env.apiLink+"career/courses/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const updateCareerCourseOrder = (data, token) => {
  return axios.post(env.apiLink+"career/courses-sort", { careerCourses : data }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export { saveCareer, getCareers, deleteCareer, getCareerDetail, getCareerCourses, deleteCareerCourse, saveCareerCourse, updateCareerCourseOrder }