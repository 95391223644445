import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminNavBar from "./AdminNavBar";

const AdminDashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [user, setUser] = useState({ name: "", email: "" });
  const history = useHistory();
  useEffect(() => {
    setUser(cookies["auth"].user);    
  }, [cookies]);

  return (
    <>
      <AdminNavBar page="home" />
      <h3 className="user-header">Hello {user.name}</h3>
      {user.role === "admin" && (
        <div className="center mt-4">
          <NavLink className="btn btn-primary" to="/admin/users">
            {" "}
            Display all users
          </NavLink>
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
