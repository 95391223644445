import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const saveTeaching = (data, token) => {
  return axios
      .post(env.apiLink+"teaching", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data'
        },
      });
};

const deleteTeaching = (id, token) => {
  return axios
      .delete(env.apiLink+"teaching/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getTeaching = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"teaching?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

export { saveTeaching, getTeaching, deleteTeaching }