import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const updateUser = (data, token) => {
  return axios
      .put(env.apiLink+"users/update", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const suspendUser = (data, token) => {
  return axios
      .post(env.apiLink+"users/suspend/"+data.id, { suspend : data.suspend }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getUser = (id, token) => {
  return axios
      .get(env.apiLink+"users/"+id+"?include=log", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

export { updateUser, suspendUser, getUser };