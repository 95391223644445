import react, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import AdminNavBar from "./AdminNavBar";
import env from "../env";
import { suspendUser } from "../endpoints/users";
import { showYmD, showYmDhm } from "../common/timeFunc.js";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [cookies] = useCookies(["cookies"]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [token, setToken] = useState("");
  const history = useHistory();
  const [success, setSuccess] = useState("");

  const loadUsers = (token) => {
    axios
      .get(env.apiLink+"users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.users);
        setUsers(res.data.users);
      })
      .catch((error) => console.error(error));
  };

  const deleteUser = (id) => {
    setIsUpdating(true);
    axios
      .delete(`${env.apiLink}users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setUsers(users.filter((user) => user.id !== id));
        setIsUpdating(false);
      })
      .catch((error) => {
        console.error(error);
        setIsUpdating(false);
      });
  };
  const resetUserPassword = (id) => {
    setIsUpdating(true);
    axios
      .post(
        `${env.apiLink}users/reset-password`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setSuccess("User has recieved an email to reset his password");
        setIsUpdating(false);
        setTimeout(() => {
          setSuccess("");
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setIsUpdating(false);
      });
  };
  const handleSuspendUser = (id, status) => {
    if (status == true) {  
      if(!window.confirm("Do you really want to suspend this user ?")){
        return;
      }
    }
    suspendUser({ id : id, suspend : status}, token).then(res => {
      console.log(res.data)
      if (res.data.success == true) {
        alert("User has been changed suspend status");
        loadUsers(token);
      } else {
        alert("Oops! something went wrong");
      }
    })
  }

  const showDateLoc = (dateloc) => {
    if (dateloc !== null) {
      const dateLocArr = dateloc.split(' - ');
      return showYmDhm(dateLocArr[0]) + ' ' + dateLocArr[1];
    }
  }

  useEffect(() => {
    const token = cookies["auth"].token;
    setToken(token);
    const user = cookies["auth"].user;
    setCurrentUser(user);
    loadUsers(token);    
  }, [cookies, history]);

  return (
    <div>
      <AdminNavBar page="users" />
      {success && <div className="alert alert-success">{success}</div>}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">User Name</th>
            <th scope="col">User Email</th>
            <th>Register Date / Location</th>
            <th>Last Login / Location</th>            
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            return (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{showDateLoc(user.registerDateLoc)}</td>
                <td>{showDateLoc(user.lastloginDateLoc)}</td>                
                <td>
                  {user.email !== currentUser.email && (
                    <>
                      <Link to={"/admin/user/"+user.id}>
                        <button
                          disabled={isUpdating}
                          className="btn btn-success btn-sm mr-1"
                        >
                          Detail
                        </button>
                      </Link>
                      <button
                        disabled={isUpdating}
                        onClick={() => deleteUser(user.id)}
                        className="btn btn-danger btn-sm mr-1"
                      >
                        Delete User
                      </button>
                      <button
                        disabled={isUpdating}
                        onClick={() => resetUserPassword(user.id)}
                        className="btn btn-success btn-sm mr-1"
                      >
                        Reset pasword
                      </button>
                      {user.suspend == false && (
                      <button
                        disabled={isUpdating}
                        onClick={() => handleSuspendUser(user.id, true)}
                        className="btn btn-warning btn-sm"
                      >
                        Suspend
                      </button>
                      )} 
                      {user.suspend == true && (
                      <button
                        disabled={isUpdating}
                        onClick={() => handleSuspendUser(user.id, false)}
                        className="btn btn-warning btn-sm"
                      >
                        Unsuspend
                      </button>
                      )} 
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
