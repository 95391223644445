import React from 'react';
import { CompositeDecorator, EditorState, getDefaultKeyBinding, RichUtils, Modifier, convertFromRaw, convertFromHTML, convertToRaw, ContentState, AtomicBlockUtils } from 'draft-js'
import "../../css/draft-editor.css";
import "draft-js/dist/Draft.css";
import {stateToHTML} from 'draft-js-export-html'; 
import { stateFromHTML } from 'draft-js-import-html';
import createImagePlugin from '@draft-js-plugins/image';
import Editor from '@draft-js-plugins/editor';
import htmlToDraft from 'html-to-draftjs';
import { textEditorPhotoUpload } from '../../endpoints/topics';
import { dataURItoBlob } from "../../common/imageFunc";
import ImageCrop from "./ImageCrop.jsx";
import {onAddLink, createLinkDecorator } from "./onAddLink.jsx";
import { splitter } from '../../common/mediaUpload';

const imagePlugin = createImagePlugin();
const plugins = [imagePlugin];

const AudioDeco = ({ entityKey, contentState, children }) => {
	let { url } = contentState.getEntity(entityKey).getData();
	return (
		<a
			// style={{ color: "blue", fontStyle: "italic" }}
			href={url}
			target="_blank"
			className="heading-text"
		>
			{'Testing'}
		</a>
	);
};
const findAudioEntities = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === "audio"
		);
	}, callback);
};
const createAudioDecorator = () => new CompositeDecorator([
	{
		strategy: findAudioEntities,
		component: AudioDeco,
	},
]);

class DraftEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {editorState: EditorState.createEmpty(), cropping : false, finishCrop : false, anchorsList : [], initialText : props.initialText };

		this.focus = () => this.refs.editor.focus();
		this.onChange = (editorState) => {
			this.setState({editorState});
		}

		this.setAnchorsList = (anchor) => {
			const anchorsList = this.state.anchorsList;
			anchorsList.push(anchor);
			this.setState({anchorsList})
		}

		this.handleKeyCommand = this._handleKeyCommand.bind(this);
		this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
		this.toggleBlockType = this._toggleBlockType.bind(this);
		this.toggleInlineStyle = this._toggleInlineStyle.bind(this);		
		this.componentId = props.componentId;
		this.token = (typeof props.token != 'undefined')? props.token : null;		
		this.readerResult = null;
		this.photoCropper=React.createRef(null);
		this.imageCropper = (typeof props.imageCropper != 'undefined')? props.imageCropper : null;
	}

	// createWithContentForEdit2(blockArr) {
  //   this.setState({ editorState : EditorState.createWithContent(ContentState.createFromBlockArray(blockArr)) });
  // }

	createWithContentForEdit(rawhtml, decorator = null, media = null) {
		const rawHtmlArr = rawhtml.split(splitter);
		let finalHtml = '';
		for (let i = 0; i < rawHtmlArr.length; i++) {
			// console.log(i);
			let html = rawHtmlArr[i]
			// console.log(html)
			if ( html.includes('<audio') ) {
				const str = html.replace('<audio controls><source src="','').replace('" /></audio>', '');
				const link = '<a href="'+str+'">[Audio]</a>'
				html = '<h1>'+link+'</h1>'					
			} 	
			if ( html.includes('<video') ) {
				const str = html.replace('<video controls><source src="','').replace('" /></video>', '');
				const link = '<a href="'+str+'">[Video]</a>'
				html = '<h1>'+link+'</h1>'					
			}

			finalHtml = finalHtml + html
		}   
		console.log(finalHtml)
		const contentState = stateFromHTML(finalHtml);					
		this.setState({ editorState : EditorState.createWithContent(contentState) });		
  }

	getAnchorList() {
		return this.state.anchorsList;
	}

	getRawHtml2 () {
		return this.state.editorState.getCurrentContent()
	}
	
	getRawHtml() {
		const convertedData = convertToRaw(this.state.editorState.getCurrentContent());		
		return convertedData;
	}	

	_handleKeyCommand(command, editorState) {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
	}

	_mapKeyToEditorCommand(e) {
		if (e.keyCode === 9 /* TAB */) {
			const newEditorState = RichUtils.onTab(
				e,
				this.state.editorState,
				4, /* maxDepth */
			);
			if (newEditorState !== this.state.editorState) {
				this.onChange(newEditorState);
			}
			return;
		}
		return getDefaultKeyBinding(e);
	}

	_toggleBlockType(blockType) {
		this.onChange(
			RichUtils.toggleBlockType(
				this.state.editorState,
				blockType
			)
		);
	}

	_toggleInlineStyle(inlineStyle) {
		this.onChange(
			RichUtils.toggleInlineStyle(
				this.state.editorState,
				inlineStyle
			)
		);
	}	

	unsetEditorState() {
		this.setState({editorState: EditorState.createEmpty()});
	}

	async insertImage(editorState, base64) {
		const res = await textEditorPhotoUpload(this.componentId, dataURItoBlob(base64))
		if (typeof res.data.photoUrl != 'undefined') {
			const contentState = editorState.getCurrentContent();
			const contentStateWithEntity = contentState.createEntity(
				"image",
				"IMMUTABLE",
				{ src: res.data.photoUrl }
			);
			const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
			const newEditorState = EditorState.set(editorState, {
				currentContent: contentStateWithEntity
			});
			return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
		}    
		alert("Sorry, photo cannot upload")
		return false;
  }

	handleClick() {
		if (this.imageCropper == null) {
			document.getElementById('image-picker-'+this.componentId).click()
		} else {
			window.scrollTo(0, 0);
			document.getElementById('crop-popup').classList.remove('hidden');
			// document.getElementById(this.imageCropper).click();
		}
  } 
	
	handleMediaUpload() {
		document.getElementById('media-picker-'+this.componentId).click()		
  } 

	async setUpImg(base64Image) {		
		let prevHtmlStr = stateToHTML(convertFromRaw(this.getRawHtml()))  		
		if (prevHtmlStr == '') {
			prevHtmlStr = '<p><br></p>'
		} 	
		if (this.componentId == 'topicDescEditor') {    	
    	if ( prevHtmlStr.includes('<figure>') && prevHtmlStr.includes('</figure>') ) {
    		const part = prevHtmlStr.substring(
			    prevHtmlStr.lastIndexOf("<figure>") + 1, 
			    prevHtmlStr.lastIndexOf("</figure>")
				);
				const finalImg = "<"+part+"</figure>";
				console.log(prevHtmlStr)
				prevHtmlStr = prevHtmlStr.replace(finalImg, '');
				console.log(prevHtmlStr)				
			}
    }

		const newEditorState = await this.insertImage(this.state.editorState, base64Image);
    this.onChange(newEditorState);

    if (this.componentId == 'topicDescEditor') {
    	const htmlStr = stateToHTML(convertFromRaw(this.getRawHtml()))
    	// console.log(htmlStr)
    	// console.log( htmlStr.includes('<figure>') );
    	// console.log( htmlStr.includes('</figure>') );

    	if ( htmlStr.includes('<figure>') && htmlStr.includes('</figure>') ) {
    		const part = htmlStr.substring(
			    htmlStr.lastIndexOf("<figure>") + 1, 
			    htmlStr.lastIndexOf("</figure>")
				);
				const finalImg = "<"+part+"</figure>";
				// console.log(finalImg);			
				const finalHtmlStr = finalImg + prevHtmlStr;
				this.createWithContentForEdit(finalHtmlStr);
			}
    }
	}

	async handleImageSelect(e) {		
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', async () => await this.setUpImg(reader.result));
			reader.readAsDataURL(e.target.files[0]);
		}
	}	

	async handleMediaSelect(e) {		
		if (e.target.files && e.target.files.length > 0) {
			const uploadFile = e.target.files[0]
			const res = await textEditorPhotoUpload(this.componentId, uploadFile)
			const contentState = this.state.editorState.getCurrentContent();
			let fileType = 'audio'
			if (uploadFile.type.includes('audio')) {
				fileType = 'audio'
			} 
			if (uploadFile.type.includes('video')) {
				fileType = 'video'
			}
			const contentStateWithEntity = contentState.createEntity(
				fileType,
				"IMMUTABLE",
				{ src: res.data.photoUrl }
			);
			const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
			const newEditorState = EditorState.set(this.state.editorState, {
				currentContent: contentStateWithEntity
			});
			const finalState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
			this.setState({editorState : finalState});

		}
	}	

	async cropAndAddImage(base64) {
		await this.setUpImg(base64)
	}	

	setCropping(val) {
		this.setState({cropping : val})
	}

	changeCropStatus(val) {
		this.setState({finishCrop : val, cropping : val})		
	}

	handleLinkHeader() {
		let text;
		let heading = prompt("Please enter Heading");
		if (heading == null || heading == "") {
			return false;
		} else {
			const contentState = this.state.editorState.getCurrentContent();
			const contentStateWithEntity = contentState.createEntity('LINK', 'IMMUTABLE', {
			  url: 'http://www.zombo.com',
			});
			const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
			const contentStateWithLink = Modifier.applyEntity(
			  contentStateWithEntity,
			  this.state.editorState.getSelection(),
			  entityKey,
			);
			const newEditorState = EditorState.set(this.state.editorState, {
			  currentContent: contentStateWithLink,
			});
			return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");

			// const contentState = this.state.editorState.getCurrentContent();
			// const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
			//   url: 'http://www.zombo.com',
			// });
			// const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
			// const newEditorState = EditorState.set(this.state.editorState, {
			// 	currentContent: contentStateWithEntity
			// });
			// return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
		}		
	}

	mediaBlockRenderer(block) {
		if (block.getType() === 'atomic') {
			return {
				component: Media,
				editable: false,
			};
		}

		return null;
	}

	render() {
		const {editorState} = this.state;

		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		let className = 'RichEditor-editor overflow-y-auto';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		return (
			<div>
				<div className="RichEditor-root">
					<BlockStyleControls
						blockRendererFn={this.mediaBlockRenderer}
						editorState={editorState}
						onToggle={this.toggleBlockType}
					/>
					<InlineStyleControls
						editorState={editorState}
						onToggle={this.toggleInlineStyle}
					/>
					<input type="file" onChange={async (e) => await this.handleImageSelect(e)} className="hidden" id={"image-picker-"+this.componentId} />
					<input type="file" onChange={async (e) => await this.handleMediaSelect(e)} className="hidden" id={"media-picker-"+this.componentId} />
					<button className="pr-2" onClick={() => this.handleClick()}>Insert Image</button>					
					{(this.componentId != 'topicDescEditor') && 
						<button className="p-2" onClick={() => this.handleMediaUpload()}>Insert Media (video/audio)</button>
					}					
					<button className="p-2" onClick={() => onAddLink(editorState, this.onChange, this.setAnchorsList)}>Insert Header Link</button>
					<div className={className} onClick={this.focus} style={{height : (window.screen.height-300)+'px'}}>
						{/* <Editor
							blockStyleFn={getBlockStyle}
							customStyleMap={styleMap}
							editorState={editorState}
							handleKeyCommand={this.handleKeyCommand}
							keyBindingFn={this.mapKeyToEditorCommand}
							onChange={this.onChange}
							placeholder="Tell a story..."
							ref="editor"
							spellCheck={true}
						/> */}

						<Editor
							blockRendererFn={this.mediaBlockRenderer}
							editorState={this.state.editorState}
							onChange={this.onChange}
							plugins={plugins}
							blockStyleFn={getBlockStyle}
							customStyleMap={styleMap}
							editorState={editorState}
							handleKeyCommand={this.handleKeyCommand}
							keyBindingFn={this.mapKeyToEditorCommand}							
							placeholder={this.state.initialText}
							ref="editor"					
						/>        	
					</div>				
				</div>
			</div>
		);
	}
}

// Custom overrides for "code" style.
const styleMap = {
	CODE: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
	},
};

function getBlockStyle(block) {
	switch (block.getType()) {
		case 'blockquote': return 'RichEditor-blockquote';
		default: return null;
	}
}

class StyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = (e) => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		let className = 'RichEditor-styleButton';
		if (this.props.active) {
			className += ' RichEditor-activeButton';
		}

		return (
			<span className={className} onMouseDown={this.onToggle}>
				{this.props.label}
			</span>
		);
	}
}

const BLOCK_TYPES = [
	{label: 'H1', style: 'header-one'},
	{label: 'H2', style: 'header-two'},
	{label: 'H3', style: 'header-three'},
	{label: 'H4', style: 'header-four'},
	{label: 'H5', style: 'header-five'},
	{label: 'H6', style: 'header-six'},
	{label: 'Blockquote', style: 'blockquote'},
	{label: 'UL', style: 'unordered-list-item'},
	{label: 'OL', style: 'ordered-list-item'},
	{label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
	const {editorState} = props;
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map((type) =>
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			)}
		</div>
	);
};

var INLINE_STYLES = [
	{label: 'Bold', style: 'BOLD'},
	{label: 'Italic', style: 'ITALIC'},
	{label: 'Underline', style: 'UNDERLINE'},
	{label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = (props) => {
	const currentStyle = props.editorState.getCurrentInlineStyle();
	
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map((type) =>
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			)}
		</div>
	);
};

const Media = (props) => {
	const entity = props.contentState.getEntity(
		props.block.getEntityAt(0)
	);
	const {src} = entity.getData();
	const type = entity.getType();

	let media;
	if (type === 'audio') {
		media = <Audio src={src} />;
	} else if (type === 'image') {
		media = <Image src={src} />;
	} else if (type === 'video') {
		media = <Video src={src} />;
	}

	return media;
};

const Audio = (props) => {
	return <audio controls src={props.src}  />;
};

const Image = (props) => {
	return <img src={props.src}  />;
};

const Video = (props) => {
	return <video controls src={props.src}  />;
};

export default DraftEditor;
