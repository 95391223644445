import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const saveCode = (data, token) => {
  return axios
      .post(env.apiLink+"codes", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteCode = (id, token) => {
  return axios
      .delete(env.apiLink+"codes/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getCode = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"codes?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

export { saveCode, getCode, deleteCode }