import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { saveTeaching, getTeaching, deleteTeaching } from "../../endpoints/teaching";
import {stateToHTML} from 'draft-js-export-html'; 
import { convertFromRaw, Editor } from 'draft-js'
import DraftEditor from "./DraftEditor.js";
import ImageCrop from "./ImageCrop.jsx";
import { dataURItoBlob, blobToDataURL } from "../../common/imageFunc";
import { createLinkDecorator } from "./../../components/admin/onAddLink";
import { splitter, replaceMediaBeforeEdit } from "../../common/mediaUpload";

const Teaching = (props) => {
	const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
	const history = useHistory();

	const [user, setUser] = useState({});

	const [teachingSlides, setTeachingSlides] = useState([]);
	const [addingSlide, setAddingSlide] = useState(false);
	const [slideTitle, setSlideTitle] = useState('');
	const [slideId, setSlideId] = useState(null);
	const [syllabusId, setSyllabusId] = useState('');
	const [slidePhoto, setSlidePhoto] = useState('');
	const [syllabusSlides, setSyllabusSlideArr] = useState([]);
	const [activeSyllabus, setActiveSyllabus] = useState(null);
	const [previewDesc, setPreviewDesc] = useState(false);
	const [initialText, setInitialText] = useState(null);  
  const editor = useRef(null);	
	const photoCropper=React.createRef(null);

	useEffect(() => {
		// alert('getting teaching slides count' + props.topicId);
		loadSlides();
	}, [cookies]);	

	const convertFromJSONToHTML = () => {
    if(editor == null || editor.current == null) {
      return '';
    } else {			
			const prevHtml = stateToHTML(editor.current.getRawHtml2(), { entityStyleFn: (entity) => {
					const entityType = entity.get('type').toLowerCase();
					console.log(entityType);
					if (entityType === 'audio') {
						const data = entity.getData();
						return {
							element: 'audio',
							attributes: {
								src: data.src,
							},
						};
					}
					if (entityType === 'video') {
						const data = entity.getData();
						return {
							element: 'video',
							attributes: {
								src: data.src,
							},
						};
					}
					return null;
				}
			})
			// console.log(prevHtml)
			let editedHtml = prevHtml
			while(editedHtml.includes('>&nbsp;</audio></figure>')) {
				editedHtml = editedHtml.replace('<figure><audio', splitter+'<audio controls><source').replace('>&nbsp;</audio></figure>', ' /></audio>'+splitter)
			}
			while(editedHtml.includes('>&nbsp;</video></figure>')) {
				editedHtml = editedHtml.replace('<figure><video', splitter+'<video controls><source').replace('>&nbsp;</video></figure>', ' /></video>'+splitter)
			}
			// console.log(editedHtml)
      return editedHtml
    } 
    // return stateToHTML(convertFromRaw(JSON.parse(text)))     
  }

	const saveSlide = async () => {
		setPreviewDesc(true);
		await new Promise(resolve  => {
			setTimeout(() => {
				resolve(true);
			}, 1500);
		});
		let SlideDescHtml = convertFromJSONToHTML();
		console.log(SlideDescHtml);
		const headingTextArr = document.getElementsByClassName('heading-text');
    const anchorList = []
    for ( const i in headingTextArr ) {
      if (Number.isInteger(parseInt(i))) {     
        const heading = headingTextArr[i]
        const headingSpan = heading.querySelector('span span')
        const id = heading.href
        const idArr = id.split('#')
        const idText = idArr[1];
        const spanText = headingSpan.textContent
        anchorList.push('<a href="#'+idText+'">'+spanText+'</a>');
      }
    }
    // const anchorList = editor.current.getAnchorList();
    if ( anchorList.length > 0 ) {
      const replaceTextWithClassAndId = [];
      for (const i in anchorList) {
        const ai = anchorList[i];
        const id = ai.substring(
          ai.lastIndexOf('#') + 1, 
          ai.lastIndexOf('">')
        );
        const airep = ai.replace('<a href="', `<a id="${id}" class="anchorText" href="`);
        replaceTextWithClassAndId.push({
          replaceText : ai,
          replaceTextWithClassAndId : airep
        })
      }
      for (const i in replaceTextWithClassAndId) {
        const rT = replaceTextWithClassAndId[i];
        SlideDescHtml = SlideDescHtml.replace(rT.replaceText, rT.replaceTextWithClassAndId)
      }
    }

		if ( props.topicId == '' ) {
			props.setErrors(['Please create topic first']);
			setTimeout(() => props.setErrors([]), 5000);
			return false;
		} 
		if ( slideTitle == null || SlideDescHtml == '<p><br></p>' || syllabusId == '' ) {
			props.setErrors(['Please choose syllabus and type both title and description']);
			setTimeout(() => props.setErrors([]), 5000);
			return false;
		}
		console.log(SlideDescHtml)
		const arr = new FormData();
		arr.append('title', slideTitle);
		arr.append('description', SlideDescHtml);
		arr.append('topicId', props.topicId);
		arr.append('syllabusId', syllabusId);
	
		const tarr = teachingSlides;
		// let cropDataUrl = "";
		// if (document.getElementById("croppedImg").value != '') {
		// 	cropDataUrl = await new Promise(async (resolve, reject) => {
		// 		const canvas = photoCropper.current.children[0];
		// 		await canvas.toBlob((blob) => {
		// 			blobToDataURL(blob, (res) => {
		// 				resolve(res);
		// 			});
		// 		});
		// 	});	
		// }
		// if (cropDataUrl != null && cropDataUrl != '') {
		// 	arr.append('photo', dataURItoBlob(cropDataUrl));
		// }
		if (slideId == null) {
			saveTeaching(arr, props.token).then(res => {
				const resdata = res.data.data;
				tarr.unshift(resdata);

				const newarr = [];
				for (let i = 0; i<tarr.length; i++) {
					newarr.push(tarr[i]);
				}
				loadSlides();
			});
		} else {
			let finalHtmlEdit = SlideDescHtml
			finalHtmlEdit = replaceMediaBeforeEdit(finalHtmlEdit);
			if (finalHtmlEdit != SlideDescHtml) {
				arr.delete('description')
				arr.append('description', finalHtmlEdit);
				console.log('---------------------------')
				console.log(finalHtmlEdit)
			}			
			
			arr.append('id', slideId);
			saveTeaching(arr, props.token).then(res => {
				const updateInd = tarr.findIndex(e => e.id == slideId);
				tarr[updateInd] = {
					title : slideTitle,
					description : finalHtmlEdit,
					topicId : props.topicId,
					syllabusId : syllabusId
				};
				const newarr = [];
				for (let i = 0; i<tarr.length; i++) {
					newarr.push(tarr[i]);
				}
				loadSlides();
			});			
		}
		unsetSlide();
	}

	const loadSlides = () => {
		getTeaching({ topicId : props.topicId }, props.token).then(res => {
			const teachingSlides = res.data.data;
			let syllabusSlideArr = [];
			let oldSyllabus = "";
			const slides = [];
			for (let i in teachingSlides) {
				const slide = teachingSlides[i];
				let i2 = i;
				if (slide.syllabusId != oldSyllabus) {
					i2 = 0;
					syllabusSlideArr[slide.syllabusId] = [];
				}
				syllabusSlideArr[slide.syllabusId].push(slide);
				oldSyllabus = slide.syllabusId;
				slides.push(slide);
			}
			setTeachingSlides(slides);
			setSyllabusSlideArr(syllabusSlideArr);
		});
	}

	const detailSlide = (id) => {
		const index = teachingSlides.findIndex(e => e.id == id);
		const detail = teachingSlides[index];
		setAddingSlide(true);
		setSyllabusId(detail.syllabusId);
		setSlideTitle(detail.title);
		// setSlideDesc(detail.description);
		setSlidePhoto(detail.photo);
		setSlideDescHtml(detail.description);
		setSlideId(detail.id);
	}

	const setSlideDescHtml = (html) => {
		setTimeout(() => {
			const decorator = createLinkDecorator();
			editor.current.createWithContentForEdit(html, decorator);
			// setTimeout(() => {
			// 	editor.current.createWithContentForEdit(html, decorator, 'media');
			// }, 400);
		}, 400);
	}

	const unsetSlide = () => {
		setAddingSlide(false);
		setSlideId(null);
		setSlideTitle('');
		if (editor.current != null) {
			editor.current.unsetEditorState();
		}
		setSyllabusId('');
		setPreviewDesc(false);
	}

	const deleteSlide = (id) => {
		const confirmDel = window.confirm("Are you sure you want to delete ?");
		if (confirmDel) {
			deleteTeaching(id, props.token).then(res => {
				if (res.data.success == true) {
					loadSlides();
				}				
			});
		}
	}

	const showCount = (sid) => {
		if (typeof syllabusSlides[sid] != 'undefined') {
			// console.log(sid + ' already ' +syllabusSlides[sid].length);
			return '('+syllabusSlides[sid].length+' Slides)';
		} else {
			// console.log(sid +  ' is ' + 0);
			return '';
		}
	}


	const addMedia = () => {
		alert(slideId)
		const index = teachingSlides.findIndex(e => e.id == slideId);
		const detail = teachingSlides[index];
		editor.current.createWithContentForEdit(detail.description, null, 'media');		
	}

	const addSlide = () => {
		setAddingSlide(true);
		setSyllabusId(activeSyllabus);
	}

	return (
		<>
			<div className={(props.tab == 'teaching')? 'm-10' : 'm-10 hidden'}>
      {(addingSlide==false) && 
        <div className="flex flex-col">
          <div className="flex flex-row ">
						<div className="flex flex-row">
							<button onClick={addSlide} className="w-24 mb-6 bg-green-600 p-2 rounded text-md text-gray-100" id="tooltip-1ctr">Add Slide</button>
							<div id="tooltip-1body" className="tooltip-1 w-auto h-10 p-1  flex items-center">
								<span className="text-2xl">&#x25C0;</span><span style={{marginLeft: "-2px"}} className="rounded-lg bg-gray-800 p-2 text-white flex text-center items-center text-sm justify-center">
									Please add course content to your syllabus below by clicking add slide
								</span>
							</div>
						</div>
          </div>
					{(activeSyllabus == null)? <h2 className="text-lg underline">Syllabus</h2> : <a href='#' className="font-bold text-4xl hover:no-underline" onClick={() => setActiveSyllabus(null)}>&#8592;</a> }
					{props.syllabuses.map((s, index) => {
						if (activeSyllabus == null) {
							return (
								<div key={index+'syldiv'} className=""><span className="ml-1 text-lg">&#10161;</span> <a href="#" onClick={() => setActiveSyllabus(s.id)}>{s.syllabusCode} : {s.title} {showCount(s.id)} </a></div>
							);
						}
					})}
          {(teachingSlides.length > 0 && activeSyllabus != null) &&
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th>Title</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
								<TeachingListing teachingSlides={teachingSlides} activeSyllabus={activeSyllabus} deleteSlide={deleteSlide} detailSlide={detailSlide} />
              </tbody>
            </table>
          }
					{(teachingSlides.length == 0 && activeSyllabus != null) &&
						<div
							className="alert alert-danger mt-2 w-full"					
							>
							There is no teaching slide for this syllabus yet, Please create new one by clicking Add Slide
						</div>
					}
        </div>
      }
      {addingSlide && 
        <div className="flex flex-col mt-2">
					Syllabus
					<select 
						className="bg-gray-200 border-solid mt-2 p-2 mb-4 max-w-5xl"
						onChange={(e) => setSyllabusId(e.target.value)}
						value={syllabusId}
					>
						<option value="">Select Syllabus</option>
						{props.syllabuses.map(s => {
							return (
								<option key={s.id+'sylsel'} value={s.id}>{s.title}</option>
							);
						})}
					</select>

					{/* photo no longer needed
					Photo
					{(slidePhoto != '') && <img src={slidePhoto} className="max-w-5xl w-auto" />}
					<div className="max-w-5xl"><ImageCrop ref={photoCropper} /></div> */}

          Title
          <input type="text" className="bg-gray-200 rounded h-8 p-2 w-full mb-4 max-w-5xl" value={slideTitle} onChange={(e) => setSlideTitle(e.target.value)} />
          
          Description
					<div className={(previewDesc == true)? 'hidden' : 'max-w-5xl'}>
						<DraftEditor ref={editor} componentId={'teachingDescEditor'} initialText={initialText} token={cookies["auth"].token} />          
					</div>
					<div id="desc-div" className={(previewDesc == false)? 'hidden' : 'mb-3 max-w-5xl'}>
						<div dangerouslySetInnerHTML={{ __html: convertFromJSONToHTML()}}></div>
					</div>
					
          <div className="w-full flex">
					{(previewDesc == false)? 
						<button onClick={() => setPreviewDesc(true)} className="mr-2 mt-2 w-24 bg-green-300 p-2 rounded text-md text-black">Preview</button>
						:
						<button onClick={() => setPreviewDesc(false)} className="mr-2 mt-2 w-24 bg-green-300 p-2 rounded text-md text-black">Edit</button>
					}
          <button onClick={unsetSlide} className="mr-2 mt-2 w-24 bg-yellow-300 p-2 rounded text-md text-black">Back</button>
          <button onClick={saveSlide} className="mt-2 w-24 bg-green-600 p-2 rounded text-md text-gray-100">Save</button>					
          </div>
        </div>
      }
      </div>
		</>
	)
}

const TeachingListing = (props) => {
  const activeTeachingSlides = props.teachingSlides.filter((slide, index) => slide.syllabusId == props.activeSyllabus);	
	return (
		<>
			{(activeTeachingSlides.length > 0) && 
				activeTeachingSlides.map((slide, index) => {
					return (
						<tr key={index+'slide'}>
							<td className="border-2 p-2">{slide.title}</td>
							<td className="border-2 p-2">
								<button className="ml-1 w-12 bg-green-200 p-1 h-8 rounded text-sm text-gray-600" onClick={() => props.detailSlide(slide.id)}>Detail</button>
								<button className="ml-1 w-12 bg-red-600 p-1 h-8 rounded text-sm text-white" onClick={() => props.deleteSlide(slide.id)}>Delete</button>
							</td>
						</tr>
					)
				})					
			}

			{(activeTeachingSlides.length == 0) && 
				<div
					className="alert alert-danger mt-2 w-full"					
					>
					There is no teaching slide for this syllabus yet, Please create new one by clicking Add Slide
				</div>
			}	
		</>
	);
			
}

export default Teaching;