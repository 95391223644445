import styles from '../assets/style/style.css'

import japan from '../assets/image/japancourse.png'
import joslogo2 from '../assets/image/logo.png'
import img1 from '../assets/image/bannerlogo.jpg'
import img2 from '../assets/image/banner1.jpg'
import img3 from '../assets/image/banner2.jpg'
import img4 from '../assets/image/banner3.jpg'
import img5 from '../assets/image/banner4.jpg'
import jpnvid from '../assets/image/trainingvideo.mp4'
import facebook from '../assets/image/logo-facebook.png'
import instagram from '../assets/image/logo-instagram.png'
import twitter from '../assets/image/logo-twitter.png'
import youtube from '../assets/image/logo-youtube.png'
import left from '../assets/image/left.png'
import right from '../assets/image/right.png'
import home from '../assets/image/home2.png'
import courseNav from '../assets/image/course.png'
import myCourses from '../assets/image/myCourses.png'
import signUp from '../assets/image/SignUp.png'
import logIn from '../assets/image/Login.png'
import helpNav from '../assets/image/logOut.png'
import profile from '../assets/image/man.png'
import JOSn5 from '../assets/image/JOSn5.jpg'
import JOSn4 from '../assets/image/JOSn4.jpg'
import JOSn3 from '../assets/image/JOSn3.jpg'
import JOSn32 from '../assets/image/JOSN32.jpg'
import backgroundImage from '../assets/image/backgroundforad2.png'
import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NavbarText } from 'reactstrap'




const Home = () => {

	const expand = () => {
		document.getElementById("nav").style.marginLeft= "0px";
		document.getElementById('page1').style.opacity = "0.3"
	}
	const closeNav = () => {
		document.getElementById("nav").style.marginLeft = "-320px";
		document.getElementById('page1').style.opacity = "1"
	}

	const nextSlide = () => {
		document.getElementById('photo-container-big').scrollTo({
			top: 0,
			left: document.getElementById('photo-container-big').scrollLeft + document.getElementById('photo-container-big').offsetWidth,
			behavior: 'smooth'
		})
	}

	const prevSlide = () => {
		document.getElementById('photo-container-big').scrollTo({
			top: 0,
			left: document.getElementById('photo-container-big').scrollLeft - document.getElementById('photo-container-big').offsetWidth,
			behavior: 'smooth'
		})
	}
	const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const history = useHistory();
	const path = history.location.pathname;    
  
	//detecting if user is logged in by checking the cookies
	useEffect(() => {
		if (cookies["auth"]) {
		const user = cookies["auth"].user;
		setIsLoggedIn(true);
		setTimeout(() => {
			if (user.questionChallenge === true) {
			// document.getElementById('challenge-toggle-check-top').checked = true
			} else {
			// document.getElementById('challenge-toggle-check-top').checked = false
			}
		}, 100)
		} else {
		setIsLoggedIn(false);
		}    
	}, [cookies]);

    return (
		<div className="homeDiv">
			<div id="page1">
				<div id="container1">
					<div id="header">
						<div id="menu">
							<div id="logo">
								<img id="logoimg1" src={joslogo2} style={{width:'108px', marginTop: '-14px'}} />
							</div>
							<div id="menubtn">
								{/* <a className="btn btn-big start-learnbtn" href="/dashboard">လေ့လာမှု့စတင်ပါ</a> */}
							</div>
							<div id="hamburger" onClick={() => expand()}>
								<span>&#9776;</span>
							</div>
						</div>
					</div>
					<div id="content1">
						<div id="page5">
							<div id="container5 relative" style={{position: 'relative'}}>
								<div onClick = {() => prevSlide()} id="prev" className="w-7 h-8 flex items-center justify-center bg-gray-200 rounded-full absolute mt-24 leftright-ctn">
									<img className="w-1/2" src={left} />
								</div>
								<div onClick = {() => nextSlide()} id="next"  className="w-7 h-8 flex items-center justify-center bg-gray-200 rounded-full absolute mt-24 leftright-ctn right-0" style={{boxShadow: "-3px 3px 7px grey", right: '-8px'}}>
									<img className="w-1/2" src={right} />
								</div>
								<div id="photo-container-big" className="mb-6">
									<div className="photoContainer">
										<img className="photo-mini" src={img1} />
									</div>
									<div className="photoContainer">
										<img className="photo-mini" src={img2} />
									</div>
									<div className="photoContainer">
										<img className="photo-mini" src={img3} />
									</div>
									<div className="photoContainer">
										<img className="photo-mini" src={img4} />
									</div>
									<div className="photoContainer">
										<img className="photo-mini" src={img5} />
									</div>
								</div>
							</div>
						</div>
						<div id="page1textContainer" style={{ backgroundImage: `url(${backgroundImage})` , backgroundSize: 'cover' , backgroundPosition: '50%', maxWidth: '1180px', 'width': 'auto', 'margin': '0px auto'}}>
							<h1> Wai Sensei မှာ ဘာလို့ လေ့လာသင့်သလဲ?</h1>
							<ul className="whyList">
								<li>Level အလိုက် သင်ခန်းစာတွေကို ကြည့်ကြပ်ပေးခြင်း။</li>
								<li>Level တစ်ခုချင်းစီ အတွက် JLPT မေးခွန်းဟောင်းများ ဖြေဆိုနိုင်ခြင်း။</li>
								<li>Level တစ်ခုတိုင်း Lesson တစ်ခုတိုင်းတွင် မေးခွန်းများ ပါဝင်ခြင်း။</li>
								<li>သင်တန်းတစ်ခု ပြီးသွားတိုင်း Certificate ရရှိခြင်း။</li>
								<li>သင်တင့်သော စျေးနှုန်းဖြင့် ကောင်းမွန်သော သင်ကြားမှုကို ရရှိခြင်း။</li>
							</ul>
						</div>
						<br/>
					</div>
					{/* <div id = "button-explore" className="pl-3 ">
						<a className="btn btn-small " href="#page2" style={{ marginTop: '-5px', marginLeft: '6px'}}>သင်ခန်းစာများကို ကြည့်ရန်</a>
					</div> */}
				</div>
			</div>
			<div id="page2">
				<div id="caption2" className="w-full flex justify-center">
					<div id="c2h2container">
						<h2>ရွေးချယ်နိုင်သော သင်ခန်းစာများ<span>&#8680;</span> </h2>
					</div>
				</div>
				<div id="page-mini" className="example" style={{
					maxWidth: '1180px',
					width: 'auto',
					margin: '0px auto',
				}}>
					<div className ="container">
						<div className="imgtxt">
							<img src={JOSn5}/>	
						</div>
						{/* <div className="txt">
							<Link to={"/dashboard/N5"} className="btn btn-mini">လေ့လာပါ</Link>
						</div> */}
					</div>

					<div className ="container">
						<div className="imgtxt">
							<img src={JOSn4}/>	
						</div>
						{/* <div className="txt">
							<Link to={"/dashboard/N4"} className="btn btn-mini">လေ့လာပါ</Link>
						</div> */}
					</div>

					<div className ="container">
						<div className="imgtxt">
							<img src={JOSn3}/>	
						</div>
						{/* <div className="txt">
							<Link to={"/dashboard/N3"} className="btn btn-mini">လေ့လာပါ</Link>
						</div> */}
					</div>	

					<div className ="container">
						<div className="imgtxt">
							<img src={JOSn32}/>	
						</div>
						{/* <div className="txt">
							<Link to={"/dashboard/N3"} className="btn btn-mini">လေ့လာပါ</Link>
						</div> */}
					</div>

					<div className ="container">
						<div className="imgtxt">
							<img src={JOSn32}/>	
						</div>
						{/* <div className="txt">
							<Link to={"/dashboard/N3"} className="btn btn-mini">လေ့လာပါ</Link>
						</div> */}
					</div>
				</div>
				<div id = "button-preview">
					<center>
						{/* <a className="btn btn-small" href="#page3">သင်ကြားပုံ Preview video ကြည့်ရန်</a> */}
					</center>
				</div>
			</div>
			{/* <div id="page3">
				<div id="container3">
					<div id="p3h2Container">
						<h2 id="p3h2">သင်ကြားမှုပုံစံ Preview video </h2>
					</div>
					<div id="videoDiv">
						<div className="video-container">
							<video  controls>
							<source src={jpnvid} type="video/mp4"/>
							</video>
						</div>						
					</div>
				</div>
				<div id="button-explore" className="flex justify-center" style={{ marginTop: '7px'}}>
					<a className="btn btn-small" href="#page4">ဆက်သွယ်လိုပါသလား</a>					
				</div>
			</div> */}
			<div id="page4" style={{
				maxWidth: '1180px',
				width: 'auto',
				margin: '0px auto',
			}}>
				<div id="container4" >
					<div className="mt-8" >
						<h1> အကူအညီလိုအပ်ပါသလား ?</h1>
						<p className="leading-8">လူကြီးမင်းတို့ အကူညီလိုအပ်ပါက ဖုန်းနဲ့ဖြစ်စေ မေးလ်နဲ့ဖြစ်စေ အောက်ပါ icon လေးများကို click နှိပ်ပြီးဆက်သွယ်နိုင်ပါသည်။</p>
						<br/>
						<a className="ctbtn" href="tel:09962982034">
							<svg xmlns="http://www.w3.org/2000/svg" className="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
							</svg>
							ဖုန်းခေါ်ရန် &nbsp;
						</a>
						<br/><br/>
						<a className="ctbtn" href="mailto:waiw93690@gmail.com">
							<svg xmlns="http://www.w3.org/2000/svg" className="icons" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
							</svg>
							email ပို့ရန် 
						</a>
						<br/><br/>
						<p className="mb-2">Wai Sensei မှ နေ့စဉ်သတင်း အချက်အလက်များအား အောက်ပါလူမှုကွန်ရပ်များပေါ်တွင် ရှာဖွေနိုင်ပါသည်။</p>
						<a href="https://www.facebook.com/waisensei1"><img className="logo-sm" src={facebook}/></a>
						{/* <a href="#"><img className="logo-sm" src={instagram}/></a>
						<a href="#"><img className="logo-sm" src={twitter}/></a>
						<a href="#"><img className="logo-sm" src={youtube}/></a> */}
					</div>
				</div>
			</div>
			<div id="nav" className="overlay">
				<div id="nav-menu">
					<div id= "navContainer">
						<div className="logoimg">
							<div id="nav-img">
								<img src={joslogo2}/>
							</div>
							<div id="navVersion">
								{/* <div>
									<span>Build 1</span>
									<br/>
									<span>Version 1.1</span>
								</div> */}
							</div>
						</div>
						<div id="closebtndiv">
							<span id="closebtn" onClick={() => closeNav()}>&times;</span>
						</div>
					</div>					
				</div>
				<div id="nav-content">
					{(cookies["auth"] != null) &&
					<div className = "overlayPM">
						<div id="avatar">
						{(cookies["auth"].user.profileUrl == null) && <img className="avatarpp" alt="" src={profile}/>}
						{(cookies["auth"].user.profileUrl != null) && <img className="avatarpp" alt="" src={cookies["auth"].user.profileUrl}/>}
						</div>
						<div id="profileText">
						<span>{cookies["auth"].user.name}</span><br/>
						</div>
					</div>}
					<div id='overlayItemsContainer'>
						{/* <a href ="/dashboard" className = 'overlayItems'>
							<img className="navIcon" src={courseNav}/>
							<span href="/dashboard">Courses</span>
						</a>
						<a href ="/my-courses" className = 'overlayItems'>
							<img className="navIcon course" src={myCourses}/>
							<span>My Courses</span>
						</a>	 */}
						<a href ="/" className = 'overlayItems'>
							<img className="navIcon" src={home}/>
							<span>Home</span>
						</a>
						<a href ="/about" className = 'overlayItems'>
							<img className="navIcon" alt="" src={signUp}/>
							<span>About</span>
						</a>											
						{/* <a href ="/register" className = 'overlayItems'>
							<img className="navIcon" src={signUp}/>
							<span>Sign Up</span>
						</a>
						<a  href="/login" className = 'overlayItems'>
							<img className="navIcon" src={logIn}/>
							<span>Log In</span>
						</a>
						<a href={path.includes("/admin")? "/admin/logout" : "/logout"} className = 'overlayItems'>
							<img className="navIcon" src={helpNav}/>
							<span>Log Out</span>
						</a>	 */}
					</div>						
				</div>
			</div>	           
		</div>
  	);
};
export default Home