import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import "./styles/AnswerOnePopup.css"

const AnswerOncePopup = (props) => {

  const [hidden, setHidden] = useState(false);
  const screenHeight = window.screen.height;

  const startAnswer = () => {
    setHidden(true);
    props.startAnswer();
  }

  return (
    <div id="startup-popup" className={(hidden === true)? "hidden" : ""}>
        <div className="popup center">
          <div className="icon">
            <FontAwesomeIcon className="fa" icon={faExclamation} />
          </div>
        <h2 className="title">Please note the following conditions before you answer!</h2>
        <p className="description">
          1. If it is wrong or correct, you cannot try again once you have answered on that question.
        </p>
        <p className="description">
          2. Please answer to complete all questions and make sure not to leave the page or close the browser while answering.
        </p>
        <p className="description">
          3. If you leave the page or close the browser without complete all questions, you will get no marks, we will regard as you answered once and you cannot try again this question. 
        </p>
        <div className="answerOnceBtn">
          <button className="answerOnceLeft" onClick={() => startAnswer() }>Continue</button>
          <Link to="/dashboard"><button className="answerOnceRight">Back</button></Link>
        </div>
      </div>
      {/* <div className="popup-cover" style={{height: screenHeight + 'px'}}></div> */}
    </div>
  );
}


export default AnswerOncePopup;
