import React, { useState, useEffect } from "react";
import useSpinner from './Spinner/useSpinner'
import NavBar from "./NavBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import '../assets/style/payment.css'
import { useHistory, useParams } from "react-router-dom";
import { getTopicDetail, orderTopic } from "../endpoints/topics";
import { useCookies } from 'react-cookie';
import kbz from '../assets/image/kbzbank.png'
import cb from '../assets/image/CB_Logo_.png'
import kpay from '../assets/image/blue-L.png'
import apay from '../assets/image/aya.png'
import aya from '../assets/image/ayabank.png'
import cbpay from '../assets/image/cbpay.PNG'
import mptpay from '../assets/image/mptpay.png'
import truemoney from '../assets/image/true.png'
import wave from '../assets/image/wave.png'
import uabpay from '../assets/image/uabpay.png'

let accName
let payAcc

const Payment = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
    const [popup, setPopup] = useState(false)
    const [success, setSuccess] = useState(false)
    const [spinner, showSpinner, hideSpinner] = useSpinner()
    const history = useHistory();
    const { topicId } = useParams();
    const [topic, setTopic] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [selPayment, setSelPayment] = useState(null);

    useEffect(() => {
        const token = cookies["auth"].token
        const userId = cookies["auth"].user.id
        console.log(cookies["auth"])
        getTopicDetail(topicId, token).then(res => {
            let hasTopic = false
            if (typeof res.data.data == 'object' && res.data.data != null) {
                if (typeof res.data.data.codeId == 'number') {
                    setTopic(res.data.data)
                    hasTopic = true
                    orderTopic({ topicId, userId }, token).then(res => {
                        if (typeof res.data.id != 'undefined') {
                            setOrderId(res.data.id)
                        }
                        if (typeof res.data.status != 'undefined' && res.data.status != 'created') {
                            history.replace('/dashboard')
                        }
                    })
                }
            }
            if (hasTopic == false) {
                history.replace('/dashboard')
            }            
        })
    }, [])

    const toggleModal = (pa = null) => {
        if (pa != null) {            
            setSelPayment(pa)
        }
        if (pa != null) {            
            accName = payments.filter(p => p.pa === pa).map(p => p.acccountName)
            payAcc = payments.filter(p => p.pa === pa).map(p => p.accountNo)            
        }
        // setPopup(!popup)
    }
    
    const fakeFetch = () => {
        showSpinner()
        setTimeout( () => {
            setPopup(!popup)
            hideSpinner()
            setSuccess(true)
        }, 3000)
    }

    const orderConfirm = () => {
        const phone = document.getElementById('payment-phone');
        if (phone.value == '') {
            alert('Please type your phone number')            
            phone.focus()
            phone.classList.add('txt-nophone')
            return false
        } else {
            phone.classList.remove('txt-nophone')
        }
        if (selPayment == null) {
            alert('Please choose payment method')
            return false
        }
        toggleModal()        
        showSpinner()
        const userId = cookies["auth"].user.id
        orderTopic({ topicId, userId, phoneNumber : phone.value, paymentMethod : selPayment, orderstatus : 'pending', 'id' : orderId }, cookies["auth"].token).then(res => {
            console.log(res.data)            
            setTimeout( () => {
                setPopup(!popup)
                hideSpinner()
                setSuccess(true)
            }, 1000)
        }).catch(err => {
            console.log(err)
            setTimeout( () => {
                alert('Oops! Something went wrong');
                toggleModal()
                hideSpinner()
            }, 1000)
        })
    }

    const payments = [
        {
            id: 1,
            paymentUrl: kbz,
            pa : 'KBZ',
            accountNo : '0102255',
            acccountName : 'Harold'
        },
        {
            id: 4,
            paymentUrl: aya,
            pa : 'AYA',
            accountNo : '0405996',
            acccountName : 'Ma Hla Hla'
        },
        {
            id: 2,
            paymentUrl: cb,
            pa : 'CB',
            accountNo : '0206995',
            acccountName : 'Harry'
        },
        {
            id: 5,
            paymentUrl: kpay,
            pa : 'KBZPay',
            accountNo : '05099637',
            acccountName : 'Bruce'
        },        
        {
            id: 9,
            paymentUrl: cbpay,
            pa : 'cbpay',
            accountNo : '09066998',
            acccountName : 'Zaw Win Htut'
        },
        {
            id: 3,
            paymentUrl: apay,
            pa : 'APYA',
            accountNo : '0306696',
            acccountName : 'Yama'
        },        
        {
            id: 6,
            paymentUrl: truemoney,
            pa : 'truemoney',
            accountNo : '060588966',
            acccountName : 'Soe Moe'
        },
        {
            id: 7,
            paymentUrl: mptpay,
            pa : 'mptpay',
            accountNo : '07099637',
            acccountName : 'Cho Cho'
        },
        {
            id: 8,
            paymentUrl: uabpay,
            pa : 'uabpay',
            accountNo : '08096657',
            acccountName : 'Wai Cho'
        }        
    ]

    return(
        <> 
            <NavBar />
            <div className="navOverlay">
                <div className="paymentChoose">
                    <h1>Payment for {(topic != null)? topic.topicTitle : ''}</h1>
                    
                    <p>
                        လူကြီးမင်း၏ဖုန်းနံပါတ်အား ဖြည့်သွင်းပြီး အောက်ပါငွေပေးချေရန် နည်းလမ်းများထဲမှ နှစ်သက်ရာနည်းလမ်းတစ်ခုကိုရွေးချယ်ပြီး click နှိပ်ပါ။ 
                        အကောင့်အချက်အလက်များပေါ်လာပါလိမ့်မည်။ <br />
                        အချက်အလက်များကိုမှတ်ထားပြီး Select Payment နှိပ်ပါ။ ငွေပေးချေရန် Confirm Payment နှိပ်ပါ။<br />
                        Admin team မှမိနစ်ပိုင်းအတွင်းငွေပေးချေမှုအတည်ပြုရန်ဆက်သွယ်လာပါလိမ့်မည်။ <br />
                        ငွေပေးချေပြီးပါက Menu မှ My Courses ထဲဝင်၍သင်ခန်းစာကိုစတင်လေ့လာနိုင်ပါမည်။
                    </p>
                    <div className="paymentName">                        
                        <input id="payment-phone" className="paymentInput" type="number" placeholder="Please type your phone number" />
                    </div>
                    <div className="paymentName">
                        <label for="plan"><b>Price : {(topic) && topic.price.toLocaleString()} MMK</b> (For lifetime access)</label>                        
                    </div>
                </div>
                {popup && (
                    <div id="popup">
                        {!spinner && 
                        <div class="popup-content">
                            <h1>Choose this payment</h1>
                            <div className="popupNameAndNumber">
                                <p>Account Name: {accName}</p>
                                <p>Account Number: {payAcc}</p>
                            </div>
                            <div className="popupConBtn">
                                <button className="popupBtn mr-2 bg-red-500" onClick={() => toggleModal()}>Select Payment</button>
                                {/* <button className="popupBtn" onClick={fakeFetch}> &nbsp;Confirm&nbsp; </button> */}
                            </div>                            
                        </div>
                        }
                        {spinner && 
                            <div>{spinner}</div>
                        }
                    </div>
                )}

                {success && (
                    <div id="popup">
                        <div class="popup-content">
                            <div className="checkBackground">
                                <FontAwesomeIcon className="checkIcon" icon={faCheckCircle} />
                            </div>
                            <p className="successPayment">Thank you for your order!<br/>We will confirm and contact you shortly</p>

                            <div className="popupConBtn">
                                <a href ="/my-courses" className="popupBtn">
                                    <span>Check Your Courses</span>
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                
                <div className="paymentBottom">
                    <div className="paymentHeader">Choose Payment Methods</div>
                    <div className="paymentLogo">
                        {
                            payments.map((pay, id) => {
                                return (
                                    <img className={(selPayment == pay.pa)? "active paymentLogoImg" : "paymentLogoImg"} src={pay.paymentUrl} alt="" key={id} onClick={() => toggleModal(pay.pa)} />                                    
                                )
                            })
                        }
                    </div>
                    <button className="paymentBtn" onClick={() => orderConfirm()}>confirm payment</button>
                </div>
            </div>
        </>
    )
}

export default Payment;
