import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useCookies } from "react-cookie"
import { getAnswers } from "../endpoints/answers"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { Progress } from 'reactstrap'
import NavBar from "./NavBar"
import './styles/mark.css'
import { showYmDhm } from "../common/timeFunc";

const Mark = () => {
    const [cookies] = useCookies(["cookies"])
    const history = useHistory()
    const [token, setToken] = useState("")
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        setToken(cookies["auth"].token)
        getAnswers(cookies["auth"].token, { userId : cookies["auth"].user.id }).then(res => {
            console.log(res.data.data)
            setAnswers(res.data.data)
        })
    }, [cookies])

    

    return (
        <>
        <NavBar />
            <div className="navOverlay">
                <div className="mark-header">
                    <h2 className="mark-header-title">Your Marks</h2>

                    <div className="mark-separators">
                        <div className="mark-lines mark-line-lefts"></div>
                        <div className="mark-asterisks"><FontAwesomeIcon icon={faAsterisk} /></div>
                        <div className="mark-lines mark-line-rights"></div>
                    </div>

                    <div className='mark-main-table'>
                        <table className="mark-table">
                            <thead className="bg-gray-400">
                                <tr>
                                    <th scope="col" className="mark-table-text">Course Title</th>
                                    <th scope="col" className="mark-table-text">Mark</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-200">
                                {answers.map((ans) => {
                                    let percent = 100 * (ans.correctAnswer/ans.totalQuestion).toFixed(2)
                                    return (
                                        <tr>
                                            <td className="mark-result">
                                                <p className="mark-topic">{ans.topic.topicTitle}</p>
                                                <p className="mark-syllabus">{ans.syllabus.title}</p>
                                                <p className="mark-data">{showYmDhm(ans.topic.createdAt)}</p>
                                            </td>
                                            <td className="mark-result">
                                                <span className="mark-result-info">
                                                    <p className="mark-number">{ans.correctAnswer}/{ans.totalQuestion}</p>
                                                    <Progress value={percent}><p className="mark-per">{percent}%</p></Progress>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })} 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mark