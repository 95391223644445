import axios from 'axios';
import env from '../env';

const saveQuestion = (data, token) => {
  return axios
      .post(env.apiLink+"questions", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteQuestion = (id, token) => {
  return axios
      .delete(env.apiLink+"questions/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getQuestions = (filter, token) => {
  const query = objectToQueryString(filter);  
  return axios
      .get(env.apiLink+"questions?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getQuestionById = (id, token) => {
  return axios
      .get(env.apiLink+"questions/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

const getRandomQuestion = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"questions/random?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const saveRandomAnswer = (data, token) => {
  return axios
      .post(env.apiLink+"questions/random", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

export { saveQuestion, getQuestions, objectToQueryString, deleteQuestion, getRandomQuestion, saveRandomAnswer, getQuestionById }