import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "./NavBar";
import env from "../env";
import { getCareerDetail } from "./../endpoints/career"
import { getTopics } from "../endpoints/topics";
import CoursesList from "./CoursesList"
import { saveUserStudiesCareer, getUserStudiesCareerTopics, getUserStudiesTopics } from "../endpoints/userstudies"

const CareerDetail = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const { id } = useParams();
	const history = useHistory();
	const [career, setCareer] = useState({})
	const [topics, setTopics] = useState([])
	const [skenumbers, setSkenumbers] = useState([1,2,3,4,5,6]);
	const [studiedTopics, setStudiedTopics] = useState([])
	const [already, setAlreadyStudy] = useState(null)
	const [userAnswers, serUserAnswers] = useState([]);

  useEffect(async () => {
		getCareerDetail(id, cookies["auth"].token).then(res => {
			if (res.data.success === true) {
				const careerObj = res.data.data				
				setCareer(careerObj)
			} else {
				alert('something wrong'); console.log(res)
			}
		})
		
		let userAnswers1 = []
		await getUserStudiesTopics({ userId : cookies["auth"].user.id }, cookies["auth"].token).then(res => {
      if (res.data.data.length > 0) {
        serUserAnswers(res.data.data);       
				userAnswers1 = res.data.data;
      }      
    })

		getTopics(cookies["auth"].token, { publish : 1 , careerId : id }).then(res => {
			if (res.data.success === true) {
				setSkenumbers([])
				const topicDatas = res.data.data
				const updTopicDatas = topicDatas.map((topic, index) => {
					const alreadyStudyTopic = userAnswers1.filter(a => parseInt(a.topicId) == parseInt(topic.id))
					let opacity = 0.25
					let buttonTxt = 'Jump Study'
					if (alreadyStudyTopic.length > 0) {
						const t = alreadyStudyTopic[0]
						opacity = 1
						const studystatus = (t.totalStudiedSyllabus == t.totalSyllabus)? 'complete' : 'studying'
						if (studystatus == 'studying') {
							buttonTxt = 'Continue'
						} else {
							buttonTxt = 'Review'
						}									
					}
					// if everyitems is dim, first item should be bright
					
					// if prev button is review, this item should be bright
					if (index > 0) {
						const prevBtnText = topicDatas[index-1].buttonTxt
						if (prevBtnText == 'Review' && opacity < 1) {
							opacity = 1
							buttonTxt = 'Start'
						}									
					}
					topic.opacity = opacity
					topic.buttonTxt = buttonTxt
					return topic
				})
				setTopics(updTopicDatas)
			} else {
				alert('something wrong'); console.log(res)
			}
		})

		getUserStudiesCareerTopics({ userId : cookies["auth"].user.id, careerId : id }, cookies["auth"].token).then(res => {
			if (res.data.success == true && res.data.data.length > 0) {
				setStudiedTopics(res.data.data)
				setAlreadyStudy(true)
			} else {
				setAlreadyStudy(false)
			}
		}).catch(err => setAlreadyStudy(false) )
  }, [cookies]);

	const startCareer = () => {
		if (topics.length < 1) {
			alert('There is no course yet!')
			return 
		}
		const firstTopicId = topics[0].id
		saveUserStudiesCareer({
			careerId : id,
			topicId : firstTopicId			
		}, cookies["auth"].token).then(res => {
			console.log(res)
			history.replace('/topic/'+firstTopicId)
		})
	}

	const startStudy = () => {
		const firstTopicId = topics[0].id
		history.replace('/topic/'+firstTopicId)		
	}

	const continueStudy = () => {
		const btns = document.getElementsByClassName('study-btn')
		if (btns.length > 0) {
			for (let i in btns) {
				if (i < topics.length) {
					if (topics[i].opacity < 1) {
						const continueTopicId = topics[i-1].id
						history.replace('/topic/'+continueTopicId)	
						break;
					}
				}
			}
		}
		const lastTopic = topics[topics.length - 1];
		if (lastTopic.buttonTxt == 'Start') {
			history.replace('/topic/'+lastTopic.id)	
		}
	}

	return (
    <>
      <NavBar />
			<div className="navOverlay">
				<div id="desc-div" className="backgroundColorD">
					<div className="careersDetail">
						<div className="careersLink">
							<Link to='/careers'>Careers </Link> {'>'} <Link to={'/careers/'+career.id}>{career.careerName}</Link>
						</div>
						<div className="topicLine"></div>
						<div className="careersName">
							{career.careerName}
						</div>
						<div className="text-gray-400">{career.careerDescription}</div>
						{already === false &&
						<button onClick={() => startCareer()} className="car-btn careers-btn" type="submit">
							Start Career
						</button>}
						{(already === true && topics.length > 0 && topics[0].buttonTxt === 'Jump Study') &&
						<button onClick={() => startStudy()} className="car-btn careers-btn" type="submit">
							Start Study
						</button>}
						{(already === true && topics.length > 0 && topics[0].buttonTxt !== 'Jump Study') &&
						<button onClick={() => continueStudy()} className="car-btn careers-btn" type="submit">
							Continue Study
						</button>}
					</div>
				</div>        
				{/* important later implementation
				<Link to="/">
					<button className="mb-4 ml-1 bg-green-500 text-gray-100 p-3 w-52 rounded-lg text-xs tracking-wide font-semibold font-display focus:outline-none lg:text-sm focus:shadow-outline hover:bg-green-700" type="submit">
						Start Career
					</button>
				</Link> */}

				<div className="careersBorder">
					<p className="careersCourse">Courses</p>
				</div>
				{already === false && <CoursesList skenumbers={skenumbers} topics={topics}  />}
				{already === true &&
					<div className="careersDetail">
						{topics.map((topic, index) => {
							// The first button Start Course/Continue still need to implement
							return (              
								<div style={{opacity : topic.opacity}} className="" key={index}>    
									<p className="careersDetailTitle">{topic.topicTitle}</p>
									<span className="font-semibold">Author : <i>{(typeof topic.user != 'undefined')? topic.user.name : topic.topic.user.name}</i> </span>
									{/* <p className="rounded-sm mt-1 mb-3 h-6 w-full text-sm font-bold font-prompt">{topic.courseCode}</p> */}
									<div className="">
											<img src={topic.courseGridPhoto} className="w-full" alt="" />
									</div>
									<p className="careersDes">{topic.courseShortDesc}</p>  
									<div className="flex justify-center">
										<Link to={"/topic/"+topic.id}><button className="rounded-sm mt-2 mb-3 h-8 w-40 bg-gray-800 text-white study-btn">{topic.buttonTxt}</button></Link>
									</div>              
								</div>
							);
						})}
					</div>
				}
			</div>
    </>
  );
};
export default CareerDetail;
