import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import NavBar from "./NavBar";
import env from "../env";
import "./styles/Login.css"

const Register = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSucess] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const history = useHistory();
  const [redirectLink, setRedirectLink] = useState(null);

  useEffect(() => {
    if (cookies["auth"]) {
      history.replace("/dashboard");
    }
    if (typeof props.location.state != 'undefined') {
      const lastState = props.location.state
      if (typeof lastState.from.state != 'undefined') {
        setRedirectLink(lastState.from.state.from)
      }      
    }
  }, [cookies]);

  const register = (e) => {
    e.preventDefault();
    setErrors([]);
    setSucess(false);
    const newErrors = [];
    if (!name) {
      newErrors.push("name is required");
    }
    if (!email) {
      newErrors.push("email is required");
    }
    if (!password) {
      newErrors.push("password is required");
    }
    if (password !== confirmPassword) {
      newErrors.push("passwords don't match");
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    setDisabled(true);
    setSucess(false);
    axios
      .post(env.apiLink+"users/register", {
        name,
        email,
        password,
        confirmPassword,
      })
      .then((res) => {
        setSucess(true);
        setTimeout(() => {
          axios
          .post(`${env.apiLink}users/login`, { email, password })
          .then((res) => {
            setCookie("auth", res.data);
            console.log(redirectLink)
            if (redirectLink != null) {
              history.replace(redirectLink.pathname);
            } else {
              history.replace("/dashboard");
            }                    
          })
        }, 1000);
      })
      .catch((err) => {
        if (err.response.status == 422) {
          if (err.response.data.err == 'Phone number is not valid') {
            setErrors([err.response.data.err]);
          }
        } else if (err.response.status == 400) {
          if (err.response.data.err == 'email or phone already exits') {
            setErrors(['You already registered with this email or phone!']);
          }          
        } else {
          setErrors(["Something went wrong! Please try again later"]);
        }        
        setDisabled(false);
      });
  };
  return (
    <>
      <NavBar />
      <div className="navOverlay loginMain">
        <div className="loginContainer">
          <div className="loginCard">
            <div>
              <h2>Register</h2>
              <div className="">
                {errors.map((error, index) => {
                  return (
                    <div
                      key={index}
                      className="alert alert-danger mt-2"
                    >
                      {error}
                    </div>
                  );
                })}
                {success && (
                  <div className="alert alert-success mt-2">
                    Your account has been created successfuly
                  </div>
                )}
                <form>
                  <div className="RegisterForm">
                    <label className="loginEmail">Name</label>
                    <input
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="regLoginInput"/>
                  </div>
                  <div className="RegisterForm">
                    <label className="loginEmail">Email Address Or Phone</label>
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="regLoginInput" type="email" />
                  </div>
                  <div className="RegisterForm">
                    <label className="loginEmail">
                      Password
                    </label>
                    <input
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="regLoginInput" type="password" />
                  </div>

                  <div className="RegisterForm">
                    <label className="loginEmail">
                      Confirm Password
                    </label>
                    <input
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}      
                      className="regLoginInput" type="password" />
                  </div>
                  <button className="loginBtn" onClick={register} disabled={disabled} type="submit">Register</button>
                </form>
                <div className="loginAccSign">
                  <p className="loginAcc">Already have an account ?</p> <Link to={"/login"} className="loginSign">Sign in</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
