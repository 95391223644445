import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "./NavBar";
import env from "../env";
import { getTopicDetail } from "../endpoints/topics";
import { getSyllabus } from "../endpoints/syllabus";
import { saveUserStudies, getUserStudies } from "../endpoints/userstudies";
import { getAnswers } from "../endpoints/answers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { Progress } from 'reactstrap'
import "./styles/Topics.css"

const TopicDetail = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [user, setUser] = useState({});
  const [token, setToken] = useState({});
  const { topicId } = useParams();
  const history = useHistory();
  const [topic, setTopic] = useState({});
  const [syllabuses, setSyllabuses] = useState([]);
  const [firstSyllabus, setFirstSyllabus] = useState(0);
  const [alreadyStudied, setAlreadyStudied] = useState([]);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (cookies["auth"]) {
      setUser(cookies["auth"].user);    
      setToken(cookies["auth"].token);
    }
    getTopicDetail(topicId, ((cookies["auth"])? cookies["auth"].token : null) ).then(res => {
      if (res.data.success == true) {
        setTopic(res.data.data);        
        if (cookies["auth"]) {
          getUserStudies({ userId : cookies["auth"].user.id, topicId }, cookies["auth"].token).then(res => {
            if (res.data.success == true) {
              const already = res.data.data;
              setAlreadyStudied(already);            

              getAnswers(cookies["auth"].token, { 
                userId : cookies["auth"].user.id,
                topicId : topicId
              }).then(res => {
                if (res.data.data.length > 0) {
                  setAnswers(res.data.data);                
                }
                setLoading(false);
              })
            } 
          })                
        } else {
          setLoading(false);
        }        
      }      
    }).catch(err => {
      console.log(err);
      history.replace("/admin/question");
    });

    getSyllabus({ topicId : topicId }, ((cookies["auth"])? cookies["auth"].token : null) ).then(res => {
      if (res.data.success === true) {
        setSyllabuses(res.data.data);
        setFirstSyllabus(res.data.data[0].id);
      }      
    });

  }, [cookies]);

  const showComplete = (syllabusId) => {
    const alreadyStudiedBySyllabus = alreadyStudied.filter(slide => slide.syllabusId == syllabusId);
    return alreadyStudiedBySyllabus.length;
  }

  const percentCorrectAnswer = (syllabusId) => {
    const percentCorrect = answers.filter(ans => ans.syllabusId === syllabusId)
    if (percentCorrect.length) {
      return percentCorrect[0].correctAnswer
    }
  }

  const showCorrectAnswer = (syllabusId, totalQuestions) => {
    const alreadyTestedBySyllabus = answers.filter(ans => ans.syllabusId == syllabusId);
    if (alreadyTestedBySyllabus.length > 0) {
      return alreadyTestedBySyllabus[0].correctAnswer + ' / ' + totalQuestions + ' Q&A correct';
    }
    return totalQuestions + ' Questions';
  }

  const showContinue = (syllabusId, totalSlides, index) => {
    const alreadyStudiedBySyllabus = alreadyStudied.filter(slide => slide.syllabusId == syllabusId);
    if (alreadyStudiedBySyllabus.length < totalSlides && alreadyStudiedBySyllabus.length > 0) {
      return (
        <Link to={"/learn/"+topicId+"/"+syllabusId}>
          <button className="tak-btn">Continue</button>
        </Link>
      );
    }
    if (alreadyStudiedBySyllabus.length === totalSlides) { // and exam is not completed
      const alreadyTestedBySyllabus = answers.filter(ans => ans.syllabusId === syllabusId);
      return (
        <>
          <div className="reviewTake">
            <Link to={"/learn/"+topicId+"/"+syllabusId}>
              <button className="re-btn">Review</button>
            </Link>
            {alreadyTestedBySyllabus.length === 0 &&
            <Link to={"/question/"+topicId+"/"+syllabusId}>
              <button className="tak-btn">Take Exam</button>
            </Link>}
          </div>
        </>
      );
    }
    if (index > 0) {
      const prevSyllabus = syllabuses[index-1];
      const alreadyStudiedByPrevSyllabus = alreadyStudied.filter(slide => slide.syllabusId === prevSyllabus.id);
      // if (alreadyStudiedByPrevSyllabus.length === prevSyllabus.totalSlides) { // continue show all syllabus by customer request Dec5,2021
        return (
          <Link to={"/learn/"+topicId+"/"+syllabusId}>
            <button className="tak-btn">Continue</button>
          </Link>
        );
      // }
    }    
  }

 
  const [selected, setSelected] = useState(null)

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }

    setSelected(i)
  }
  

  return (
    <>
      <NavBar />
      <div className="navOverlay">
        <div className="main-containerTopic">
          <div className="headerTopic">
              <div id="desc-div" className=''>
                <div className="topicLink">
                  <Link to='/dashboard'>Courses</Link> <span className="greaterThan"> {'>'} </span>  <Link to={'/topic/'+topicId}>{topic.topicTitle}</Link>
                </div>
                <div className="topicLine"></div>
                <div className="topicHeader">{/*  pl-3 */}
                  {/* {topic.courseCode} :  */}
                  {topic.topicTitle}    
                </div>
              </div>

              <div className="text-gray-400">
                <div className="imgAndText" dangerouslySetInnerHTML={{ __html: topic.description }}></div>
                {
                  (loading === false && alreadyStudied.length === 0) &&
                    <Link to={"/learn/"+topicId+"/"+firstSyllabus}>
                      <button className="btn cta-btn" type="submit">
                        Start Course
                      </button>
                    </Link>
                }
              </div>
          </div>
        </div>
                        

        <div className="syllabusesHeader">Syllabuses</div>
        <div className="separator">
            <div className="line line-left"></div>
            <div className="asterisk"><FontAwesomeIcon icon={faAsterisk} /></div>
            <div className="line line-right"></div>
        </div>
        <div className="wrapper">
          <div className="accordion">
            {syllabuses.map((s, index)=>{
              let percent = 100 * (showComplete(s.id)/s.totalSlides).toFixed(2)
              let ansPercent = 100 * (percentCorrectAnswer(s.id)/s.totalQuestions).toFixed(2)

              console.log(ansPercent)
              return(
                    <div className="item" onClick={() => toggle(index)}>
                      <div className="title">
                        <div><FontAwesomeIcon icon={faLongArrowAltRight} /> 
                        &nbsp;{s.title} </div>
                        <span>{selected === index ? '-' : '+'}</span>
                      </div>
                      <div className={selected === index ? 'content show' : 'content'}>
                        <div className="TeachingQuestion">
                          <div className="comAndQA">
                            
                            {
                              (loading === false && alreadyStudied.length > 0)?
                              <span className="topicTeaching"> 
                                {showComplete(s.id)} / {s.totalSlides} Completed
                              </span>
                              :
                              <span className="topicTeaching"> 
                                {s.totalSlides} Teaching Slides
                              </span>
                            }

                              <div class="progress">
                                  <Progress value={percent}></Progress>
                              </div>
                          </div>
                          
                          <div className="comAndQA">
                            {
                              (loading === false && answers.length > 0)?
                              <span className="topicTeaching"> 
                                {showCorrectAnswer(s.id, s.totalQuestions)}
                              </span>
                              :
                              <span className="topicTeaching"> 
                                {s.totalQuestions} Questions
                              </span>
                            }
                              <div class="progress">
                                  <Progress value={ansPercent}></Progress>
                              </div>
                          </div>
                        </div>
                        {(loading === false && alreadyStudied.length > 0) &&
                          <div className="mb-4">
                            {showContinue(s.id, s.totalSlides, index)}
                          </div>}
                      </div>
                    </div>
            )})}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicDetail;
