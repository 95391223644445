import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { saveTeaching, getTeaching, deleteTeaching } from "../../endpoints/teaching";
import QuestionDetail from "../../common/QuestionDetail";
import { saveQuestion, getQuestions } from "../../endpoints/questions";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import QuestionInput from "./QuestionInput";

const QuestionTab = (props) => {
	const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
	const [question, setQuestion] = useState([]);
	const [updateQid, setUpdateQid] = useState(null);
  const [editing, setEditing] = useState(false);
  const [activeSyllabus, setActiveSyllabus] = useState(null);
  const [previewDuration, setPreviewDuration] = useState(0);
  const [randomQuiz, setRandomQuiz] = useState({
    questionTitle : '',
    answer1 : '',
    answer2 : '',
    answer3 : '',
    answer4 : ''
  });
  const questionInputRef = useRef();
  
	useEffect(() => {
    if(props.topicId != undefined){
      loadQuestions(props.topicId);
    }
  }, [cookies]);

  const loadQuestions = (topicId) => {
    getQuestions({topicId : topicId}, cookies["auth"].token).then(res => {
      if (res.data.success == true) {
        setQuestion(res.data.data);
      }
    });
  }

  const showCount = (sid) => {    
    const activeQuestion = question.filter(q => q.syllabusId == sid);	
    return '('+activeQuestion.length+' questions)';    
	}

  const editQuestion = (index, id) => {
    questionInputRef.current.editQuestion(index, id)
    setEditing(true);
  }
  
	return (
		<>
			<div className={(props.tab == 'question')? 'm-10 max-w-5xl' : 'm-10 hidden'}>

        {(activeSyllabus == null)? <h2 className="text-lg underline">Syllabus</h2> : <a href='#' className="font-bold text-4xl hover:no-underline" onClick={() => setActiveSyllabus(null)}>&#8592;</a> }
        
        {(activeSyllabus == null) &&
          <div>
            {props.syllabuses.map((s, index) => {
              if (activeSyllabus == null) {
                return (
                  <div key={index+'syldiv'}><span className="ml-1 text-lg">&#10161;</span> <a href="#" onClick={() => setActiveSyllabus(s.id)}>{s.syllabusCode} : {s.title} {showCount(s.id)} </a></div>
                );
              }
            })}
          </div>
        }
        
        {(activeSyllabus != null) &&
          <div>
            {(editing == false) && question.map((q, index) => {
              if (q.syllabusId == activeSyllabus) {
                return <QuestionDetail answerCnt={props.answerCnt} token={props.token} loadQuestions={loadQuestions} no={index+1} editQuestion={() => editQuestion(index, q.id)} key={index+'qdetail'} question={q} index={index} parent="adminQuestion" />
              }              
            })}            
          </div>
        }
        <div style={{marginLeft: '-40px'}}>
        <QuestionInput 
          tab='question'
          loadQuestions={loadQuestions} 
          activeSyllabus={activeSyllabus}         
          topicId={props.topicId}
          token={props.token}
          level={props.level}
          courseCode={props.courseCode}
          ref={questionInputRef}
          setEditing={setEditing}
        />
        </div>
      </div>
      
		</>
	)
}

export default QuestionTab;