import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "./NavBar";
import env from "../env";
import { getTopicDetail } from "../endpoints/topics";
import { getTeaching } from "../endpoints/teaching";
import { saveUserStudies, getUserStudies } from "../endpoints/userstudies";
import { getRandomQuestion, saveRandomAnswer } from "../endpoints/questions";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import "./styles/Teaching.css"
import { Nav } from "reactstrap";
import { getOrders } from '../endpoints/topics'

const Teaching = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const { topicId, syllabusId } = useParams();
  const [teachingSlides, setTeachingSlides] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [alreadyStudied, setAlreadyStudied] = useState([]);
  const minusVal = (window.screen.height > 1000)? 180 : 250;
  const screenHeightMinus100 = (window.screen.height - minusVal) + 'px';
  const [topicTitle, setTopicTitle] = useState('');
  const [syllabusTitle, setSyllabusTitle] = useState('');
  const [anchorArr, setAnchorArr] = useState([]);
  const [randomQuiz, setRandomQuiz] = useState(null);
  const [correctMsg, setCorrectMsg] = useState(null);
  const [wrongMsg, setWrongMsg] = useState(null);
  const history = useHistory();

  let interval
  useEffect(async () => {
    setUser(cookies["auth"].user);    
    setToken(cookies["auth"].token);
    // getTopicDetail(topicId, cookies["auth"].token).then(res => {
    //   const arr = res.data.data.teachingslides;
    //   setTeachingSlides(arr);
    //   setSlideWidth(parseInt(100/arr.length));
    // })
    const datas = await getUserStudies({ userId : cookies["auth"].user.id, syllabusId : syllabusId }, cookies["auth"].token);    
    const already = (datas.data.success == true)? datas.data.data : [];
    if (already.length < 1) {
      getTopicDetail(topicId, cookies["auth"].token).then(async res => {
        const topic = res.data.data;
        if (topic.price > 0) {
          const res = await getOrders(cookies["auth"].token, { userId : cookies["auth"].user.id, topicId : topicId })
          if (res.data.data && res.data.data.length > 0) {
            if (res.data.data[0].orderstatus == 'pending') {
              alert('Your order for this course is processing')
              history.replace("/topic/"+topicId);  
            } else if (res.data.data[0].orderstatus == 'approve') {
              continuePageStartProcess(already);
            } else {
              history.replace("/payment/"+topicId);  
            }
          } else {
            history.replace("/payment/"+topicId);
          }          
        } else {
          continuePageStartProcess(already);
        }
      })      
    } else {
      setAlreadyStudied(already);
      
      continuePageStartProcess(already);
      // if (cookies["auth"].user.questionChallenge == true) {
      //   interval = setInterval(showQuestion, 5000); // 2000 ms = start after 2sec 
      // }
    }
  }, [cookies]);

  const continuePageStartProcess = (already) => {
    getTeaching({ topicId : topicId, syllabusId : syllabusId }, cookies["auth"].token).then(async res => {
      setTeachingSlides(res.data.data);
      if (res.data.data.length > 0) {
        const slide = res.data.data[0];        
        createAnchorList(slide.description, 0);
        checkAlreadyStudiedAndSaveIfNot(already, slide.id);
      }
      setSlideWidth(parseInt(100/res.data.data.length));
    });
    
    getTopicDetail(topicId, cookies["auth"].token).then(res => {
      const topic = res.data.data;
      setTopicTitle(res.data.data.topicTitle);
      topic.syllabuses.map(s => {
        if (s.id == syllabusId) {
          setSyllabusTitle(s.title);
        }
      })
    });
  }

  useEffect(() => {
    return () => {
      console.log("cleaned up");
      // clearInterval(interval);
    };
  }, []);

  const showQuestion = () => {
    if (user != null) {
      if (user.questionChallenge != true) {
        return 
      }
    }
    getRandomQuestion({ userId : cookies["auth"].user.id, topicId : topicId, level : cookies["auth"].user.level }, cookies["auth"].token).then(res => {
      if (res.data.success == true && res.data.data.length > 0) {
        const quiz = res.data.data[0];
        setRandomQuiz(quiz);
        clearInterval(interval);
      }
    })    
  }


  const checkAlreadyStudiedAndSaveIfNot = (already, slideId) => {
    const alreadyStudy = already.filter(userstudy => userstudy.slideId == slideId);
    if (alreadyStudy.length == 0) {
      const studyData = {
        topicId : topicId,
        userId : cookies["auth"].user.id,
        slideId : slideId,
        syllabusId : syllabusId
      };
      saveUserStudies(studyData, cookies["auth"].token);
      const alreadyStudiedState = alreadyStudied;
      alreadyStudiedState.push(studyData);
      setAlreadyStudied(alreadyStudiedState);
    }    
  }

  const setTab = (index) => {
    setActiveSlide(index);
  }
  
  const goNext = (event, next = null) => {
    if (next == null) {
      next = activeSlide+1;
    }
    if (next == teachingSlides.length) {
      return false;
    }
    
    document.getElementById('div-'+activeSlide).style.marginLeft = "-100%"
    document.getElementById('div-'+next).style.marginLeft = "100%"
    setTimeout(() => {
        document.getElementById('div-'+activeSlide).style.display = 'none'
        document.getElementById('div-'+activeSlide).style.marginLeft = "0px"
        document.getElementById('div-'+next).style.display = "block"    
    }, 300)
    setTimeout(() => {
        document.getElementById('div-'+next).style.marginLeft = "0px"
        setActiveSlide(next);
    }, 350)

    createAnchorList(teachingSlides[next].description, next);
    checkAlreadyStudiedAndSaveIfNot(alreadyStudied, teachingSlides[next].id);        
    
    const twactive = document.getElementsByClassName('tw-active')[0];
    const currenSlideScroll = (twactive.offsetWidth * next) + twactive.offsetWidth + 20;
    const tabSlider = document.getElementById('tab-slider-id');
    if (currenSlideScroll > tabSlider.offsetWidth) {
      tabSlider.scrollTo({
        top: 0,
        left: tabSlider.offsetWidth,
        behavior: 'smooth'
      })
    }
  }

  const goPrev = (event, prev = null) => {
    if (activeSlide == 0) {
      return false;
    }
    if (prev == null) {
      prev = activeSlide-1;
    }
    
    document.getElementById('div-'+activeSlide).style.marginLeft = "100%"
    document.getElementById('div-'+prev).style.marginLeft = "-100%"
    setTimeout(() => {
        document.getElementById('div-'+activeSlide).style.display = 'none'
        document.getElementById('div-'+activeSlide).style.marginLeft = "0px"
        document.getElementById('div-'+prev).style.display = "block"    
    }, 300)
    setTimeout(() => {
        document.getElementById('div-'+prev).style.marginLeft = "0px"
        setActiveSlide(prev);
    }, 350)

    createAnchorList(teachingSlides[prev].description, prev);
    checkAlreadyStudiedAndSaveIfNot(alreadyStudied, teachingSlides[prev].id);    
    
    const twactive = document.getElementsByClassName('tw-active')[0];
    const currenSlideScroll = (twactive.offsetWidth * prev) - twactive.offsetWidth - 20;
    const tabSlider = document.getElementById('tab-slider-id');
    tabSlider.scrollTo({
      top: 0,
      left: currenSlideScroll,
      behavior: 'smooth'
    })    
  }

  const createAnchorList = (descHtml, index) => {
    setTimeout(() => {
      const anchors = document.querySelector('#div-'+index).getElementsByClassName('anchorText');
      const anchorList = [];
      for (let i = 0; i < anchors.length; i++) {
        const anc = anchors[i];
        anchorList.push({
          id : anc.id,
          text : anc.textContent
        })
      }
      setAnchorArr(anchorList)
    }, 800)
  }  

  const goExact = (index) => {
    if (index > activeSlide) {
      goNext({}, index)
    } else {
      goPrev({}, index)
    }
  }

  const renderTime = (remainingTime) => {
    if (remainingTime === 0) {
      if (randomQuiz != null) {
        saveRandomAnswer({ 
          questionId : randomQuiz.id,
          correct : false,
          point : randomQuiz.scoreTimeout
        }, token).then(res => {
          document.getElementById('timeoutmsg').innerHTML = '<span class="m-3" style="line-height:44px">Timeout! you lose '+randomQuiz.scoreTimeout+' from your points</span>'
          setTimeout(() => document.getElementById('timeoutmsg').innerHTML = '', 5000)
        });
        setTimeout(() => setRandomQuiz(null), 1500);
        interval = setInterval(showQuestion, 10000);
      }
      return <div className="timer text-2xl font-semibold">Too lale...</div>;
    }
  
    return (
      <div className="timer self-center text-center ">
        <div className="text text-lg">Remaining</div>
        <div className="text text-2xl font-semibold">{remainingTime}</div>
        <div className="text text-lg">seconds</div>
      </div>
    );
  }

  const handleSaveRandomAnswer = (chosenAns, scoreRight, scoreWrong) => {
    const correct = (randomQuiz.bestAnswer == chosenAns)? true : false    
    saveRandomAnswer({ 
      questionId : randomQuiz.id,
      correct : correct,
      point : correct? scoreRight : scoreWrong,
    }, token).then(res => {
      if (correct == true) {
        setCorrectMsg('Correct! you got '+scoreRight+' credit points')
        setTimeout(() => setCorrectMsg(null), 5000)
      } else {
        setWrongMsg('Wrong! you lose '+scoreWrong+' from your points')
        setTimeout(() => setWrongMsg(null), 5000)
      }
      setTimeout(() => setRandomQuiz(null), 1000)
      interval = setInterval(showQuestion, 10000);
    })
  }

  const updateUser = (user) => {
    setUser(user);
  }

  return (
    <>
      <div className="fixed w-full">
        <NavBar/>
        <div className="flex flex-wrap w-full container cus-container ml-1 mr-1 lg:ml-14 lg:mr-14 mx-auto pt-16 navOverlay">
          <div className="mx-auto relative flex flex-wrap md:w-8/12 w-full xl:w-65Pxl 2xl:w-65P h-screen teachingNavGap">
            <div className="teachingRight">
              <Link to='/dashboard'>Courses</Link> {'>'} <Link to={'/topic/'+topicId}>{topicTitle}</Link> {'>'} <Link to={'/learn/'+topicId+'/'+syllabusId}>{syllabusTitle}</Link>
            </div>
            <div className="flex justify-between w-full overflow-x-auto tab-slider mt-3" id="tab-slider-id">
              {
                teachingSlides.map((slide, index) => {
                  let classes = "tab-button";
                  if (activeSlide == null && index == 0) {
                    classes = "tw-active tab-button";
                  }
                  if (activeSlide != null && activeSlide == index) {
                    classes = "tw-active tab-button";
                  }
                  return (
                    <button key={'go'+index} onClick={() => goExact(index)} className={classes} style={{width: slideWidth+'%' }}></button>
                  );
                })
              }
            </div>
            <div className="flex flex-grow justify-center w-full">
              <div className="w-full relative overflow-y-auto overflow-x-hidden" id="style-3">
              {
                teachingSlides.map((slide, index) => {
                  let classes = "w-full flex h-screen flex-nowrap flex-col top-0 pr-1";
                  if (index === 0) {
                    classes = "w-full flex h-screen flex-nowrap flex-col top-0 pr-1";
                  }                
                  const congractClass = (activeSlide + 1 === teachingSlides.length)? 'w-full flex justify-center flex-col p-2' : 'w-full flex justify-center flex-col p-2 hidden';
                  return (
                    <div className={classes} id={"div-"+index} key={"div-"+index} style={{transition: '0.5s', textOverflow: 'hidden', marginLeft : '0px', display : (index === 0)? 'block' : 'none'}}>
                      <div className="w-full bg-gray-200 flex flex-col pb-48 pl-1 pr-1">
                        <h1 className="teachingLeftHeader">{slide.title}</h1>
                        { 
                          (slide.photo != null) && <img src={slide.photo} className="max-w-md mb-3" />
                        }
                        <div id="desc-div" className="relative pr-1">
                          <div className="teachingImgAndText" dangerouslySetInnerHTML={{ __html: slide.description}}></div>
                          <div className={congractClass} id="congractClassID">
                            <div className="teachingMessage">
                              <span className="teachingCon"> Congractulation! </span> <p>Your study section was finished. Ready to take exam ?</p>
                              <div className="teachingBtn">
                                <Link to={"/question/"+topicId+"/"+syllabusId}><button className="takeBtn">Take Exam</button></Link>
                                <Link to={"/topic/"+topicId}><button className="detailBtn">Detail</button></Link>
                              </div>
                            </div>
                          </div>
                        </div>                 
                      </div>  
                    </div>
                  );
                })
              }
              </div>
            </div>
            <div className="flex gap-8 py-3 absolute bg-gray-600 bottom-16 justify-center w-full">
                <FontAwesomeIcon className="fontAwesomeTeach"  onClick={goPrev} icon={faBackward} />
                <FontAwesomeIcon className="fontAwesomeTeach"  onClick={goNext} icon={faForward} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Teaching;
