import React, { useState, useEffect } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminNavBar from "./../AdminNavBar";
import { saveCode, getCode, deleteCode } from "../../endpoints/codes";
import { showMmSsFromSec } from "../../common/timeFunc";

const Codes = () => {
  const [cookies] = useCookies(["cookies"]);
  const [token, setToken] = useState("");
  const [codes, setCodes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    setToken(cookies["auth"].token);    
    showCodes();
  }, [cookies, editId]);

  const handleSaveCode = () => {
    const code = getValues();
    if (editing == true) {
      code.id = editId;
    }
    saveCode(code, token).then(res => {
      if (res.data.success == true) {
        showCodes();
        resetValues();
        setTimeout(() => setSuccess(false), 5000);

        if (editing == true) {
          showCodes();
          setEditing(false);
          setEditId(null);
          setSuccess('Code updated successfully!')
        } else {
          setSuccess('Code created successfully!')
        }
      }
    }).catch(err => {
      if (err.response.status == 422 || err.response.status == 400) {
        const errors = err.response.data.err;
        const errorsArr = errors.split(',');
        setErrors(errorsArr);
      } else {
        setErrors(['Oops! something error occoured!']);
      }
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, 0);
    })
  }

  const resetValues = (code = { code : '', comment : '' }) => {
    document.getElementById('code').value = code.code;
    document.getElementById('comment').value = code.comment;
  }

  const getValues = () => {
    return {
      code : document.getElementById('code').value,
      comment : document.getElementById('comment').value
    }
  }

  const showCodes = () => {
    getCode({}, cookies["auth"].token).then(res => {
      setCodes(res.data.data);
    })
  }

  const editCode = (ind) => {
    const code = codes[ind];
    setEditId(code.id);
    resetValues(code);
    setEditing(true);
  }

  const backEdit = () => {
    resetValues();
    setEditing(false);
    setEditId(null);
  }

  const handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) {
      await deleteCode(id, token);
      showCodes();
    }
  }

  return (
    <>
      <AdminNavBar page="codes" />
      <div className="m-10">
        <fieldset class="border p-3">
          <legend class="w-auto text-lg font-semibold">{(editing == false)? 'Create Code' : 'Edit Code' }</legend>
          {success && <div className="alert alert-success">{success}</div> }
          {errors.map((error, index) => {
            return (
              <div
                className="alert alert-danger mt-2"
                style={{ width: "70%" }}
                key={index+'errs'}
              >
                {error}
              </div>
            );
          })}
          <div className="w-full flex flex-col">
            <div className="flex">
              <label className="w-24">Code : </label> <input id="code" type="text" className="bg-gray-200 rounded h-8 p-2 w-72 mb-4" />                    
            </div>
            <div className="flex">
              <label className="w-24">Comment : </label> <input id="comment" type="text" className="bg-gray-200 rounded h-8 p-2 w-2/3 mb-4" />
            </div>
            <div className="flex">
              <div className="w-24"></div>
              {(editing == false) && 
                <button onClick={() => handleSaveCode()} className="bg-blue-400 p-2 rounded text-lg text-gray-100">Create</button>
              }
              {(editing == true) &&
                <>
                  <button onClick={() => handleSaveCode()} className="bg-blue-400 p-2 rounded text-lg text-gray-100">Update</button>
                  <button onClick={() => backEdit()} className="bg-yellow-400 p-2 ml-2 rounded text-lg text-gray-100">Back</button>
                </>
              }
            </div>
          </div>
        </fieldset>
        <br/><br/>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Code</th>
              <th>Comment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {codes.map((code, index) => {
              return (
                <tr key={index}>
                  <td className="border-2 p-2">{code.code}</td>
                  <td className="border-2 p-2">{code.comment}</td>
                  <td className="border-2 p-2">
                    <button onClick={() => editCode(index)} class="ml-1 w-12 bg-yellow-200 p-1 h-8 rounded text-sm text-gray-600">Edit</button>
                    <button onClick={() => handleDelete(code.id)} class="ml-1 w-12 bg-red-600 p-1 h-8 rounded text-sm text-white">Delete</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
    </div>  
    </>
  );
};

export default Codes;
