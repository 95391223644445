//Add Link Component
import React from "react";
import { CompositeDecorator, EditorState, Modifier } from "draft-js";
const Link = ({ entityKey, contentState, children }) => {
	let { url } = contentState.getEntity(entityKey).getData();
	return (
		<a
			// style={{ color: "blue", fontStyle: "italic" }}
			href={url}
			target="_blank"
			className="heading-text"
		>
			{children}
		</a>
	);
};
const findLinkEntities = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === "LINK"
		);
	}, callback);
};
export const createLinkDecorator = () => new CompositeDecorator([
	{
		strategy: findLinkEntities,
		component: Link,
	},
]);
// call all together
export const onAddLink = (editorState, setEditorState, setAnchorsList) => {
	let linkUrl = window.prompt("Add link Id Eg:#heading1");
	const decorator = createLinkDecorator();
	if (linkUrl.charAt(0) != '#') {
		alert('link Id format is incorrect.');
		return false;
	}
	if (linkUrl.includes(' ')) {
		alert('link Id format is incorrect.');
		return false;
	}
	if (linkUrl) {
		let displayLink = window.prompt("Display Text");
		if (displayLink) {
			const currentContent = editorState.getCurrentContent();
			const createEntity = currentContent.createEntity("LINK", "MUTABLE", {
					url: linkUrl,
			});
			let entityKey = currentContent.getLastCreatedEntityKey();
			const selection = editorState.getSelection();
			const textWithEntity = Modifier.insertText(
				currentContent,
				selection,
				displayLink,
				null,
				entityKey
			);
			let newState = EditorState.createWithContent(textWithEntity, decorator);
			setEditorState(newState);
			setAnchorsList('<a href="'+linkUrl+'">'+displayLink+'</a>')
		}
	}
};