import React from "react"
import SpinnerIcon from './SpinnerIcon'
import './Spinner.css'

const Spinner = () => {
    return(
        <div className="Spinner">
            <SpinnerIcon />
        </div>
    )
}

export default Spinner