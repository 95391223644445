import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const history = useHistory();
  //removing the user data from the cookies and sending user to the login page
  useEffect(() => {
    const path = history.location.pathname;        
    if(path == '/admin/logout'){
      removeCookie("auth", {path : '/admin'});
      window.location.href = "/admin/login";
    }else{
      removeCookie("auth");
      window.location.href = "/login";
    }    
  }, [removeCookie]);
  return <></>;
};

export default Logout;
