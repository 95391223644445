import React, { useState, useEffect } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminNavBar from "./../AdminNavBar";
import { getTopics } from "../../endpoints/topics";
import { showYmD } from "../../common/timeFunc";
import { saveTopic } from "../../endpoints/topics";

const Topic = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [user, setUser] = useState({ name: "", email: "" });
  const [token, setToken] = useState("");
  const [topics, setTopics] = useState([]);

  const history = useHistory();
  useEffect(() => {
    setUser(cookies["auth"].user);
    setToken(cookies["auth"].token);
    getTopics(cookies["auth"].token).then(res => {
      setTopics(res.data.data);      
    });
  }, [cookies]);

  const changeOrder = (orderNo, topicId, currentOrderNo) => {
    orderNo = parseFloat(orderNo);
    currentOrderNo = parseFloat(currentOrderNo);
    setTopics([]);
    let updateOrderNo = orderNo - 1;
    if (orderNo > currentOrderNo) {
      updateOrderNo = orderNo + 1;
    }
    updateOrderNo = updateOrderNo + parseFloat('0.' + Date.now().toString());
    saveTopic({ id : topicId, orderNo : updateOrderNo}, token).then(async res => {
      getTopics(token).then(res => {
        setTimeout(() => {
          setTopics(res.data.data);
        }, 300);
      });
    }).catch(err => {
      console.log(err);
    })   
  }

  return (
    <>
      <AdminNavBar page="topic" />
      <div className="container mx-auto cus-container lg:p-4">
        <div className="w-full text-center"><span className="m-3 mt-4 sm:mt-0 text-2xl">Courses Administration &nbsp;</span></div>
        <div className="w-full justify-between flex items-center pt-3 pb-3">
          <p className="text-gray-600	m-2 text-2xl font-semibold">
            Courses
          </p>
          <Link to="/admin/question"><button className="bg-blue-400 p-2 mr-2 rounded text-lg text-gray-100">Add Course</button></Link>
        </div>
        <div className="w-full flex flex-wrap flex-shrink-0">
        {
          topics.map((topic, index) => {
            const orderCode = '&#931'+(index+2);
            const virtualOrder = index + 1;
            return (
              <div key={index+'grid'} className={((index % 2 == 0)? "pr-4 " : "pl-4 ") + "w-full sm:w-1/2 min-w-min h-auto p-2 max-w relative "}>
                <div className="w-full mt-8">
                  <p className="rounded-sm mt-3 mb-3 2xl:h-6 w-full text-lg font-bold font-prompt">{topic.courseCode} : {topic.topicTitle}</p>
                  <div className="w-full bg-gray-100 h-auto flex flex-col">
                    <img src={topic.courseGridPhoto} className="w-full" />
                  </div>
                  <p className="rounded-sm mt-2 mb-1 h-14 w-ful font-bold text-lg overflow-hidden font-prompt">
                    {topic.courseShortDesc}
                  </p>                
                  <p className="text-xs"> {topic.syllabuses.length} syllabus | {topic.totalStudents} people studying {(topic.publish == true)? '| Publish at '+ showYmD(topic.publishDate) : ''}</p> 
                  <Link to={"/admin/question/"+topic.id}><button className="rounded-sm mt-2 mb-3 h-8 w-20 bg-gray-800 text-white">Edit</button></Link>
                </div>
                
                {(index > 0) && 
                  <span onClick={() => changeOrder(topics[index-1].orderNo, topic.id, topic.orderNo)} className="absolute top-2 right-3 text-gray-600 cursor-pointer">&#9650;</span>
                }
                {(index == 0) && 
                  <span className="absolute top-2 right-3 text-gray-300">&#9650;</span>
                }
                {(index < topics.length - 1) &&                 
                  <span onClick={() => changeOrder(topics[index+1].orderNo, topic.id, topic.orderNo)} className="absolute top-6 right-3 text-gray-600 cursor-pointer">&#9660;</span>  
                }
                <span className="absolute top-4 right-8 text-gray-600 cursor-pointer">
                  <select className="mr-2 bg-gray-200" onChange={(e) => changeOrder(e.target.value, topic.id, topic.orderNo)}>
                    {topics.map((topic2, index2) => {
                      return (
                        <option selected={(virtualOrder === index2+1)? 'selected' : ''} value={topic2.orderNo}>{index2+1}</option>
                      )
                    })}                    
                  </select>
                </span>  
              </div>
            )
          })
        }
        </div>
        {/* {
          topics.map((topic, index) => {
            const orderCode = '&#931'+(index+2);
            const virtualOrder = index + 1;
            return (
              <div key={index} className={"h-16 w-full bg-gray-50 p-3 mb-2 relative "+((topic.publish == 0)? 'opacity-70' : '')}>
                <p className=""><Link to={"/admin/question/"+topic.id}>{topic.topicTitle}</Link></p>
                <p className="text-xs"> {topic.totalQuestion} questions | {topic.totalAnswered} answered {(topic.publish == true)? '| Publish at '+ showYmD(topic.publishDate) : ''}</p> 
                {(index > 0) && 
                  <span onClick={() => changeOrder(topics[index-1].orderNo, topic.id, topic.orderNo)} className="absolute top-2 right-3 text-gray-600 cursor-pointer">&#9650;</span>
                }
                {(index < topics.length - 1) &&                 
                  <span onClick={() => changeOrder(topics[index+1].orderNo, topic.id, topic.orderNo)} className="absolute top-6 right-3 text-gray-600 cursor-pointer">&#9660;</span>  
                }
                <span className="absolute top-4 right-8 text-gray-600 cursor-pointer">
                  <select className="mr-2" onChange={(e) => changeOrder(e.target.value, topic.id, topic.orderNo)}>
                    {topics.map((topic2, index2) => {
                      return (
                        <option selected={(virtualOrder === index2+1)? 'selected' : ''} value={topic2.orderNo}>{index2+1}</option>
                      )
                    })}                    
                  </select>
                </span>  
              </div>
            );
          })
        } */}
        <br />
        <Link to="/admin/question"><button className="bg-blue-400 p-2 rounded text-lg text-gray-100">Add Course</button></Link>
      </div>    
    </>
  );
};

export default Topic;
