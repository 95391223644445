import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import AdminNavBar from "../AdminNavBar";
import env from "../../env";
import { getOrders, approveOrderTopic } from "../../endpoints/topics"
import { showYmDhm } from "../../common/timeFunc"
const Order = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);	
	const [token, setToken] = useState("");
	const [currentUser, setCurrentUser] = useState({});
  const [orders, setOrders] = useState([]);

  useEffect(() => {
		setCurrentUser(cookies["auth"].user);    
    setToken(cookies["auth"].token);
    getOrdersData()		
  }, [cookies]);

  const getOrdersData = () => {
    getOrders(cookies["auth"].token, {}).then(res => {
      setOrders(res.data.data)
    })
  }
  const approveOrder = (orderstatus, topicId, userId, id) => {
    const confirm = window.confirm("Are you sure you want to "+orderstatus+"? You cannot change after "+orderstatus+"!")
    if (!confirm) {
      return false;
    }
    approveOrderTopic({ topicId, userId, id, orderstatus }, cookies["auth"].token).then(res => {
      if (res.data.success == true) {
        alert(orderstatus + 'ed this order');
        getOrdersData();
      }
    })
  }
  return (
    <>
      <AdminNavBar page="orders" />
      <div className="m-10">
        <h2 className="font-semibold text-2xl mb-4">Order</h2>
        <table className="table-auto w-full">
          <thead>
            <tr>  
              <th>ID</th>            
              <th>Order Date</th>
              <th>
                <div className="flex items-center">
                  User
                  {/* <div className="flex flex-col ml-2 cursor-pointer">
                    <span className="text-xs text-gray-300" style={{marginBottom: "-4px"}}>▲</span>
                    <span className="text-xs text-gray-300">▼</span>
                  </div> */}
                </div>
              </th>
              <th>
                <div className="flex items-center">
                  Course
                  {/* <div className="flex flex-col ml-2 cursor-pointer">
                    <span className="text-xs text-gray-300" style={{marginBottom: "-4px"}}>▲</span>
                    <span className="text-xs text-gray-300">▼</span>
                  </div> */}
                </div>
              </th>
              <th>
                <div className="flex items-center">
                  Price
                  {/* <div className="flex flex-col ml-2 cursor-pointer">
                    <span className="text-xs text-gray-300" style={{marginBottom: "-4px"}}>▲</span>
                    <span className="text-xs text-gray-300">▼</span>
                  </div> */}
                </div>
              </th>
              <th>
                <div className="flex items-center">
                  Phone Number
                  {/* <div className="flex flex-col ml-2 cursor-pointer">
                    <span className="text-xs text-gray-300" style={{marginBottom: "-4px"}}>▲</span>
                    <span className="text-xs text-gray-300">▼</span>
                  </div> */}
                </div>
              </th>
              <th>Payment Method</th>
              <th>Status</th>
              <th>Approve Date</th>
              <th>Option</th>
            </tr>
          </thead>
          <tbody>            
            {orders.map(o => {
              return (
                <tr key={o.id}>
                  <td className="border-2 p-2">{o.id}</td>
                  <td className="border-2 p-2">
                    {(o.orderstatus != 'pending') && showYmDhm(o.createdAt)}
                    {(o.orderstatus == 'pending') && showYmDhm(o.updatedAt)}
                  </td>
                  <td className="border-2 p-2">{o.name}</td>
                  <td className="border-2 p-2">{o.topicTitle}</td>
                  <td className="border-2 p-2">{o.price}</td>
                  <td className="border-2 p-2">{o.phoneNumber}</td>
                  <td className="border-2 p-2">{o.paymentMethod}</td>
                  <td className="border-2 p-2"><span className={((o.orderstatus == 'approve')? 'bg-green-600' : 'bg-yellow-500')+"  text-sm rounded-lg p-1 text-white"}>{o.orderstatus}</span></td>
                  <td className="border-2 p-2">{showYmDhm(o.approveDate)}</td>
                  <td className="border-2 p-2">
                    {(o.orderstatus == 'pending' || o.orderstatus == 'created') && <button onClick={() => approveOrder('approve', o.topicId, o.userId, o.id)} className="p-1 text-sm font-bold text-green-900 bg-green-400">Approve</button>}
                    {(o.orderstatus == 'pending' || o.orderstatus == 'created') && <button onClick={() => approveOrder('reject', o.topicId, o.userId, o.id)} className="p-1 text-sm font-bold text-red-900 bg-red-400">Reject</button>}
                  </td>
                </tr>
              )
            })}            
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Order;
