import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { saveTeaching, getTeaching, deleteTeaching } from "../../endpoints/teaching";
import QuestionDetail from "../../common/QuestionDetail";
import { saveQuestion, getQuestionById } from "../../endpoints/questions";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ilogo from "../../assets/i.png";
import {stateToHTML} from 'draft-js-export-html'; 
import DraftEditor from "./DraftEditor";
import { splitter, replaceMediaBeforeEdit } from "../../common/mediaUpload";

const QuestionInput = forwardRef((props, ref) => {
	const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
	const [updateQid, setUpdateQid] = useState(null);
  const [editing, setEditing] = useState(false);
  const [previewDuration, setPreviewDuration] = useState(0);
  const [questionType, setQuestionType] = useState(0);
  const editor = useRef(null);	
  const [previewDesc, setPreviewDesc] = useState(false);
  const [randomQuiz, setRandomQuiz] = useState({
    questionTitle : '',
    answer1 : '',
    answer2 : '',
    answer3 : '',
    answer4 : ''
  });
	const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
	useEffect(() => {
    
  }, [cookies]);

	useImperativeHandle(ref, () => ({
    async editQuestion(ind, id){
			setEditing(true);
			const ques = await getQuestionById(id, props.token);
			setUpdateQid(id);
      if (ques.data.data.questionType == '1') {
        setTimeout(() => {
          document.getElementById('desc-editor-id').click()
          editor.current.createWithContentForEdit(ques.data.data.description);          
        }, 500)        
      }
			resetQuestionForm(ques.data.data);			            
		},
    async createQuestion() {
      alert('hrer');    
			resetQuestionForm();			
    }
  }), [])


	const addQuestion = async() => {
    if (parseInt(document.getElementById('score-right').value) < 0) {
      setErrors(['Right score should larger than 0']);
      setTimeout(() => setErrors([]), 5000);
			window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (parseInt(document.getElementById('score-wrong').value) > 0) {
      setErrors(['Wrong score should not larger than 0']);
      setTimeout(() => setErrors([]), 5000);
			window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (parseInt(document.getElementById('score-timeout').value) > 0) {
      setErrors(['Timeout score should larger than 0']);
      setTimeout(() => setErrors([]), 5000);
			window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (parseInt(document.getElementById('timer-duration').value) < 0) {
      setErrors(['Timer duration should larger than 0']);
      setTimeout(() => setErrors([]), 5000);
			window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (document.querySelector('input[name="ans"]:checked') == null) {
			setErrors(['Please choose best answer']);
      setTimeout(() => setErrors([]), 5000);
			window.scrollTo(0, window.screen.height-300);
      return false;
    }
    let topicIdVal = props.topicId;
		const question = getQuestionVal();
		question.topicId = topicIdVal;

    setPreviewDesc(true);
    await new Promise(resolve  => {
        setTimeout(() => {
            resolve(true);
        }, 1500);
    });
    let descHtml = ''
    if (questionType != '0') {
      descHtml = convertFromJSONToHTML();    
      descHtml = replaceMediaBeforeEdit(descHtml);    
    }    
    console.log(descHtml)
    question.questionType = questionType
    question.description = descHtml
    saveQuestion(question, props.token).then(res => {
      if (res.data.success = true) {
        setSuccess(res.data.message);
        setTimeout(() => setSuccess(false), 5000);
        window.scrollTo(0, window.screen.height-300);
        resetQuestionForm();
        props.loadQuestions(topicIdVal);
      }
    }).catch(err => {
      if (err.response.status == 422) {
        const errors = err.response.data.err;
        const errorsArr = errors.split(',');
        setErrors(errorsArr);
      } else {
        setErrors(['Oops! something error occoured!']);
      }
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, window.screen.height-300);
    });
  }

  const resetQuestionForm = (ques = { questionTitle : '', answer1 : '', answer2 : '', answer3 : '', answer4 : '', bestAnswer : null, timerDuration : 0, scoreRight : 0, scoreWrong : 0, scoreTimeout : 0, level : 0 }) => {
    document.getElementById('question').value = ques.questionTitle;
    document.getElementById('ans1').value = ques.answer1;
    document.getElementById('ans2').value = ques.answer2;
    document.getElementById('ans3').value = ques.answer3;
    document.getElementById('ans4').value = ques.answer4;
    document.getElementById('timer-duration').value = ques.timerDuration;
    document.getElementById('score-right').value = ques.scoreRight;
    document.getElementById('score-wrong').value = ques.scoreWrong;
    document.getElementById('score-timeout').value = ques.scoreTimeout;
    if (questionType != '0') {
      setTimeout(() => {
        setPreviewDesc(false);
        document.getElementById('desc-editor-id').click()
      }, 500) 
			editor.current.unsetEditorState();
		}
    // if (document.querySelector('input[name="level"]:checked') != null) {
    //   const radiobtn = document.querySelector('input[name="level"]:checked');
    //   radiobtn.checked = false;
    // }    
    if (ques.level != null) {
      const radiobtns = document.getElementsByClassName('level-radio');
      for (let i = 0; i < radiobtns.length; i++) {
        const radiobtnObj = radiobtns[i];
        if (radiobtnObj.value == ques.level) {
          radiobtnObj.checked = true;
        }
      }
    }
    if (document.querySelector('input[name="ans"]:checked') != null) {
      const radiobtn = document.querySelector('input[name="ans"]:checked');
      radiobtn.checked = false;
    }    
    if (ques.bestAnswer != null) {
      const radiobtns = document.getElementsByClassName('ans-radio');
      for (let i = 0; i < radiobtns.length; i++) {
        const radiobtnObj = radiobtns[i];
        if (radiobtnObj.value == ques.bestAnswer) {
          radiobtnObj.checked = true;
        }
      }
    }else{
			document.getElementById('ans1Radio').checked = true;
      setEditing(false);      
			props.setEditing(false);
    }
  }

  const updateQuestion = async () => {
    if (parseInt(document.getElementById('score-right').value) < 0) {
      setErrors(['Right score should larger than 0']);
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (parseInt(document.getElementById('score-wrong').value) > 0) {
      setErrors(['Wrong score should not larger than 0']);
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (parseInt(document.getElementById('score-timeout').value) > 0) {
      setErrors(['Timeout score should larger than 0']);
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, window.screen.height-300);
      return false;
    }
    if (parseInt(document.getElementById('timer-duration').value) < 0) {
      setErrors(['Timer duration should larger than 0']);
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, window.screen.height-300);
      return false;
    }
    const question = getQuestionVal();
    question.topicId = props.topicId;
    question.id = updateQid;
    
    setPreviewDesc(true);
    await new Promise(resolve  => {
        setTimeout(() => {
            resolve(true);
        }, 1500);
    });
    let descHtml = ''
    if (questionType != '0') {
      descHtml = convertFromJSONToHTML();    
      descHtml = replaceMediaBeforeEdit(descHtml);    
    }    
    console.log(descHtml)
    question.questionType = questionType
    question.description = descHtml

    saveQuestion(question, props.token).then(res => {
      if (res.data.success = true) {
        setSuccess(res.data.message);
        setTimeout(() => setSuccess(false), 5000);
        window.scrollTo(0, window.screen.height-300);
        resetQuestionForm();
        props.loadQuestions(props.topicId);
      }
      setEditing(false);
      setUpdateQid(null);
    }).catch(err => {
			console.log(err);
      if (err.response.status == 422) {
        const errors = err.response.data.err;
        const errorsArr = errors.split(',');
        setErrors(errorsArr);
      } else {
        setErrors(['Oops! something error occoured!']);
      }
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, window.screen.height-300);
      setEditing(false);
      setUpdateQid(null);
    });
  }

  const getQuestionVal = () => {
    return {
      questionTitle : document.getElementById('question').value,
      answer1 : document.getElementById('ans1').value,
      answer2 : document.getElementById('ans2').value,
      answer3 : document.getElementById('ans3').value,
      answer4 : document.getElementById('ans4').value,
      bestAnswer : (document.querySelector('input[name="ans"]:checked') != null)? document.querySelector('input[name="ans"]:checked').value : '',
      syllabusId : props.activeSyllabus,
      timerDuration : document.getElementById('timer-duration').value,
      scoreRight : document.getElementById('score-right').value,
      scoreWrong : document.getElementById('score-wrong').value,
      scoreTimeout : document.getElementById('score-timeout').value,
      // level : (document.querySelector('input[name="level"]:checked') != null)? document.querySelector('input[name="level"]:checked').value : '',
      level : props.level,
      courseCode : props.courseCode
    };
  }

  const renderTime = (remainingTime) => {
    if (remainingTime === 0) {
      // if (randomQuiz != null) {
        // setTimeout(() => setRandomQuiz(null), 1500);        
      // }
      setTimeout(() => setPreviewDuration(0), 1500);        
      return <div className="timer text-2xl font-semibold">Too lale...</div>;
    }
  
    return (
      <div className="timer self-center text-center ">
        <div className="text text-lg">Remaining</div>
        <div className="text text-2xl font-semibold">{remainingTime}</div>
        <div className="text text-lg">seconds</div>
      </div>
    );
  }

  const previewRandomQuestion = () => {
    const question = getQuestionVal();
    const timer = document.getElementById('timer-duration').value;
    setPreviewDuration(parseInt(timer));
    setRandomQuiz(question);
  }

  const changeType = () => {
    if (questionType == 0) {
      setQuestionType(1);
    } else {
      setQuestionType(0);
    }    
  }

  const convertFromJSONToHTML = () => {
    if(editor == null || editor.current == null) {
      return '';
    } else {			
			const prevHtml = stateToHTML(editor.current.getRawHtml2(), { entityStyleFn: (entity) => {
					const entityType = entity.get('type').toLowerCase();
					console.log(entityType);
					if (entityType === 'audio') {
						const data = entity.getData();
						return {
							element: 'audio',
							attributes: {
								src: data.src,
							},
						};
					}
					if (entityType === 'video') {
						const data = entity.getData();
						return {
							element: 'video',
							attributes: {
								src: data.src,
							},
						};
					}
					return null;
				}
			})
			// console.log(prevHtml)
			let editedHtml = prevHtml
			while(editedHtml.includes('>&nbsp;</audio></figure>')) {
				editedHtml = editedHtml.replace('<figure><audio', splitter+'<audio controls><source').replace('>&nbsp;</audio></figure>', ' /></audio>'+splitter)
			}
			while(editedHtml.includes('>&nbsp;</video></figure>')) {
				editedHtml = editedHtml.replace('<figure><video', splitter+'<video controls><source').replace('>&nbsp;</video></figure>', ' /></video>'+splitter)
			}
			// console.log(editedHtml)
      return editedHtml
    } 
    // return stateToHTML(convertFromRaw(JSON.parse(text)))     
  }

	return (
		<div className={((props.tab == 'question' && props.activeSyllabus != null)? '' : ' hidden') + ' mb-6 pr-2 lg:m-10 flex flex-col lg:flex-row'}>
			<div className="w-3/4 pr-2">
				<div className="border p-2">
					{success && <div className="alert alert-success">{success}</div> }
					{errors.map((error, index) => {
						return (
							<div
								className="alert alert-danger mt-2"
								style={{ width: "70%" }}
								key={index+'errs'}
							>
								{error}
							</div>
						);
					})}
					Question Title
					<textarea id="question" className={"bg-gray-200 rounded  p-2 w-full "+((questionType == 0)? "h-16" : "h-16")}></textarea>
          <div className={"w-full "+((questionType == 0)? "hidden" : "")}>
            Description
            <div className={(previewDesc == true)? 'hidden' : 'max-w-5xl'}>
              <DraftEditor ref={editor} componentId={'questionEditor'} initialText={'Describe your question description here'} token={cookies["auth"].token} />          
            </div>
            <div id="desc-div" className={(previewDesc == false)? 'hidden' : 'mb-3 max-w-5xl'}>
              <div dangerouslySetInnerHTML={{ __html: convertFromJSONToHTML()}}></div>
            </div>       
            {(previewDesc == false)? 
              <button onClick={() => setPreviewDesc(true)} className="mr-2 mt-2 w-24 bg-green-300 p-2 rounded text-md text-black">Preview</button>
              :
              <button onClick={() => setPreviewDesc(false)} className="mr-2 mt-2 w-24 bg-green-300 p-2 rounded text-md text-black">Edit</button>
            }
          </div>
          <br />
          {/* <input type="radio" className="" checked name="questionType" value="0" onClick={(e) => changeType(e.target.value)} />Text
          <input type="radio" className="ml-4" name="questionType" value="1" onClick={(e) => changeType(e.target.value)} />Audio */}
          <div className="tooltip-body mt-2 relative flex items-center right-0" style={{marginRight: '220px'}}>
            {(questionType != '0')? 'Audio' : 'Text '} &nbsp;&nbsp;
            <div class="relative inline-block w-10 mr-1 mt-1 align-middle select-none transition duration-200 ease-in">
              <input onClick={() => changeType()} type="checkbox" name="toggle" id="desc-editor-id" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
              <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
            </div>
            <img src={ilogo} className="w-5 cursor-pointer" id="tooltip-1ctr" />
            <div id="tooltip-2body" style={{right : '100px !important'}} className="tooltip-1 absolute top-10 w-48 h-auto p-2 rounded-lg bg-gray-800 text-white flex text-center items-center text-sm justify-center">
              <span className="tooltip1-icon">&#9650;</span>
              Change complex question including text/audio/image?
            </div>
          </div>
          <br />
					Answer1
					<textarea id="ans1" className="bg-gray-200 rounded h-16 p-2 w-full" ></textarea>
					Answer2
					<textarea id="ans2" className="bg-gray-200 rounded h-16 p-2 w-full" ></textarea>
					Answer3
					<textarea id="ans3" className="bg-gray-200 rounded h-16 p-2 w-full" ></textarea>
					Answer4
					<textarea id="ans4" className="bg-gray-200 rounded h-16 p-2 w-full" ></textarea>
					Best Answer<br />
					<input type="radio" className="ans-radio" value="1" name="ans" id="ans1Radio" /> Ans:1 &nbsp;&nbsp;
					<input type="radio" className="ans-radio" value="2" name="ans" /> Ans:2 &nbsp;&nbsp;
					<input type="radio" className="ans-radio" value="3" name="ans" /> Ans:3 &nbsp;&nbsp;
					<input type="radio" className="ans-radio" value="4" name="ans" /> Ans:4 &nbsp;
					<br /><br />
				
					<div><div className="w-96">Timer Duration </div><input type="number" className="bg-gray-200 p-2 mt-2 mb-2" id="timer-duration" /></div>
					<div><div className="w-96">Score right </div><input type="number" className="bg-gray-200 p-2 mt-2 mb-2" id="score-right" /></div>
					<div><div className="w-96">Score wrong </div><input type="number" className="bg-gray-200 p-2 mt-2 mb-2" id="score-wrong" /></div>
					<div><div className="w-96">Score timeout </div><input type="number" className="bg-gray-200 p-2 mt-2 mb-2" id="score-timeout" /></div>

					Level : {(props.level == 0)? 'Begineer' : ((props.level == 1)? 'Intermediate' : ((props.level == 2)? 'Expert' : '')) }<br />
					{/* <input type="radio" className="level-radio" value="0" name="level" id="level1Radio2"  /> Begineer &nbsp;&nbsp;
					<input type="radio" className="level-radio" value="1" name="level"  /> Intermediate &nbsp;&nbsp;
					<input type="radio" className="level-radio" value="2" name="level"  /> Expert              */}
					<br/><br/>
					
					{(editing == true)? 
					<>
						<button 
							onClick = {() => updateQuestion()}
							className="bg-blue-400 p-2 rounded text-gray-100"
							id="updateQuestion"
						>
							Update Question
						</button>
						&nbsp;
						<button onClick = {() => resetQuestionForm()} className="bg-red-600 p-2 rounded text-lg text-gray-100">Back</button>
						{/* <button 
							onClick = {() => previewRandomQuestion()}
							className={"bg-green-400 p-2 rounded text-gray-100 ml-1 "+((previewDuration > 0)? 'hidden' : '')}
							id="addQuestion"
						>
							Preview
						</button> */}
					</>
					:
					<>
						<button 
							onClick = {() => addQuestion()}
							className="bg-blue-400 p-2 rounded text-gray-100"
							id="addQuestion"
						>
							Add Question
						</button>
						{/* <button 
							onClick = {() => previewRandomQuestion()}
							className={"bg-green-400 p-2 rounded text-gray-100 ml-1 "+((previewDuration > 0)? 'hidden' : '')}
							id="addQuestion"
						>
							Preview
						</button> */}
					</>
					}
				</div>
			</div>
			{(previewDuration > 0) && 
				<div className="w-1/4 bg-blue-100">
					<div className="flex flex-col items-center p-4">
						<CountdownCircleTimer
							isPlaying
							duration={previewDuration}
							colors={[
								['#004777', 0.33],
								['#F7B801', 0.33],
								['#A30000', 0.33],
							]}
							ariaLabel="Remaining"
						>
							{({ remainingTime }) => renderTime(remainingTime)}
						</CountdownCircleTimer>
						<span className="mt-4 mb-4 text-lg font-semibold">{randomQuiz.questionTitle}</span>
						<span className="pb-3 p-2 border-gray-400 font-medium cursor-pointer hover:bg-green-300 w-full border-t flex justify-center">{randomQuiz.answer1}</span>
						<span className="pb-3 p-2 border-gray-400 font-medium cursor-pointer hover:bg-green-300 w-full border-t flex justify-center">{randomQuiz.answer2}</span>
						<span className="pb-3 p-2 border-gray-400 font-medium cursor-pointer hover:bg-green-300 w-full border-t flex justify-center">{randomQuiz.answer3}</span>
						<span className="p-2 border-gray-400 font-medium cursor-pointer hover:bg-green-300 w-full pb-3 border-t border-b flex justify-center">{randomQuiz.answer4}</span>
					</div>
				</div>
			}
		</div>
	);
});

export default QuestionInput;