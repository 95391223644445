import React, { useState, useRef } from "react"
import NavBar from "./NavBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faForward, faAsterisk } from '@fortawesome/free-solid-svg-icons'
import VideoPlayer from 'react-video-js-player'
import Video from './video/video.mp4'
import Slider from "./audio/Slider"
import song from './mp3/Suncrown - Legend of the Forgotten Centuries.mp3'
import ControlPanel from './audio/ControlPanel'
import pic1 from './img/pic1.png'
import "./styles/britishTeach.css"

const BritishTeach = () => {

    const video = Video

    const [percentage, setPercentage] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)

    const audioRef = useRef()

    const onChange = (e) => {
        const audio = audioRef.current
        audio.currentTime = (audio.duration / 100) * e.target.value
        setPercentage(e.target.value)
    }

    const play = () => {
        const audio = audioRef.current
        audio.volume = 0.1
    
        if (!isPlaying) {
          setIsPlaying(true)
          audio.play()
        }
    
        if (isPlaying) {
          setIsPlaying(false)
          audio.pause()
        }
    }

    const getCurrDuration = (e) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime
    
        setPercentage(+percent)
        setCurrentTime(time.toFixed(2))
    }

    return (
        <>
            <div className="fixed w-full">
                <NavBar />
                <div className="flex flex-wrap w-full container cus-container ml-1 mr-1 lg:ml-14 lg:mr-14 mx-auto pt-16">
                    <div className="mx-auto relative flex flex-wrap md:w-8/12 w-full xl:w-65Pxl 2xl:w-65P h-screen">
                        <div className="britishRight">
                            <p>Courses {'>'} css {'>'} Yamamoto</p> 
                        </div>

                        <div className="flex flex-grow justify-center w-full pt-4">
                            <div className="w-full relative overflow-y-auto overflow-x-hidden">
                                <div className="w-full flex h-screen flex-nowrap flex-col top-0 pr-1">
                                    <div className="w-full bg-gray-200 flex flex-col pb-48">
                                        <div className="britishMainVideo">
                                            <h1>This is MP4</h1>
                                            <VideoPlayer src={video} className="britishVideo"/>
                                            <p className="mp3-text">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni vitae dicta veritatis possimus, tenetur qui ab doloribus nostrum quod dolorem animi corporis nam quidem vero aperiam non earum provident? Consequuntur.</p>
                                        </div>

                                        <div className="britishSeparator">
                                            <div className="britishLine britishLine-left"></div>
                                            <div className="britishAsterisk"><FontAwesomeIcon icon={faAsterisk} /></div>
                                            <div className="britishLine britishLine-right"></div>
                                        </div>

                                        <div>
                                            <div className='app-container'>
                                                <h1>Player</h1>
                                                <Slider percentage={percentage} onChange={onChange} />
                                                <audio
                                                    ref={audioRef}
                                                    onTimeUpdate={getCurrDuration}
                                                    onLoadedData={(e) => {
                                                        
                                                        setDuration(e.currentTarget.duration.toFixed(2))
                                                    }}
                                                    src={song}
                                                ></audio>
                                                <ControlPanel
                                                    play={play}
                                                    isPlaying={isPlaying}
                                                    duration={duration}
                                                    currentTime={currentTime}
                                                />
                                            </div>

                                            <p className="mp3-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur iure assumenda libero, reiciendis doloribus aperiam. Facere, est nesciunt sequi expedita facilis soluta maxime beatae tenetur aperiam dignissimos delectus doloribus modi!</p>
                                        </div>

                                        <div className="britishSeparator">
                                            <div className="britishLine britishLine-left"></div>
                                            <div className="britishAsterisk"><FontAwesomeIcon icon={faAsterisk} /></div>
                                            <div className="britishLine britishLine-right"></div>
                                        </div>

                                        <div className="britishImg">
                                            <h1>Image and text</h1>

                                            <img className="britishPic" src={pic1} alt="" />

                                            <p className="mp3-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora rerum eaque voluptates expedita laborum, voluptatem illo vitae, ipsa, perspiciatis nesciunt provident vero? Possimus quod minus harum dolorum mollitia quisquam reprehenderit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-8 py-3 absolute bg-gray-600 bottom-16 justify-center w-full z-20">
                            <FontAwesomeIcon className="fontAwesomeTeach" icon={faBackward} />
                            <FontAwesomeIcon className="fontAwesomeTeach" icon={faForward} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BritishTeach