import React, { useEffect, useState } from "react";
import "../App.css";
import { Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import ilogo from "../assets/i.png";
import { updateUser } from "../endpoints/users";
import joslogo2 from '../assets/image/joslogo.png'
import profile from '../assets/image/man.png'
import home from '../assets/image/home2.png'
import courseNav from '../assets/image/course.png'
import signUp from '../assets/image/SignUp.png'
import results from '../assets/image/results.png'
import checklist from '../assets/image/checklist.png'
import openBook from '../assets/image/open-book.png'
import logIn from '../assets/image/Login.png'
import helpNav from '../assets/image/Menuhelp.png'
import '../assets/style/nav.css'

const NavBar = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();
  const path = history.location.pathname;    
  
  //detecting if user is logged in by checking the cookies
  useEffect(() => {
    if (cookies["auth"]) {
      const user = cookies["auth"].user;
      setIsLoggedIn(true);
      setTimeout(() => {
        if (user.questionChallenge === true) {
          // document.getElementById('challenge-toggle-check-top').checked = true
        } else {
          // document.getElementById('challenge-toggle-check-top').checked = false
        }
      }, 100)
    } else {
      setIsLoggedIn(false);
    }    
  }, [cookies]);

  // const updateChallenge = (e) => {
  //   const questionChallenge = document.getElementById('challenge-toggle-check-top').checked
  //   const id = cookies["auth"].user.id;
  //   const user = cookies["auth"].user;
  //   const name = user.name;
  //   updateUser({ questionChallenge, id, name }, cookies["auth"].token)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         setCookie("auth", { user : res.data.data, token : res.data.token }, { path: '/' });        
  //         if (typeof props.updateUser != 'undefined') {
  //           props.updateUser(res.data.data);
  //         }
  //       }
  //     })
  //     .catch((err) => {
        
  //     });
  // };


  // let navClassFix="";
  // if (typeof props.fixed != undefined && props.fixed == true) {
  //   navClassFix = navClassFix + " fixed w-full z-50";
  // }

  const expand = () => {
		document.getElementById("nav").style.marginLeft= "0px"
    document.getElementsByClassName('navOverlay')[0].style.opacity = '0.5'
	}

	const closeNav = () => {
		document.getElementById("nav").style.marginLeft = "-320px"
    document.getElementsByClassName('navOverlay')[0].style.opacity = '1'
	}


  return (
    <>
    {/* <Navbar color={"primary pt-0 pb-0 fixed w-full z-50"+navClassFix}> */}
      <div className="navHeader">
        <div>
          <NavbarBrand href={isLoggedIn ? "/" : "/"}>
            <img id="logoimg1" alt="" src={joslogo2} style={{width:'68px'}} />
          </NavbarBrand>
          {/* {isLoggedIn && (
            <>
              <NavLink className="navbar-link hover:text-white hover:font-bold flex nav-link" href='/dashboard'>Courses</NavLink>
              <NavLink className="navbar-link hover:text-white hover:font-bold flex nav-link" href='/my-courses'>My Courses</NavLink>
            </>
          )} */}
        </div>
        {/* shows "login" if the user is not logged in */}
        {!isLoggedIn && (
          <>
            {/* <NavItem>
              <NavLink href={(path==='/login')? '/register' : '/login'} className="">
                {(path==='/login')? 'Sign Up' : 'Login'}
              </NavLink>
            </NavItem> */}
            {/* <div className="navThreeBar" onClick={() => expand()}>
              &#9776; 
            </div> */}
            <div id="hamburger" onClick={() => expand()}>
								<span>&#9776;</span>
						</div>


            <div id="nav" className="overlay">
                <div className="navContainer">
                  <div className="logoimg">
                    <div id="nav-img">
                      <img src={joslogo2} alt="" style={{width:'68px'}}/>
                    </div>
                  </div>
                  <div id="closebtndiv">
                    <span id="closebtn" onClick={() => closeNav()}>&times;</span>
                  </div>
                </div>
                <div id="nav-content">
                  {(cookies["auth"] != null) &&
                  <div className = "overlayPM">
                    <div id="avatar">
                      {(cookies["auth"].user.profileUrl == null) && <img className="avatarpp" alt="" src={profile}/>}
                      {(cookies["auth"].user.profileUrl != null) && <img className="avatarpp" alt="" src={cookies["auth"].user.profileUrl}/>}
                    </div>
                    <div id="profileText">
                      <span>{cookies["auth"].user.name}</span><br/>
                    </div>
                  </div>}
                  <div id='overlayItemsContainer'>
                    <a href ="/dashboard" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={home}/>
                      <span>Courses</span>
                    </a>
                    <a href ="/my-courses" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={courseNav}/>
                      <span>My Courses</span>
                    </a>
                    <a href ="/account" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={signUp}/>
                      <span>My Account</span>
                    </a>
                    <a href ="/mark" className='overlayItems'>
                      <img className="navIcon" alt="" src={results}/>
                      <span>My Marks</span>
                    </a>
                    <a href ="/careers" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={openBook}/>
                      <span>Course</span>
                    </a>
                    <a href ="/register" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={home}/>
                      <span>Sign Up</span>
                    </a>
                    <a href ="/login" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={courseNav}/>
                      <span>Login</span>
                    </a>
                    <a  href={path.includes("/admin")? "/admin/logout" : "/logout"} className = 'overlayItems'>
                      <img className="navIcon" alt="" src={logIn}/>
                      <span>Logout</span>
                    </a>
                  </div>				
                </div>
              </div>	    
          </>
        )}
        {/* shows "logout" if the user is logged in */}
        {isLoggedIn && (
          <>
          {/* <div className="tooltip-body mt-2 absolute flex items-center right-0" style={{marginRight: '220px'}}>
            <div class="relative inline-block w-10 mr-1 mt-1 align-middle select-none transition duration-200 ease-in">
              <input onClick={updateChallenge} type="checkbox" name="toggle" id="challenge-toggle-check-top" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
              <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
            </div>
            <img src={ilogo} className="w-5 cursor-pointer" id="tooltip-1ctr" />
          </div> */}
          
          {/* <NavItem className="navbar-item lg:mr-2 relative profile-name pt-2 pb-2">            
            <NavLink           
              href="#"
              className="navbar-link hover:text-white hover:font-bold flex"
            >
              {cookies["auth"].user.name} <span className="ml-2" style={{marginTop: '9px', fontSize: '12px'}}>&#9660;</span>
            </NavLink>
            <div className="border-t rounded-t-xl border-light-gray border-solid z-20 profile-sub hidden profile-sub absolute w-auto h-24 right-0 top-14" style={{top: "61px"}}>
              <div className="bg-white w-44 h-12 rounded-t-xl	border-l border-r border-t border-light-gray border-solid flex pl-4 items-center">
                <a href="/account" className="poppin-font text-base text-gray-600">My Account</a>
              </div>
              <div className="bg-white w-44 h-12 rounded-b-xl	border border-light-gray border-solid  flex pl-4 items-center">
                <a href={path.includes("/admin")? "/admin/logout" : "/logout"} className="poppin-font text-base text-gray-600">Logout</a>
              </div>
            </div>
          </NavItem> */}
              <div id="hamburger" onClick={() => expand()}>
                  <span>&#9776;</span>
              </div>

              <div id="nav" className="overlay">
                <div className="navContainer">
                  <div className="logoimg">
                    <div id="nav-img">
                      <img src={joslogo2} alt="" style={{width:'68px'}}/>
                    </div>
                  </div>
                  <div id="closebtndiv">
                    <span id="closebtn" onClick={() => closeNav()}>&times;</span>
                  </div>
                </div>
                <div id="nav-content">
                  {(cookies["auth"] != null) &&
                  <div className = "overlayPM">
                    <div id="avatar">
                      {(cookies["auth"].user.profileUrl == null) && <img className="avatarpp" alt="" src={profile}/>}
                      {(cookies["auth"].user.profileUrl != null) && <img className="avatarpp" alt="" src={cookies["auth"].user.profileUrl}/>}
                    </div>
                    <div id="profileText">
                      <span>{cookies["auth"].user.name}</span><br/>
                    </div>
                  </div>}                  
                  <div id='overlayItemsContainer'>
                    <a href ="/dashboard" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={home}/>
                      <span>Courses</span>
                    </a>
                    <a href ="/my-courses" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={courseNav}/>
                      <span>My Courses</span>
                    </a>
                    <a href ="/account" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={signUp}/>
                      <span>My Account</span>
                    </a>
                    <a href ="/mark" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={results}/>
                      <span>My Marks</span>
                    </a>
                    <a href ="/careers" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={openBook}/>
                      <span>Course</span>
                    </a>
                    <a href ="/register" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={home}/>
                      <span>Sign Up</span>
                    </a>
                    <a href ="/login" className = 'overlayItems'>
                      <img className="navIcon" alt="" src={courseNav}/>
                      <span>Login</span>
                    </a>
                    <a  href={path.includes("/admin")? "/admin/logout" : "/logout"} className = 'overlayItems'>
                      <img className="navIcon" alt="" src={logIn}/>
                      <span>Logout</span>
                    </a>
                  </div>				
                </div>
              </div>
          </>
        )}
      </div>
    {/* </Navbar> */}
    {/* {!path.includes('learn') && <div class="h-14 w-full"></div>} */}
    </>
  );
};

export default NavBar;
