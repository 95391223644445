import React, { useState, useRef } from "react"
import NavBar from "./NavBar"
import Slider from "./audio/Slider"
import song from './mp3/Suncrown - Legend of the Forgotten Centuries.mp3'
import ControlPanel from './audio/ControlPanel'
import "./styles/examination.css"
import "./styles/britishTeach.css"

const Examination = () => {

    const [percentage, setPercentage] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)

    const audioRef = useRef()

    const onChange = (e) => {
        const audio = audioRef.current
        audio.currentTime = (audio.duration / 100) * e.target.value
        setPercentage(e.target.value)
    }

    const play = () => {
        const audio = audioRef.current
        audio.volume = 0.1
    
        if (!isPlaying) {
          setIsPlaying(true)
          audio.play()
        }
    
        if (isPlaying) {
          setIsPlaying(false)
          audio.pause()
        }
    }

    const getCurrDuration = (e) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime
    
        setPercentage(+percent)
        setCurrentTime(time.toFixed(2))
    }

    return(
        <>
            <NavBar />
            <div className="examinationHeader">
                <h1>Hello World</h1>

                <div className="time-circle">
                    <div className="time">
                        <p>10:50</p>
                    </div>
                </div>

                <div className="examinationBox">
                    <h2>Examination</h2>
                    <div className='ex-container'>
                        <h1>Player</h1>
                        <Slider percentage={percentage} onChange={onChange} />
                        <audio
                            ref={audioRef}
                            onTimeUpdate={getCurrDuration}
                            onLoadedData={(e) => {
                                
                                setDuration(e.currentTarget.duration.toFixed(2))
                            }}
                            src={song}
                        ></audio>
                        <ControlPanel
                            play={play}
                            isPlaying={isPlaying}
                            duration={duration}
                            currentTime={currentTime}
                        />
                    </div>

                    <div className="ex-select">
                        <h1>Answer</h1>
                        <div className="ex-answer">
                            <div>
                                <input className="ex-radio" type="radio" name="answer" id="one" class="input-hidden" />
                                <label for="one">
                                    <img src="https://images.pexels.com/photos/3885676/pexels-photo-3885676.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="" />
                                </label>
                            </div>
                            <div>
                                <input className="ex-radio" type="radio" name="answer" id="two" class="input-hidden" />
                                <label for="two">
                                    <img src="https://images.pexels.com/photos/5206263/pexels-photo-5206263.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="" />
                                </label>
                            </div>
                            <div>
                                <input className="ex-radio" type="radio" name="answer" id="three" class="input-hidden" />
                                <label for="three">
                                    <img src="https://images.pexels.com/photos/5206270/pexels-photo-5206270.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                                </label>
                            </div>
                            <div>
                                <input className="ex-radio" type="radio" name="answer" id="four" class="input-hidden" />
                                <label for="four">
                                    <img src="https://images.pexels.com/photos/5206270/pexels-photo-5206270.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Examination