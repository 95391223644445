import React, { useState, useEffect } from "react";
import { NavLink, useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import NavBar from "./NavBar";
import { getTopics } from "../endpoints/topics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faClock, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import "./styles/dashboard.css"
import { getCode } from '../endpoints/codes'

const Dashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [user, setUser] = useState({ name: "", email: "" });
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  const [skenumbers, setSkenumbers] = useState([1,2,3,4,5,6]);
  const [disabled, setDisabled] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchComplete, setSearchComplete] = useState(false);
  const [code, setCode] = useState(null);
  const [free, setFree] = useState(null);
  const [codes, setCodes] = useState([]);
  const { codeId } = useParams();
  useEffect(() => {
    if (cookies["auth"]) {
      setUser(cookies["auth"].user);
    }
    getCode({}, ((cookies["auth"])? cookies["auth"].token : null)).then(res => {
      const codesData = res.data.data
      setCodes(codesData)
      if (codeId) {
        const selcodes = codesData.filter(c => c.code == codeId)
        getTopicData(selcodes[0].id)
        setCode(selcodes[0].id)
      } else {
        getTopicData()
      }
    })
  }, [cookies]);  

  const getTopicData = (codeId = null) => {
    let codeIdPara = codeId    
    let freeVal = null
    if (codeId == 'free') {
      setFree(1)
      freeVal = 1
      codeIdPara = null      
    } else {
      freeVal = null
      setFree(null)
    }

    getTopics(((cookies["auth"])? cookies["auth"].token : null), {publish : 1, codeId : codeIdPara, free : freeVal}).then(res => {
      setTopics(res.data.data);
      setSkenumbers([]);
    });
  }

  const changeCode = (val) => {
    if (val == 'free') {
      setCode(null)      
    } else {
      setCode(val)      
    }    
    getTopicData(val)
  }
  let alreadySearching = false;
  const searchTopic = (e) => {
    setSearchComplete(false);
    setSearchValue(e.target.value);
    setTimeout(() => {
      let virtualTopics = topics;
      setTopics([]);
      setSkenumbers([1,2,3,4,5,6]);
      if (alreadySearching == false) {
        // this is basically calling ajax
        setTimeout(() => {
          setSkenumbers([]);
          alreadySearching = false;
          setDisabled(false);
          setSearchComplete(true);
          setTopics(virtualTopics);
        }, 3000);    
      }
      setDisabled(true);
      alreadySearching = true;
    }, 2000);    
  }

  const expendTopic = (index) => {
    const topicDesc = document.getElementById('topic-desc'+index);
    const topicName = document.getElementById('topic-name'+index);
    const icon = document.getElementById('icon'+index);
    if ( topicDesc.classList.contains('hidden') ) {
      icon.classList.add('rotate-ani');
      topicName.classList.remove('rounded-xl');
      topicName.classList.add('rounded-t-xl'); 
      topicDesc.classList.remove('hidden');
      topicDesc.style.height = topicDesc.scrollHeight + 'px';
    } else {
      icon.classList.add('rotate-ani-back');
      topicDesc.style.height = '0px';
      setTimeout(() => {
        topicDesc.classList.add('hidden');
        topicName.classList.remove('rounded-t-xl');
        topicName.classList.add('rounded-xl');
      }, 200);
      setTimeout(() => {
        icon.classList.remove('rotate-ani');
        icon.classList.remove('rotate-ani-back');
      }, 500);
    }
  }

  return (
    <>
      <NavBar />
      <div className="navOverlay main-container flex-col flex">
        <div className="w-full flex overflow-x-scroll" id="code-scroll">
          <span onClick={() => changeCode('free')} className={"mb-4 mt-0 ml-2 h-8 flex items-center font-bold text-sm pl-7 pr-7 rounded-2xl border-1 border-gray-400 "+((free == 1)? 'text-red-800 bg-red-200' : 'bg-gray-100')}>Free</span>
          {codes.map(c => {
            return (
              <span className={" mb-4 mt-0 ml-2 h-8 flex items-center font-bold text-sm pl-7 pr-7 rounded-2xl border-1 border-gray-400 "+((c.id == code)? 'text-red-800 bg-red-200' : 'bg-gray-100')} onClick={() => changeCode(c.id)}>{c.code}</span>
            )
          })}
          {/*           
          <span className="mb-4 mt-0 ml-2 h-8 flex items-center font-bold text-sm pl-7 pr-7 rounded-2xl bg-gray-100 border-1 border-gray-400 ">N4</span>
          <span className="mb-4 mt-0 ml-2 h-8 flex items-center font-bold text-sm pl-7 pr-7 rounded-2xl bg-gray-100 border-1 border-gray-400 bg-red-200 text-red-800">N3</span>
          <span className="mb-4 mt-0 ml-2 h-8 flex items-center font-bold text-sm pl-7 pr-7 rounded-2xl bg-gray-100 border-1 border-gray-400 ">N2</span>
          <span className="mb-4 mt-0 ml-2 h-8 flex items-center font-bold text-sm pl-7 pr-7 rounded-2xl bg-gray-100 border-1 border-gray-400 ">N1</span> */}
        </div>
        {/* <h3 className="user-header mb-6">Hello {user.name}</h3> */}
        {/* <p className="text-gray-600	m-2 mt-4 font-mono text-2xl"> */}
          {/* Please select topic you want to answer */}
        {/* </p> */}
        {/* <div className="w-full flex flex-row-reverse mb-3">
          <input type="text" disabled={(disabled == true) && ( 'disabled' )} className="w-full sm:w-64 h-10 rounded-3xl p-3 border focus:outline-none delay-150 focus:shadow-md focus:border-2 focus:border-blue-200" placeholder="Search Topics" onKeyUp={searchTopic} />
        </div> */}
        
        {/* {(disabled == true) && 
          <div className="text-lg">Searching results for {searchValue}</div>
        }
        {(searchComplete == true) && 
          <div className="text-lg">15 results found for {searchValue}</div>
        } */}
        
        <div className="cards">
        {/* {skenumbers.map((num) => {
          return (         
            <div className="w-full md:w-1/2 min-w-md  pr-4 pb-3 pt-3 mb-2 animate-pulse" key={num}>    
              <p className="rounded-sm mt-3 mb-3 h-6 w-64 bg-gray-100"></p>
              <div className="w-full bg-gray-100 h-64 flex flex-col">
                
              </div>
              <p className="rounded-sm mt-2 mb-1 h-5 w-full bg-gray-100"></p>
              <p className="rounded-sm mt-2 mb-1 h-5 w-10/12 bg-gray-100"></p>
              <p className="rounded-sm mt-2 mb-3 h-12 w-32 bg-gray-100"></p>
            </div>
          );
        })}         */}
        {
          topics.map((topic, index) => {
            return (
              // <div key={index} className="mb-2">
              //   <div id={"topic-name"+index} className="w-full bg-gray-50 border-t border-b border-l border-r border-gray-200 p-3 rounded-xl flex justify-between relative">
              //     <div className="flex flex-col">    
              //       <p className="rounded-sm "><Link to={"/topic/"+topic.id}>{topic.topicTitle}</Link></p>
              //       <p className="rounded-sm text-xs"> {topic.totalQuestion} questions | {topic.totalAnswered} answered</p> 
              //     </div>
              //     <div className="cursor-pointer text-2xl absolute right-3" onClick={() => expendTopic(index)}>    
              //       <FontAwesomeIcon icon={faAngleDown} id={"icon"+index} />
              //     </div>
              //   </div>
              //   <div id={"topic-desc"+index} className="hidden bg-white flex flex-col border-l border-r border-b border-gray-200 rounded-b-xl border-t-0 h-0 overflow-hidden transition-all delay-75">
              //     <div className="p-3 flex flex-wrap">
              //       <div id="desc-div" className='mt-3 mb-3 w-9/12'>
              //         <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
              //       </div>
              //       <ul className="pl-3 w-3/12">
              //         <li className="mb-2">Syllabuses</li>
              //         {topic.syllabuses.map((syl, index) => {
              //           return (
              //             <li key={index}><span className="ml-1 text-lg">&#10161;</span> <span className="text-sm">{syl.title}</span></li>
              //           )
              //         })}
              //       </ul>
              //     </div>
              //   </div>
              // </div>
                <div className="card" key={index}>    
                  <div className="cardContainer">
                  {/* <p className="rounded-sm mt-1 mb-3 h-6 w-full text-sm font-bold font-prompt">{topic.courseCode}</p> */}
                    <div className="card-img">
                      <img src={topic.courseGridPhoto} className="classImg" />
                      <div className="level">
                        <p>{topic.courseShortDesc}</p>
                      </div>
                    </div>
                    <div className="title-open">
                      <p className="dashboardTitle">
                        {/* {topic.courseCode} :  */}
                        {topic.topicTitle}</p>               
                      <Link to={"/topic/"+topic.id}><button className="open-btn">OPEN</button></Link>
                    </div>
                    <div className="fontAwesomeContainer">
                      <div className="fontAwesome">
                        <FontAwesomeIcon icon={faUserAlt} />
                        {/* <p>219k</p> */}
                        <p>{topic.totalStudents}</p>
                      </div>
                      <div className="fontAwesome">
                        <FontAwesomeIcon icon={faClock} />
                        <p>{topic.totalHours}h</p>
                      </div>
                    </div>
                  </div>
                </div>
            );
          })
        }
        </div>

        {/* {(topics.length > 0) && (
          <div className="mb-10 hover:bg-gray-400 hover:font-bold transition-colors delay-75 hover:text-white shadow-sm mt-4 w-64 border-2 border-gray-200 cursor-pointer h-auto flex text-lg text-gray-500 items-center justify-center p-2 mx-auto rounded-full">
            SEE MORE
          </div>
        )} */}
      </div>    
    </>
  );
};

export default Dashboard;
