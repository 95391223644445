import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import AdminNavBar from "../AdminNavBar";
import NavBar from "../NavBar";
import env from "../../env";
import { getCareerCourses, deleteCareerCourse, saveCareerCourse, updateCareerCourseOrder } from "../../endpoints/career"
import Sortable from 'sortablejs';
import { getTopics } from "../../endpoints/topics";
import Select from 'react-select'

const CareerCourse = () => {
	const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
	const { id } = useParams();
	const [token, setToken] = useState("");
	const [user, setCurrentUser] = useState({});
  const [courses, setCourses] = useState([]);
  const [options, setOptions] = useState([]);  
  const [selectedOptions, setSelectedOptions] = useState([]); 
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([])

  useEffect(async () => {
		setCurrentUser(cookies["auth"].user);    
    setToken(cookies["auth"].token);		
    var el = document.getElementById('courses');
    var sortable = Sortable.create(el);
    const courses = await showCareerCourses();
    getCourseOptions(courses)
  }, [cookies]);

  const getCourseOptions = (courses) => {
    const filterObj = {}
    if (cookies["auth"].user.accountType == true) {
      filterObj.userId = cookies["auth"].user.id
    }
    getTopics(cookies["auth"].token, filterObj).then(res => {
      const filters = res.data.data.filter(d => {
        return courses.includes(d.id) === false
      })
      const optionsSta = []
      filters.map(c => {
        optionsSta.push({ value : c.id , label : c.topicTitle })
      })
      setOptions(optionsSta);
    })
  }

  const handleSelectChange = (changeOptions) => {
    setSelectedOptions(changeOptions);    
  }

  const showCareerCourses = async () => {
    return await getCareerCourses(id, cookies["auth"].token).then(res => {
      setCourses(res.data.data);      
      const ids = res.data.data.map(d => {
        return d.topicId
      })
      return ids
    })
  }

  const handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) {
      await deleteCareerCourse(id, token);
      const courses = await showCareerCourses();
      getCourseOptions(courses)
    }
  }

  const addCourse = () => {
    if (selectedOptions.length > 0) {
      const ids = []
      selectedOptions.forEach(o => {
        ids.push(o.value)
      })
      saveCareerCourse({ careerId : id, topicIds : ids }, token).then(async res => {
        const resData = res.data;
        if (resData.success == true) {
          const courses = await showCareerCourses();
          getCourseOptions(courses)
          setSelectedOptions([])
        }
      }).catch(err => {
        console.log(err)
        setSelectedOptions([])
      })
    }
  }

  const saveOrder = () => {
    const courses = document.getElementsByClassName('career-course');
    const careerCourses = [];
    for (let serialNo = 0; serialNo < courses.length; serialNo++) {
      const course = courses[serialNo];
      const id = course.getAttribute('data-id');
      careerCourses.push({ id, serialNo })
    }
    if (careerCourses.length > 0) {
      updateCareerCourseOrder(careerCourses, token).then(res => {
        if (res.data.success) {
          setSuccess('Course Order Updated')
          setTimeout(() => {
            setSuccess(false)
          }, 7000)
        } else {
          showError()
        }
      }).catch(err => {
        showError()
      })
    }     
  }
  
  const showError = () => {
    setErrors(['Oops! something went wrong, please try again!'])
    setTimeout(() => {
      setErrors(false)
    }, 7000)
  }
  return (
    <>
      {(user.accountType != true) && <AdminNavBar page="career" />}
      {(user.accountType == true) && <NavBar />}
      <div className={(user.accountType == true)? "container mx-auto cus-container lg:p-4 mt-4" : "m-10"}>
        <Link style={{textDecoration: "none"}} to={(user.accountType == true)? "/teacher/career" : "/admin/career"}><span className="font-bold text-4xl hover:no-underline cursor-pointer">&#8592;</span></Link>      
        <fieldset class="border p-3 mt-3 flex">
          <legend class="w-auto text-lg font-semibold">Add Courses {(courses.length > 0)? ' - ' + courses[0].career.careerName : '' }</legend>
          <div className="w-96">
            <Select options={options} isMulti onChange={(c) => handleSelectChange(c)} value={selectedOptions} />
          </div>
          <button onClick={() => addCourse()} class="ml-1 w-16 bg-green-200 p-1 h-8 rounded text-sm text-gray-600">Add</button>
        </fieldset>
        {success && <div className="mt-6 alert alert-success">{success}</div> }
        {errors.map((error, index) => {
          return (
            <div
              className="alert alert-danger mt-2"
              style={{ width: "70%" }}
              key={index+'errs'}
            >
              {error}
            </div>
          );
        })}
        <div className="w-full max-w-lg flex justify-between h-auto mt-6">
          <div className="font-bold">Course</div>
          <div className="font-bold">Option &nbsp; &nbsp; &nbsp;</div>
        </div>        
        <div id="courses">
        {courses.map(c => {
          return (
            <div className="w-full max-w-lg flex justify-between h-auto border-2 p-3 mt-2 career-course" data-id={c.id}>
              <div className=" w-auto">
                <span className="mr-2 cursor-pointer text-lg">&#x2725;</span>
                {c.topic.courseCode} : {c.topic.topicTitle}
              </div>
              <div className="text-right">
                <button onClick={() => handleDelete(c.id)} class="ml-1 w-16 bg-red-600 p-1 h-8 rounded text-sm text-white">Remove</button>
              </div>
            </div>
          )
        })}
        </div>
        <button onClick={() => saveOrder()} class="ml-1 w-auto bg-green-600 p-2 h-auto rounded text-md mt-4 text-white">Save Order</button>
      </div>
    </>
  );
};

export default CareerCourse;

