import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminNavBar from "../AdminNavBar";
import { saveTopic, getTopicDetail, topicPhotoUpload, deleteTopic } from "../../endpoints/topics";
import Teaching from "./teaching";
import QuestionTab from "./questionTab";
import { saveSyllabus, deleteSyllabus } from "../../endpoints/syllabus";
import DraftEditor from "./DraftEditor.js";
import {stateToHTML} from 'draft-js-export-html'; 
import { convertFromRaw } from 'draft-js'
import ImageCrop from "./ImageCrop.jsx";
import { blobToDataURL, dataURItoBlob } from "../../common/imageFunc";
import { createLinkDecorator } from "./../../components/admin/onAddLink";
import { getCode } from "../../endpoints/codes";

const Question = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const history = useHistory();

  const [user, setUser] = useState({});
  const [token, setToken] = useState({});

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [topicTitle, setTopicTitle] = useState("");      
  const [topicId, setTopicId] = useState("");  
  const [publish, setPublish] = useState(false);
  const [publishDate, setPublishDate] = useState(null);
  const [answerCnt, setAnswerCnt] = useState(0);
  const { questionId } = useParams();
  const [tab, setTab] = useState('about');
  const [outline, setOutline] = useState([]);
  const [courseShortDesc, setDescription] = useState('');
  const editor = useRef(null);
  const photoCropper=React.createRef(null);
  const photoCropperEditor=React.createRef(null);
  const [courseGridPhoto, setCourseGridPhoto] = useState('');
  const [finishCrop, setFinishCrop] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [finishCrop2, setFinishCrop2] = useState(false);
  const [cropping2, setCropping2] = useState(false);
  const [codes, setCodes] = useState([]);
  const [code, setCode] = useState(null);
  const [courseCode, setCourseCode] = useState(null);
  const [level, setLevel] = useState(null);
  const [totalHours, setTotalHours] = useState(null);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    setUser(cookies["auth"].user);    
    setToken(cookies["auth"].token);
    if(questionId != undefined){
      setTopicId(questionId);
      getTopicDetail(questionId, cookies["auth"].token).then(res => {
        const data = res.data.data;
        setTopicTitle(data.topicTitle);
        setPublish(data.publish);
        setPublishDate(data.publishDate);
        setAnswerCnt(data.answerSheets.length);
        setDescHtml(data.description);
        // const levelPlus = data.level + 1;
        // document.getElementById('level'+levelPlus+'Radio').checked = true;
        setCode(data.codeId);
        setCourseCode(data.courseCode);
        // setTimeout(() => {
        //   document.getElementById('codeIdSel').value = parseInt(data.codeId);
        // }, 200) 
        if (data.courseShortDesc) {
          if (data.courseShortDesc.length > 100) {
            setDescription(data.courseShortDesc.substr(0, 100))
            document.getElementById('topic-desc').value = data.courseShortDesc.substr(0, 100);
          } else {
            setDescription(data.courseShortDesc);  
            document.getElementById('topic-desc').value = data.courseShortDesc;
          }          
        }        
        setCourseGridPhoto(data.courseGridPhoto);
        setOutline(data.syllabuses);
        handleSetLevel(data.level);
        setLevel(data.level);
        setTotalHours(data.totalHours);
        setPrice(data.price);
      }).catch(err => {
        console.log(err);
        history.replace("/admin/question");
      });
    }
    getCode({}, cookies["auth"].token).then(res => {
      setCodes(res.data.data);
    })
  }, [cookies]);

  const selectElement = (id, valueToSelect) => {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

  const setDescHtml = (html) => {
		setTimeout(() => {
      const decorator = createLinkDecorator();

			editor.current.createWithContentForEdit(html, decorator);
		}, 400);
	}

  const convertFromJSONToHTML = () => {
    if(editor == null || editor.current == null) {
      return '';
    } else {
      return stateToHTML(convertFromRaw(editor.current.getRawHtml()));
    } 
    // return stateToHTML(convertFromRaw(JSON.parse(text)))     
  }

  const handelSaveSetting = async(publishStatus = null, finish = false) => {
    let canvas = '';
    if (finishCrop == true) {
      canvas = photoCropper.current.children[0];
    }
    // console.log(canvas);
    // return false;
    if (answerCnt > 0) {
      // alert('Sorry you cannot change setting, because someone already answered!');
      // return false;
    }
    if(publishStatus == false){
      if(!window.confirm("Do you really want to unpublish this course?")){
        return;
      }
    }
    const newErrors = [];
    if (!topicTitle) {
      newErrors.push("Course is required");
    }
    if (courseShortDesc == '') {
      newErrors.push("Course Short Description is required");
    }
    if (courseShortDesc.length > 100) {
      newErrors.push("Course Short Description should not be longer than 100 characters.");
    }
    if (code == null || code == '') {
      newErrors.push('Code is required to choose');
    }
    if (document.querySelector('input[name="level"]:checked') == null) {
      newErrors.push('Level must be choose');
    }

    const headingTextArr = document.getElementsByClassName('heading-text');
    const anchorList = []
    for ( const i in headingTextArr ) {
      if (Number.isInteger(parseInt(i))) {     
        const heading = headingTextArr[i]
        const headingSpan = heading.querySelector('span span')
        const id = heading.href
        const idArr = id.split('#')
        const idText = idArr[1];
        const spanText = headingSpan.textContent
        anchorList.push('<a href="#'+idText+'">'+spanText+'</a>');
      }
    }
    // return false;
    let descHtml = convertFromJSONToHTML();
    // const anchorList = editor.current.getAnchorList();
    if ( anchorList.length > 0 ) {
      const replaceTextWithClassAndId = [];
      for (const i in anchorList) {
        const ai = anchorList[i];
        const id = ai.substring(
          ai.lastIndexOf('#') + 1, 
          ai.lastIndexOf('">')
        );
        const airep = ai.replace('<a href="', `<a id="${id}" class="anchorText" href="`);
        replaceTextWithClassAndId.push({
          replaceText : ai,
          replaceTextWithClassAndId : airep
        })
      }
      for (const i in replaceTextWithClassAndId) {
        const rT = replaceTextWithClassAndId[i];
        descHtml = descHtml.replace(rT.replaceText, rT.replaceTextWithClassAndId)
      }
    }

    // return false;
    if(descHtml == null || descHtml == '' || descHtml == '<p><br></p>'){
      newErrors.push("Description is required");
    }
    if(outline.length == 0){
      newErrors.push("Syllabus is required");
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, 0);
      return;
    }
    const arr = {};
    if(topicId != ""){
      arr.id = topicId;
    }
    if(topicTitle != ""){
      arr.topicTitle = topicTitle;
    }
    if(publishStatus != null){
      arr.publish = publishStatus;
      setPublish(arr.publish);
    }
    if(descHtml != null && descHtml != '' && descHtml != '<p><br></p>'){
      arr.description = descHtml;
    }
    if (courseShortDesc != '') {
      arr.courseShortDesc = courseShortDesc;
    }
    if(topicId == "" && outline.length > 0){
      const outlineTextArr = [];
      for (let i in outline) {
        outlineTextArr.push(outline[i].title);
      }
      arr.outline = outlineTextArr.join('|');
    }
    if (document.querySelector('input[name="level"]:checked').value != null) {      
      arr.level = parseInt(document.querySelector('input[name="level"]:checked').value)
    }
    if (code != null && code != '') {
      arr.codeId = code;
    }
    if (totalHours != null) {
      arr.totalHours = totalHours;
    }
    if (price != null) {
      arr.price = price
    }
    return await saveTopic(arr, token).then(async res => {
      const retTopicId = res.data.data.id;      
      // if (res.data.refresh == true) {
        // const paths = window.location.pathname.split('/');
        // const lastPath = paths[paths.length - 1];
        // alert(lastPath)
        // if (lastPath == 'question') {
          //window.location.href = '/admin/question/' + retTopicId
        // } else {
          // window.location.reload()
        // }        
      // }      
      setCourseCode(res.data.data.courseCode);
      if (finishCrop == true) {
        const cropDataBlob = await new Promise(async (resolve, reject) => {
          await canvas.toBlob((blob) => {
            resolve(blob);
          });
        });	
        await topicPhotoUpload(retTopicId, token, cropDataBlob);
      }
      if(topicId == ""){
        setTopicId(retTopicId);        
        setOutline([]);        
      }
      if(publishStatus == true){
        setPublishDate(res.data.data.publishDate);
      }
      setSuccess(res.data.message);
      const outlineArr = [];
      setTimeout(() => {
        if (typeof res.data.data.syllabus != 'undefined') {
          res.data.data.syllabus.map((o, i) => {
            outlineArr.push(o);
          });
          setOutline(outlineArr);
          handleSetLevel(res.data.data.level);
        }
      }, 1500);
      setTimeout(() => {
        setSuccess(false);
        handleSetLevel(res.data.data.level);
        // setTab('teaching');                
      }, 5000);
      handleSetLevel(res.data.data.level);
      setLevel(res.data.data.level);
      window.scrollTo(0, 0);
      if (finish == true) {
        document.querySelector('button[data-target=panel-2]').click()
      }
      setTimeout(() => {
        history.replace('/admin/question/' + retTopicId);
        // window.location.href = '/admin/question/' + retTopicId;
      }, 1600)
      return retTopicId;
    }).catch(err => {
      if (err.response.status == 400 && err.response.data.err) {
        setErrors([err.response.data.err]);  
      } else {
        setErrors(["Oops! Something error occoured!"]);      
      }            
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, 0);
    })   
  }

  const addOutline = async () => {
    const outlineTxt = document.getElementById('txt-outline').value; 
    if (outlineTxt == "") {
      return false;
    }
    const outlineArr = [];
    outline.map((o, i) => {
      outlineArr.push(o);
    });
    if (topicId != "") {
      // await calling ajax insert
      const retSyllabus = await saveSyllabus({ title : outlineTxt, topicId : topicId }, token);
      outlineArr.push({ id : retSyllabus.data.data.id, title : outlineTxt, syllabusCode : retSyllabus.data.data.syllabusCode });
    }else{
      outlineArr.push({ id : null, title : outlineTxt});
    }
    setOutline(outlineArr);
    document.getElementById('txt-outline').value = ""; 
  }

  const deleteOutline = async (i, id) => {
    const res = window.confirm("Are you sure you want to delete");
    if (res == false) {
      return res;
    }
    if (id != null) {
      // calling ajax delete
      const ret = await deleteSyllabus(id, token);
      if (ret.data.success == false) {
        if (typeof ret.data.message != 'undefined') {
          alert(ret.data.message);
          return false;
        }
      }
    }
    const outlineArr = [];
    outline.map((o, i) => {
      outlineArr.push(o);
    });
    outlineArr.splice(i, 1);
    setOutline(outlineArr);
  }

  const changeTab = (tab) => {
    if (topicId == "") {
      alert('Please make sure to complete About Course tab first');
      return false;
    }
    setTab(tab);
  }

  const editOutline = (i, id) => {
    document.getElementById('lbl'+i).classList.add('hidden');
    document.getElementById('txt'+i).classList.remove('hidden');
    document.getElementById('upd'+i).classList.remove('hidden');
    document.getElementById('can'+i).classList.remove('hidden');
    document.getElementById('edit'+i).classList.add('hidden');
    document.getElementById('del'+i).classList.add('hidden');
    document.getElementById('txt'+i).value = outline[i].title;
  }

  const updateOutline = async (i, id) => {
    // calling ajax update
    const outlineTxt = document.getElementById('txt'+i).value;
    await saveSyllabus({ title : outlineTxt, id : id, topicId : topicId }, token);
    outline[i].title = outlineTxt;
    const outlineArr = [];
    outline.map((o, i) => {
      outlineArr.push(o);
    });
    setOutline(outlineArr);
    resetUpdate(i);
  }

  const cancelUpdate = (i, id) => {
    resetUpdate(i);
  }

  const resetUpdate = (i, id) => {
    document.getElementById('lbl'+i).classList.remove('hidden');
    document.getElementById('txt'+i).classList.add('hidden');
    document.getElementById('upd'+i).classList.add('hidden');
    document.getElementById('can'+i).classList.add('hidden');
    document.getElementById('edit'+i).classList.remove('hidden');
    document.getElementById('del'+i).classList.remove('hidden');
    document.getElementById('txt'+i).value = '';
  }

  const changeCropStatus = (status) => {
    setFinishCrop(status);
    setCropping(status);    
  }

  const changeCropStatus2 = (status) => {
    setFinishCrop2(status);
    setCropping(status);
  }

  const insertImageToEditor = async () => {
    const cropDataBlob = await new Promise(async (resolve, reject) => {
      const canvas = photoCropperEditor.current.children[0];
      await canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
    blobToDataURL(cropDataBlob, function(dataUrl) {
      editor.current.setUpImg(dataUrl);
      document.getElementById('crop-popup').classList.add('hidden');
    });
  }

  const closePopup = () => {
    document.getElementById('crop-popup').classList.add('hidden');
  }

  const handleDeleteTopic = () => {
    if(!window.confirm("Are you sure you want to delete this course?")){
      return false;
    }
    deleteTopic(topicId, token).then(res => {
      if (res.data.success == true) {
        alert('The course has successfully deleted');
        history.replace("/admin/topics");
      } else {
        alert(res.data.error);
      }
    });
  }

  const changeShortDesc = (e) => {
    const text = e.target.value
    if (text.length > 100) {
      e.target.value = courseShortDesc
    } else {
      setDescription(e.target.value);
    }    
  }

  const handleSetLevel = (level) => {
    // setLevel(level)      
    setTimeout(() => {
      const levelPlus = level + 1;
      document.getElementById('level'+levelPlus+'Radio').checked = true;  
    }, 300)
  }

  const appear = (divId) => {
    document.getElementById(divId).classList.add('ofs-fadeout-ani')    
  }

  const disappear = (divId) => {
    document.getElementById(divId).style.display = 'flex'    
    document.getElementById(divId).classList.remove('ofs-fadeout-ani')    
    document.getElementById(divId).classList.add('ofs-fadein-ani')    
    setTimeout(() => {
      document.getElementById(divId).style.display = 'none'      
      document.getElementById(divId).classList.remove('ofs-fadein-ani')      
    }, 200)
  }

  const next = (div1, div2) => {
    disappear(div1);
    setTimeout(() => {
      appear(div2);
    }, 300)
  }

  return (
    <div id="rootDiv" className="w-full">
      <div id="crop-popup" className="hidden">
        <div className="popup-box w-11/12 h-auto relative" >
          <span className="absolute right-4 cursor-pointer top-3 " onClick={closePopup}>&#10006;</span>
          <h2 className="text-lg">Insert Image to Text Editor</h2><br/>
          <ImageCrop minWidth={680} startCropping={() => setCropping2(true)} cancelCrop={() => changeCropStatus2(false)} finishCrop={() => changeCropStatus2(true)} ref={photoCropperEditor} id={'_courseDetailCropper'} length={16} height={6}/>
          <button onClick={() => insertImageToEditor()} className={(finishCrop2==false)? 'hidden' : 'p-2 bg-green-500 rounded-sm mr-1 text-white'} >Insert</button>
        </div>
        <div className="popup-cover w-full" style={{height: document.body.scrollHeight}}></div>
      </div>
      <AdminNavBar page="question" />
      <div className="m-10">
        <div className="flex mb-10">
          <button onClick={() => setTab('about')} data-target="panel-3" className={(tab == 'about')? 'tab-button tw-active' : 'tab-button'}>
            About Course
          </button>
          <button onClick={() => changeTab('teaching')} data-target="panel-2" className={(tab == 'teaching')? 'tab-button tw-active' : 'tab-button'}>
            Course Content
          </button>
          <button onClick={() => changeTab('question')} data-target="panel-1" className={(tab == 'question')? 'tab-button tw-active' : 'tab-button'}>
            Questions Setup
          </button>
        </div>
      </div>
      <div className="m-10">
        {success && <div className="alert alert-success">{success}</div> }
        {errors.map((error, index) => {
          return (
            <div
              className="alert alert-danger mt-2"
              style={{ width: "70%" }}
              key={index+'errs'}
            >
              {error}
            </div>
          );
        })}
      </div>
      <div className={(tab == 'about')? 'm-10 flex flex-col' : 'm-10 hidden'}>
        <div className="flex" style={{height:'41px'}}>
          <Link to="/admin/topics"><button className="bg-yellow-400 mr-2 p-2 rounded text-md text-gray-600">&nbsp;&nbsp;Back&nbsp;&nbsp;</button></Link>
          {(topicId != '') &&
          <>
            <button 
              onClick={() => handelSaveSetting()}
              className="bg-green-600 p-2 rounded text-md text-gray-100 mr-2 w-32"
              id="saveSetting"
            >
              Save Setting
            </button>          
            <button 
              onClick={() => handelSaveSetting(!publish)}
              className="bg-green-600 p-2 w-32 rounded text-md text-gray-100 mr-2">
              {(publish == false)? 'Publish' : 'Unpublish'}
            </button>
            <button 
              onClick={() => handleDeleteTopic()}
              className="bg-red-600 p-2 w-32 rounded text-md text-gray-100 mr-2">
              Delete
            </button>
          </>
          }
        </div>
        <p className="mt-4 mb-4">{(publishDate != null)? 'Published At : '+(new Date(publishDate)).toString() : ''}</p>
        <div id="div-abtcourse-1" className="abt-course-setup" >
          {(courseCode != null) && <div className="font-semibold">Course Code : {courseCode} <br></br><br></br></div>}
          {/* {(courseCode == null) &&
            <> */}
              <label>Code</label>
              <select className="bg-gray-200 h-8 mb-4 max-w-3xl" id="codeIdSel" style={{padding: 'inherit'}}
                onChange={(e) => {
                  setCode(e.target.value);
                }}>
                <option value="">Select Code</option>
                {codes.map((c, index) => {
                  let selected = '';
                  if (c.id == code) {
                    selected = 'selected';
                  }
                  return (
                    <option selected={selected} key={index} value={c.id}>{c.code}</option>
                  )
                })}
              </select>
            {/* </>
          }         */}
          {/* {(level != null) && <div className="font-semibold">Level : {(level == 0)? 'Begineer' : (level == 1)? 'Intermediate' : 'Expert' } <br></br><br></br></div>} */}
          {/* {(level == null) &&
            <> */}
              <label>Level</label>
              <div className="mb-4">
                <input type="radio" onClick={() => handleSetLevel(0)} className="level-radio" value={0} name="level" id="level1Radio" /> Begineer &nbsp;&nbsp;
                <input type="radio" onClick={() => handleSetLevel(1)} className="level-radio" value={1} name="level" id="level2Radio" /> Intermediate &nbsp;&nbsp;
                <input type="radio" onClick={() => handleSetLevel(2)} className="level-radio" value={2} name="level" id="level3Radio" /> Expert             
              </div>
            {/* </>
          }         */}

          Course Name 
          <input type="text" 
            value={topicTitle}
            onChange={(e) => {
              setTopicTitle(e.target.value);
            }}
            className="bg-gray-200 rounded h-8 p-2 w-full mb-4 max-w-3xl" />
          
          Total Hours
          <input type="number" 
            value={totalHours}
            onChange={(e) => {
              setTotalHours(e.target.value);
            }}
            className="bg-gray-200 rounded h-8 p-2 w-full mb-4 max-w-3xl" />

          Price
          <input type="number" 
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            className="bg-gray-200 rounded h-8 p-2 w-full mb-4 max-w-3xl" />

          <button className="nextbtn" onClick={() => next('div-abtcourse-1', 'div-abtcourse-2')}>Next</button>
        </div>

        <div id="div-abtcourse-2" className="abt-course-setup" style={{ display : 'none' }}>
          Course Grid Photo
          {(courseGridPhoto != '' && cropping == false) && <img src={courseGridPhoto} className="max-w-3xl w-auto" />}
          <div className="max-w-5xl"><ImageCrop startCropping={() => setCropping(true)} cancelCrop={() => changeCropStatus(false)} finishCrop={() => changeCropStatus(true)} ref={photoCropper} id={'_courseCropper'} /></div>
          
          Course Short Description
          ({100 - courseShortDesc.length} characters remaining)
          <textarea id="topic-desc" className="bg-gray-200 rounded h-18 p-2 w-full mb-4 max-w-3xl"
            onChange={changeShortDesc}>{courseShortDesc}</textarea>
          <div className="flex">
            <button className="backbtn"  onClick={() => next('div-abtcourse-2', 'div-abtcourse-1')}>Previous</button>
            <button className="nextbtn"  onClick={() => next('div-abtcourse-2', 'div-abtcourse-3')}>Next</button>
          </div>
        </div>

        <div id="div-abtcourse-3" className="abt-course-setup" style={{ display : 'none' }}>
          Description        
          <div className="max-w-3xl mb-4">
            <DraftEditor ref={editor} componentId={'topicDescEditor'} initialText={null} token={cookies["auth"].token} imageCropper={'croppedImg_courseDetailCropper'} />
          </div>
          <div className="flex">
            <button className="backbtn"  onClick={() => next('div-abtcourse-3', 'div-abtcourse-2')}>Previous</button>
            <button className="nextbtn"  onClick={() => next('div-abtcourse-3', 'div-abtcourse-4')}>Next</button>
          </div>
        </div>
        
        <div id="div-abtcourse-4" className="abt-course-setup" style={{ display : 'none' }}>
          <label className=" underline">Syllabus</label>
          <div className="flex flex-col">
            {outline.map((line, index) => {
              return (
                <div key={index+'syltopic'} className="flex max-w-3xl">
                  <label id={'lbl'+index} className="mt-1 w-9/12">{line.syllabusCode} : {line.title}</label>
                  <input id={'txt'+index} type="text" className="hidden bg-gray-200 rounded h-8 p-2 w-9/12 lg:w-11/12 mb-1 max-w-3xl" onChange={(e) => console.log(e.target.value)} />
                  {(line.id != null) && 
                    <button id={'edit'+index} onClick={() => editOutline(index, line.id)} className="ml-1 w-12 bg-yellow-300 p-1 h-8 rounded text-sm text-gray-100">Edit</button>
                  }
                  <button id={'del'+index} onClick={() => deleteOutline(index, line.id)} className="ml-1 w-12 bg-red-600 p-1 h-8 rounded text-sm text-gray-100">Delete</button>
                  <button id={'upd'+index} onClick={() => updateOutline(index, line.id)} className="hidden ml-1 w-12 bg-yellow-300 p-1 h-8 rounded text-sm text-gray-100">Save</button>
                  <button id={'can'+index} onClick={() => cancelUpdate(index)} className="hidden ml-1 w-12 bg-red-600 p-1 h-8 rounded text-sm text-gray-100">Back</button>
                </div>
              );
            })}
          </div>
          <div className="flex">
            <input type="text" className="bg-gray-200 rounded h-8 p-2 w-10/12 lg:w-11/12 mb-4 max-w-3xl" id="txt-outline" />
            <button onClick={addOutline} className="ml-1 w-12 bg-green-600 p-1 h-8 rounded text-sm text-gray-100">Add</button>
          </div>
          <div className="flex">
            <button className="backbtn"  onClick={() => next('div-abtcourse-4', 'div-abtcourse-3')}>Previous</button>            
            <button className="nextbtn"  onClick={() => handelSaveSetting(null, true)}>Finish</button>            
          </div>
        </div>                
      </div>
      {(topicId != "") && 
        <>
          <Teaching tab={tab} setErrors={setErrors} setSuccess={setSuccess} topicId={topicId} syllabuses={outline} token={token} />
        
          <QuestionTab level={level} courseCode={courseCode} tab={tab} setErrors={setErrors} setSuccess={setSuccess} topicId={topicId} syllabuses={outline} answerCnt={answerCnt} token={token} />    
        </>
      }
    </div>
  );
};

export default Question;
