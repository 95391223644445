import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getTopicDetail } from "../endpoints/topics";
import { getQuestions } from "../endpoints/questions";
import { saveAnswer, getAnswers } from "../endpoints/answers";
import NavBar from "./NavBar";
import QuestionDetail from "../common/QuestionDetail";
import AnswerOncePopup from "../components/AnswerOncePopup";
import { showMmSsFromSec } from "../common/timeFunc";
import "./styles/userQuestion.css"

let timerInterval;
let timer = 0;
let totalTime = 0;

const UserQuestion = () => {
  const [cookies] = useCookies(["cookies"]);
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [topicTitle, setTopicTitle] = useState("");      
  const [courseCode, setCourseCode] = useState("");      
  const [question, setQuestion] = useState([]);
  const { topicId, syllabusId } = useParams();
  const [user, setUser] = useState({});
  const [startDate, setStartDate] = useState({});
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alreadyAnsweredCheck, setAlreadyAnswered] = useState(false);
  const [answerSheetId, setAnswerSheetId] = useState(null);

  useEffect(() => {
    setStartDate(new Date());
    setUser(cookies["auth"].user);
    if(topicId != undefined){
      getTopicDetail(topicId, cookies["auth"].token).then(res => {
        setTopicTitle(res.data.data.topicTitle);
        setCourseCode(res.data.data.courseCode);
        if (res.data.data.publish == 0) {
          history.replace("/dashboard");  
        }        
      }).catch(err => {
        history.replace("/dashboard");
      });
      getAnswers(cookies["auth"].token, 
        { 
          userId : cookies["auth"].user.id,
          syllabusId : syllabusId
        }
      ).then(res => {
        setAlreadyAnswered(true);
        if (res.data.data.length > 0) {
          // alert('You already answered on this syllabus so you cannot answer again!');
          // history.replace("/dashboard"); !important need to open again
        }
      }).catch(err => {
        history.replace("/dashboard");
      })
      loadQuestions(topicId);
    }

    return () => {
      window.clearInterval(timerInterval);
    }
  }, [cookies]);

  const loadQuestions = () => {
    getQuestions({syllabusId : syllabusId}, cookies["auth"].token).then(res => {
      if (res.data.success == true) {
        setQuestion(res.data.data);    
      }
    });
  }

  const handleSubmitAnswer = (start = false) => {
    if (answers.length != question.length && start == false) {
      setErrors(["Please answer all questions!"]);
      setTimeout(() => setErrors([]), 5000);
      window.scrollTo(0, 0);
      return false;
    }
    let correctAnswer = 0;
    answers.forEach((ans,index) => { 
      if (ans.userAnswered == question[index].bestAnswer) {
        correctAnswer++;
      }
    });
    const answerData = {
      topicId : topicId,
      userId : user.id,
      correctAnswer : correctAnswer,
      totalTimeSpent : totalTime,
      startDateTime : startDate,
      answers : answers,
      answerSheetId : answerSheetId,
      syllabusId : syllabusId
    }
    saveAnswer(answerData, cookies["auth"].token).then(res => {
      if (res.data.success == true && start == false) {
        setSuccess("Congractulation! You answered all questions. Your answer sheet was submitted. We will redirect to 'My Marks' Screen.");
        document.getElementById("totalTimeId").innerHTML = showMmSsFromSec(totalTime);
        setTimeout(() => history.replace('/mark'), 7000);
        window.scrollTo(0, 0);
      }
      if (start === true) {
        setAnswerSheetId(res.data.data.id);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const chooseAnswer = (no, answer) => {
    if (question[no].timerDuration - timer < 0) {
      return false
    } 
    answers[no] = {
      questionId : question[no].id,
      userAnswered : answer,
      timespent : timer
    };
    setAnswers(answers);
    window.clearInterval(timerInterval);
    setTimeout(() => {
      timer = 0;
      openQuestion(no+1);
      if (question[no+1]) {
        startTimer(question[no+1].timerDuration);
      }      
    }, 100);
    
  }

  const startAnswer = () => {
    timer = 0;
    totalTime = 0;
    setTimeout(() => {
      setLoading(false);
      openQuestion(0);
      startTimer(question[0].timerDuration);
      handleSubmitAnswer(true);
    }, 1000);
  }

  const startTimer = (duration) => {
    timerInterval = window.setInterval(() => {

      if (timer < duration) {
        timer++;
        totalTime++;
        document.getElementById("timerId").innerHTML = duration - timer
      } else {
        const qbs = document.getElementsByClassName('question-box');
        for (let qb of qbs) {
          if (!qb.classList.contains('hidden')) {
            const idname = qb.getAttribute('id');
            const no = idname.replace('q-', '');
            document.getElementById('null-answer-radio-'+no).click()
            break;
          }
        }        
      }

    }, 1000);
  }

  const openQuestion = (no) => {
    const questionBox = document.getElementsByClassName('question-box');
    if(no < question.length){
      setTimeout(() => {
        questionBox[no].classList.remove('hidden');
      }, 500);
    } else {
      window.clearInterval(timerInterval);
      handleSubmitAnswer();
    }
    if( no > 0 ){
      questionBox[no-1].classList.add('hidden');
    }
  }

  const myTimer = () => {
    timer++;
    totalTime++;
    document.getElementById("timerId").innerHTML = showMmSsFromSec(timer);
  }

  return (
    <>
      {(alreadyAnsweredCheck == true) && <AnswerOncePopup startAnswer={() => startAnswer()} />}
      <NavBar />
      <div className="userQuestionMain navOverlay">
        {(loading === true)?
        <div className="">
          {/* <div className="animate-pulse w-56 h-7 bg-gray-200 rounded"></div>
          <div className="mt-3 animate-pulse w-40 h-7 bg-gray-200 rounded"></div>
          <div className="mt-3 animate-pulse w-full h-40 bg-gray-200 rounded"></div> */}
        </div>
        :
        <div className="">
          <h1 className="userQuestionHeader">
            {/* {courseCode} :  */}
            {topicTitle}</h1>
          {success && <div className="userQuestionSuccess">{success}</div> }
          {errors.map((error, index) => {
            return (
              <div
                className="alert alert-danger mt-2 w-full"
                key={index}
              >
                {error}
              </div>
            );
          })}

          <div className='time-circle'>
            <div className="time">
              { (answers.length > 0) && 
                <div className="totalTotalTime flex-col items-center">
                  <p className="totalTime text-3xl font-bold">Total Time</p>
                  <p className="ttNumber text-3xl font-bold" id="totalTimeId"></p>
                </div>
              }
              { !success && <p className="startTime text-4xl font-bold" id="timerId"></p> }
            </div>
          </div>
          
          {question.map((q, index) => {
            return (
              <QuestionDetail question={q} key={index} no={index+1} parent="userQuestion" chooseAnswer={chooseAnswer} />
            );
          })}
          {/* {!success && 
            <button 
              onClick={() => handleSubmitAnswer()}
              className="green-btn hidden"
              id="submitAnswer"            
            >
              Submit
            </button>
          } */}
          {/* <Link to="/dashboard"><button className="red-btn">Back</button></Link> */}
          <br/>      
        </div>    
      }
      </div>
    </>
  );
};

export default UserQuestion;
