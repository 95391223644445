import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import env from "../env";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [disabled, setDisbaled] = useState(false);
  const [token, setToken] = useState("");
  const query = useQuery();
  const history = useHistory();  

  useEffect(() => {
    if (!query.get("token")) {
      history.replace("/login");
    } else {
      setToken(query.get("token"));
    }
  }, [query, history]);

  const performChangePassword = (e) => {
    e.preventDefault();
    if (password === confirmPassword && password !== "") {
      setErrors([]);
      setDisbaled(true);
      axios
        .post(env.apiLink+"users/change-password", {
          token,
          password,
        })
        .then(() => {
          setErrors([]);
          setSuccess(true);
          setDisbaled(false);
          setPassword("");
          setConfirmPassword("");
          setTimeout(() => {
            window.close();
          }, 4000);
        })
        .catch((error) => {
          setErrors(["You may already updated by using this link, So, you cannot use again."]);
          console.error(error);
          setDisbaled(false);
        });
    } else {
      setErrors(["Please enter a valid password"]);
    }
  };
  return (
    <>
      <div className="container mx-auto cus-container">
        <div className="md:w-1/2 mx-auto mt-10 max-w-prose">
          <h2 className="text-center text-blue-900 font-display font-semibold lg:text-left text-2xl xl:text-bold">Please choose a new password</h2>
          <div className="mt-12">
            {errors.map((err, index) => {
              return (
                <div
                  key={index}
                  className="alert alert-danger mt-2"
                >
                  {err}
                </div>
              );
            })}
            {success && (
              <div className="alert alert-success mt-2">
                Password changed succesfully
                {/* <br /> <NavLink to="/login">Login</NavLink> */}
              </div>
            )}
            <form onSubmit={performChangePassword}>
              <div className="mt-8">
                <div className="flex justify-between items-center">
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    Password
                  </div>
                </div>
                <input
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500" type="password" placeholder="Enter your password" />
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center">
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    Confirm Password
                  </div>
                </div>
                <input
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}      
                  className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500" type="password" placeholder="Enter your password" />
              </div>

              <div className="mt-10">
                <button className="bg-blue-500 text-gray-100 p-3 w-full rounded-full tracking-wide
                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-blue-600
                shadow-lg"
                disabled={disabled}
                onClick={performChangePassword}
                type="submit"
                >
                  Change
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
