import { Link } from "react-router-dom";

const CoursesList = (props) => {

    const getCareer = (cid) => {
        return props.careers.filter(c => c.id === cid)[0]
    }

    return (
        <div className="w-full flex  flex-col md:flex-row md:flex-wrap">
        {props.skenumbers.map((num) => {
            return (         
            <div className="w-full md:w-1/2 min-w-md  pr-4 pb-3 pt-3 mb-2 animate-pulse" key={num}>    
                <p className="rounded-sm mt-3 mb-3 h-6 w-64 bg-gray-100"></p>
                <div className="w-full bg-gray-100 h-64 flex flex-col">
                
                </div>
                <p className="rounded-sm mt-2 mb-1 h-5 w-full bg-gray-100"></p>
                <p className="rounded-sm mt-2 mb-1 h-5 w-10/12 bg-gray-100"></p>
                <p className="rounded-sm mt-2 mb-3 h-12 w-32 bg-gray-100"></p>
            </div>
            );
        })}        

        {props.topics.map((topic, index) => {
            return (              
                <div className="w-full md:w-1/2 min-w-md  pr-4 pb-3 pt-3 mb-2" key={index}>    
                <p className="rounded-sm mt-3 h-6 w-full text-lg font-bold font-prompt">{topic.courseCode} : {topic.topicTitle}</p>
                <span className="text-xs mb-2">Author : {(typeof topic.user != 'undefined')? topic.user.name : topic.topic.user.name}</span>
                {/* <p className="rounded-sm mt-1 mb-3 h-6 w-full text-sm font-bold font-prompt">{topic.courseCode}</p> */}
                <div className="w-full bg-gray-100 h-auto flex flex-col">
                    <img src={topic.courseGridPhoto} className="w-full" />
                </div>
                <p className="rounded-sm mt-2 mb-1 h-14 w-ful font-bold text-lg overflow-hidden font-prompt">
                    {topic.courseShortDesc}
                </p>                
                <div className="flex">
                    {(typeof topic.careertopics != 'undefined' && typeof props.careers != 'undefined' && props.careers.length > 0) && 
                    <>
                        {topic.careertopics.map(c => {
                        return (
                            <Link to={'/careers/'+c.careerId} className="p-1 text-sm mr-1 mb-2 bg-green-600 rounded-sm no-underline cursor-pointer text-white">{getCareer(c.careerId).careerName}</Link>
                        )
                        })}
                    </>
                    }
                    {(typeof topic.careerIds != 'undefined' && typeof props.careers != 'undefined') && 
                    <>
                        {topic.careerIds.split(',').map(cid => {
                        return (
                            <Link to={'/careers/'+cid} className="p-1 text-sm mr-1 mb-2 bg-green-600 rounded-sm no-underline cursor-pointer text-white">{getCareer(parseInt(cid)).careerName}</Link>
                        )
                        })}  
                    </>
                    }
                </div>
                <Link to={"/topic/"+topic.id}><button className="rounded-sm mt-2 mb-3 h-8 w-20 bg-gray-800 text-white">OPEN</button></Link>
                </div>
            );
        })}
        </div>
    )
}

export default CoursesList