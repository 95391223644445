import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link, useParams } from "react-router-dom";
import axios from "axios";
import AdminNavBar from "../AdminNavBar";
import env from "../../env";
import {getQuestions, deleteQuestion} from "../../endpoints/questions";
import {getTopics} from "../../endpoints/topics";
import {getSyllabus} from "../../endpoints/syllabus";
import QuestionInput from "./QuestionInput";

const AdminNewPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);	
	const [token, setToken] = useState("");
	const [currentUser, setCurrentUser] = useState({});
  const [questions, setQuestions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [selTopicId, setSelTopicId] = useState(null);
  const [selSyllabusId, setSelSyllabusId] = useState(null);
  const [questionTitle, setQuestionTitle] = useState('');
  const [order, setOrder] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pagers, setPagers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  /**** For Question input *****/
  const [activeSyllabus, setActiveSyllabus] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [level, setLevel] = useState(null);
  const [courseCode, setCourseCode] = useState(null);
  const questionInputRef = useRef();
  const [editing, setEditingReal] = useState(false)
  const [creating, setCreating] = useState(false);
  const loadQuestions = () => {
    clickSearch();
    setCreating(false);
  }

  const editQuestion = (index) => {
    const ques = questions[index];
    setEditingReal(true);
    setActiveSyllabus(ques.syllabusId);
    setTopicId(ques.topicId);
    setLevel(ques.level);
    setCourseCode(ques.topic.courseCode);
    questionInputRef.current.editQuestion(index, ques.id);
  }

  const createQuestion = () => {
    setEditingReal(true);
    setActiveSyllabus(0);
    setTopicId(null);
    setLevel(null);
    setCourseCode(null);
    setCreating(true);
    // questionInputRef.current.createQuestion();
  }

  const undoCreate = () => {
    setEditingReal(false);
    setActiveSyllabus(null);
    setCreating(false);
  }

  const setEditing = (val) => {
    setEditingReal(val);
    setActiveSyllabus(null);
  }
  /***********************************************/


  useEffect(() => {
		setCurrentUser(cookies["auth"].user);    
    setToken(cookies["auth"].token);		
    searchQuestion();    
    getCourses();    
  }, [cookies]);

  const handleDeleteQuestion = async (id) => {
    // if (props.answerCnt > 0) {
    //   alert('Sorry you cannot update/delete, because someone already answered!');
    //   return false;
    // }
    const confirm = window.confirm('Are you sure you want to delete this question?');
    if (confirm == true) {
      await deleteQuestion(id, token);
      loadQuestions();
    }    
  }

  const searchQuestion = () => {
    const arr = {
      limit : limit,
      offset : offset
    };
    if (selTopicId != null) {
      arr.topicId = selTopicId
    }
    if (selSyllabusId != null && selSyllabusId != '') {
      arr.syllabusId = selSyllabusId
    }
    if (questionTitle != null && questionTitle != '') {
      arr.questionTitle = questionTitle
    }    
    if (order != null && orderBy != null) {
      arr.order = order;
      arr.orderBy = orderBy
    }
    getQuestions(arr, cookies["auth"].token).then(res => {
      setQuestions(res.data.data);
      const pagers = [];
      const noOfPagers = Math.ceil(res.data.count / limit);
      for( let i = 1; i <= noOfPagers; i++ ) {
        pagers.push(i);        
      }
      setTotalPages(noOfPagers);
      setPagers(pagers);
    })
  }

  const setPage = (page) => {
    setPageNo(page);
    setOffset((page * limit) - limit);

    // const pagers = [];    
    // if (page > 3) {
    //   // for (let i = page-2; i < page; i++) {
    //   //   pagers.push(i);
    //   // }
    //   for (let i = page; page <= page + 2; i++) {
    //     if (i <= totalPages) {
    //       pagers.push(i)
    //     }
    //   }
    // }      
    
    // setPagers(pagers);
    
    clickSearch();
  }

  const resetSearchQuestion = () => {
    document.getElementById('course-sel').value = ''
    document.getElementById('syllabus-sel').value = ''
    document.getElementById('topic-code-title').value = ''
    setSelSyllabusId(null);
    setSyllabus([]);
    setSelTopicId(null);
    setQuestionTitle('');
    clickSearch();
  }

  const clickSearch = () => {
    setTimeout(() => {
      document.getElementById('search-btn').click();
    }, 500) 
  }
  

  const getCourses = () => {
    getTopics(cookies["auth"].token).then(res => {
      setTopics(res.data.data);
    })
  }
  
  const handleChangeCourse = (topicId) => {
    setSyllabus([]);
    setSelTopicId(null);
    if (topicId != '') {
      setSelTopicId(topicId);
      getSyllabus({topicId : topicId}, cookies["auth"].token).then(res => {
        setSyllabus(res.data.data);
      })
    }
  }

  const handleChangeCourse2 = (target) => {
    const tid = target.value
    if (tid != '') {
      getSyllabus({topicId : tid}, cookies["auth"].token).then(res => {
        setSyllabus(res.data.data);
      })
      setTopicId(tid);
      const opt = target.selectedOptions[0].text;
      const ccode = opt.split('-')[0].trim();
      setCourseCode(ccode);
      const level = ccode.charAt(ccode.length-4)
      setLevel(parseInt(level) - 1);
    }
  }

  const changeOrder = (orderClick) => {
    if (orderBy != orderClick) {
      setOrder('asc');
    } else {
      const newOrder = (order == 'asc')? 'desc' : 'asc';
      setOrder(newOrder);
    }
    setOrderBy(orderClick);
    clickSearch();
  }  

  return (
    <>
      <AdminNavBar page={'questions'} />
      <div className="m-10">
        <h2 className="font-semibold text-2xl mb-4">Questions</h2>
        <div className="flext flex-col  mb-6 pr-2 lg:m-10">
          {(creating === true) && 
            <>
              <span onClick={undoCreate} className="font-bold text-4xl hover:no-underline cursor-pointer">&#8592;</span>
              <div className="mt-2 ml-2 flex flex-col">
                <label className="w-20">Course </label> 
                <select id="course-sel2" className="bg-gray-200 rounded h-8 p-1 w-64 mb-4 mr-4" onChange={(event) => handleChangeCourse2(event.target)}>
                  <option value="">All Courses</option>
                  {topics.map((t, i) => {
                    return (
                      <option key={i+'c'} value={t.id}>{t.courseCode} - {t.topicTitle}</option>
                    )
                  })}
                </select>
                <label className="w-20">Syllabus </label> 
                <select id="syllabus-sel2" className="bg-gray-200 rounded h-8 p-1 w-64 mb-4 mr-4" onChange={(event) => setActiveSyllabus(event.target.value)}>
                  <option value=''>All Syllabuses</option>
                  {syllabus.map((s, i) => {
                    return (
                      <option key={i+'s'} value={s.id}>{s.syllabusCode} - {s.title}</option>
                    )
                  })}
                </select>
              </div>
            </>
          }
        </div>
        <QuestionInput 
          tab='question'
          loadQuestions={loadQuestions} 
          activeSyllabus={activeSyllabus}         
          topicId={topicId}
          token={cookies["auth"].token}
          level={level}
          courseCode={courseCode}
          ref={questionInputRef}
          setEditing={setEditing}
        />
        <div className={(editing == true)? 'hidden' : ''}>
          <fieldset class="border p-3 mb-4">
            <legend class="w-auto text-lg font-semibold">Search Questions</legend>
            <div className="w-full flex flex-col">
              <div className="flex">
                <label className="w-20">Course : </label> 
                <select id="course-sel" className="bg-gray-200 rounded h-8 p-1 w-64 mb-4 mr-4" onChange={(event) => handleChangeCourse(event.target.value)}>
                  <option value="">All Courses</option>
                  {topics.map((t, i) => {
                    return (
                      <option key={i+'c'} value={t.id}>{t.courseCode} - {t.topicTitle}</option>
                    )
                  })}
                </select>
                <label className="w-20">Syllabus : </label> 
                <select id="syllabus-sel" className="bg-gray-200 rounded h-8 p-1 w-64 mb-4 mr-4" onChange={(event) => setSelSyllabusId(event.target.value)}>
                  <option value=''>All Syllabuses</option>
                  {syllabus.map((s, i) => {
                    return (
                      <option key={i+'s'} value={s.id}>{s.syllabusCode} - {s.title}</option>
                    )
                  })}
                </select>
                <label className="w-20">Question : </label> 
                <input onChange={e => setQuestionTitle(e.target.value)} id="topic-code-title" type="text" className="bg-gray-200 rounded h-8 p-2 w-64 mb-4 mr-4" placeholder="Question Code Or Title" />
                <button id="search-btn" className="bg-blue-400 p-1 rounded text-md text-gray-100 h-8 w-20" onClick={() => searchQuestion()} >Search</button>
                <button className="bg-yellow-400 ml-1 p-1 rounded text-md text-gray-100 h-8 w-20" onClick={() => resetSearchQuestion()} >Reset</button>
              </div>            
            </div>
          </fieldset>
          <div className="flex w-full flex-row-reverse"><button className="p-2 bg-green-400 mb-2 self-end" onClick={createQuestion}>Create New</button></div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th>
                  <div className="flex items-center">
                    Course
                    <div className="flex flex-col ml-2 cursor-pointer" onClick={() => changeOrder('course')}>
                      <span className={'text-xs '+((order == 'asc' && orderBy == 'course')? '' : 'text-gray-300')} style={{marginBottom: '-4px'}}>&#9650;</span>
                      <span className={'text-xs '+((order == 'desc' && orderBy == 'course')? '' : 'text-gray-300')}>&#9660;</span>
                    </div>  
                  </div>
                  {/* <div className="flex flex-col ml-2 cursor-pointer">
                    <span className="text-xs" style={{marginBottom: '-4px'}}>&#9650;</span>
                    <span className="text-xs text-gray-300">&#9660;</span>
                  </div>  
                  </div>
                  <div className="flex flex-col ml-2 cursor-pointer">
                    <span className="text-xs text-gray-300" style={{marginBottom: '-4px'}}>&#9650;</span>
                    <span className="text-xs">&#9660;</span>
                  </div>  
                  </div> */}
                </th>
                <th>
                  <div className="flex items-center">
                    Syllabus
                    <div className="flex flex-col ml-2 cursor-pointer" onClick={() => changeOrder('syllabus')}>
                      <span className={'text-xs '+((order == 'asc' && orderBy == 'syllabus')? '' : 'text-gray-300')} style={{marginBottom: '-4px'}}>&#9650;</span>
                      <span className={'text-xs '+((order == 'desc' && orderBy == 'syllabus')? '' : 'text-gray-300')}>&#9660;</span>
                    </div>  
                  </div>
                </th>
                <th>
                  <div className="flex items-center">
                    Question Code
                    <div className="flex flex-col ml-2 cursor-pointer" onClick={() => changeOrder('code')}>
                      <span className={'text-xs '+((order == 'asc' && orderBy == 'code')? '' : 'text-gray-300')} style={{marginBottom: '-4px'}}>&#9650;</span>
                      <span className={'text-xs '+((order == 'desc' && orderBy == 'code')? '' : 'text-gray-300')}>&#9660;</span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flex items-center">
                    Question Title
                    <div className="flex flex-col ml-2 cursor-pointer" onClick={() => changeOrder('questionTitle')}>
                      <span className={'text-xs '+((order == 'asc' && orderBy == 'questionTitle')? '' : 'text-gray-300')} style={{marginBottom: '-4px'}}>&#9650;</span>
                      <span className={'text-xs '+((order == 'desc' && orderBy == 'questionTitle')? '' : 'text-gray-300')}>&#9660;</span>
                    </div>
                  </div>
                </th>
                <th>Option</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((qus, index) => {
                return (
                  <tr key={index+'q'}>
                    <td className="border-2 p-2">
                      {(qus.topic != null)? qus.topic.courseCode + ' - ' + qus.topic.topicTitle : ''}
                    </td>
                    <td className="border-2 p-2">
                      {(qus.syllabus != null)? qus.syllabus.syllabusCode + ' - ' + qus.syllabus.title : ''}                      
                    </td>
                    <td className="border-2 p-2">{qus.questionCode}</td>
                    <td className="border-2 p-2">{qus.questionTitle}</td>
                    <td className="border-2 p-2 flex justify-between">
                        <button className="items-start" onClick={() => editQuestion(index)}>Edit</button>
                        <button className="items-start" onClick={() => handleDeleteQuestion(qus.id)}>Delete</button>
                        {/* <Link className="items-end" to={"/admin/answer/"+ans.id}>See Answer</Link> */}
                    </td>
                  </tr>
                );
              })}            
            </tbody>
          </table>
          <div className="flex mt-4">
            {(pageNo > 3) && 
              <button onClick={() => setPage(1)} className="p-1 pl-2 pr-2 text-sm mr-2 bg-gray-200">First</button>  
            }
            {pagers.map(i => {
              if (pageNo - i <= 2 && i - pageNo < 3) {
                return (
                  <button key={'page'+i} onClick={() => setPage(i)} className={"p-1 pl-2 pr-2 text-sm mr-2 "+(((offset/limit) + 1 == i)? 'bg-yellow-300' : 'bg-gray-200')}>{i}</button>  
                );
              }
            })}
            {(totalPages - pageNo > 2) && 
              <button onClick={() => setPage(totalPages)} className="p-1 pl-2 pr-2 text-sm mr-2 bg-gray-200">Last</button>  
            }
            {/* <button onClick={() => setPage(1)} className="p-1 pl-2 pr-2 text-sm bg-gray-200 mr-2">1</button>
            <button onClick={() => setPage(2)} className="p-1 pl-2 pr-2 text-sm bg-gray-200 mr-2">2</button>
            <button onClick={() => setPage(3)} className="p-1 pl-2 pr-2 text-sm bg-yellow-300">3</button>
            <button onClick={() => setPage(4)} className="p-1 pl-2 pr-2 text-sm bg-gray-200 mr-2">4</button>
            <button onClick={() => setPage(5)} className="p-1 pl-2 pr-2 text-sm bg-gray-200 mr-2">5</button> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminNewPage;
