import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import NavBar from "./NavBar";
import env from "../env";
import { getUserStudiesTopics } from "../endpoints/userstudies";
import { showMmSsFromSec } from "../common/timeFunc";
import { updateUser, getUser } from "../endpoints/users";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEdit, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap'
import { getOrders, fbPhotoUpload } from '../endpoints/topics'
import './styles/EditAccount.css'
const EditAccount = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSucess] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [answered, setAnswered] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const history = useHistory();
  const [sentResetpw, setSentResetpw] = useState(false);
  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    const user = cookies["auth"].user;

    getUser(user.id, cookies["auth"].token).then(res => {
      const userData = res.data.data
      setName(userData.name);
      setEmail(userData.email);
      setPoints(userData.point);
      setLevel(userData.level);
      if (userData.profileUrl != null) {
        setProfileUrl(userData.profileUrl)
      }
      // document.getElementById('level'+userData.level+'Radio').checked = true
    });

    // if (user.questionChallenge == true) {
    //   document.getElementById('challenge-toggle-check').checked = true
    // } else {
    //   document.getElementById('challenge-toggle-check').checked = false
    // }

    getUserStudiesTopics({ userId : user.id }, cookies["auth"].token).then(async res => {
      let datas = []
      if (res.data.data.length > 0) {
        datas = res.data.data        
      }
      await getOrders(cookies["auth"].token, { userId : user.id}).then(res => {
        console.log(res.data)
        res.data.data.map(d => {
          if (d.orderstatus == 'pending') {
            datas.unshift(d)
          }
          if (d.orderstatus == 'approve') {
            const already = datas.filter(ds => ds.topicId == d.topicId)
            console.log(already)            
            if (already.length < 1) {
              datas.unshift(d)
            }
          }
        })                
      })
      if (datas.length > 0) {
        setUserAnswers(datas);
        setAnswered(true);        
      }else{
        setAnswered(false);
      }
    }).catch(err => {
      setAnswered(false);
    });
    
  }, [cookies]);

  const update = (profile = null) => {
    // const questionChallenge = document.getElementById('challenge-toggle-check').checked
    const questionChallenge = 1;
    setErrors([]);
    setSucess(false);
    const newErrors = [];
    if (!name) {
      newErrors.push("name is required");
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    setDisabled(true);
    setSucess(false);
    const id = cookies["auth"].user.id;
    // const level = document.querySelector('input[name="level"]:checked').value
    const data = { name, id, questionChallenge }
    if (profile != null) {
      data.profileUrl = profile;
    }
    updateUser(data, cookies["auth"].token)
      .then((res) => {
        if (res.data.success === true) {
          setSucess(true);
          setCookie("auth", { user : res.data.data, token : res.data.token });
          setTimeout(() => {
            setSucess(false);
          }, 5000);
          setDisabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrors(["Something went wrong! Please try again later"]);
        setTimeout(() => {
          setErrors([]);
        }, 5000);
        setDisabled(false);
      });
  };

  const resetPassword = async () => {
    setDisabled(true);
    const newErrors = [];
    if (!email) {
      newErrors.push("email is required");
      setErrors(newErrors);
      setDisabled(false);
      return;
    }
    try {
      const user = await axios.post(`${env.apiLink}users/email-exist`, { email : email });
      if ( user.data.success == true ) {
        axios
          .post(
            `${env.apiLink}users/reset-password`, { id : user.data.data.id }
          )
          .then(() => {
            setDisabled(false);
            setSentResetpw(true);
            setTimeout(() => {
              setSentResetpw(false);
            }, 5000);
          })
          .catch((error) => {
            console.error(error);
            setDisabled(false);
          });
      }
    } catch(err) {
      newErrors.push("email is not exist!");
      setErrors(newErrors);
      setDisabled(false);
    }
  };

  const choosePhoto = (e) => {
    const file = e.target.files[0]
    if (file.type.includes('image')) {
      fbPhotoUpload('photos/user/'+cookies["auth"].user.id+'/'+Date.now(), file).then(photoUrl => {
        setProfileUrl(photoUrl);
        update(photoUrl)
      }).catch(err => {
        alert('Please upload only photo')
      })
    } else {
      alert('Please upload only photo')
    }
  }

  return (
    <>
      <NavBar />
      <div className="navOverlay">
        <div className={""+((window.location.pathname.includes('my-courses'))? ' hidden' : 'editMain')}>
          <div className="editPadding">
            <div className="editHeader">
              <div className="borderProfile mt-7 cursor-pointer" onClick={() => document.getElementById('profilePhoto').click()}>
                
                {profileUrl == null && <FontAwesomeIcon className="profilePic" icon={faUser} />}
                {profileUrl != null && <div className="w-full h-full"><img src={profileUrl} className="w-full h-auto" /></div> }

              </div>
              <input type="file" id="profilePhoto" onChange={choosePhoto} className="hidden" />
              <div className={""+((window.location.pathname.includes('my-courses'))? ' hidden' : '')} >
                <div>
                  {errors.map((error, index) => {
                    return (
                      <div
                        key={index}
                        className="alert alert-danger mt-2"
                      >
                        {error}
                      </div>
                    );
                  })}
                  {success && (
                    <div className="alert alert-success mt-2">
                      Account updated successfuly!
                    </div>
                  )}
                  {sentResetpw && (
                    <div className="alert alert-success mt-2">
                      Reset password email sent!
                    </div>
                  )}
                  <div action="#" className="editTopBottomgap">
                    <div className="editEmailAndName">
                      <div className="editEmailHader emailNamePoint">
                        <div className="inforHeader">Email Address</div>
                        {/* <input
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          className="" type="email" disabled /> */}
                          <p className="editName">{email}</p>
                      </div>
                      <div className="emailNamePoint">
                        <div className="inforHeader">Name</div>
                        <input
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          className="editNameText" type="text" placeholder="Enter your Name" 
                        />
                        <FontAwesomeIcon className="awesomeEdit" icon={faEdit} />
                      </div>
                    </div>
                    
                    
                    {/* <div className="editChoose">
                      <div className="inforHeader">Choose your Level</div>
                      <div className="radioTextHeader">
                        <div className="radioText">
                          <input type="radio"  className="chooseBtn" value="0" name="level" id="level0Radio" /> 
                          <p>Begineer</p>
                        </div>
                        <div className="radioText">
                          <input type="radio"  className="chooseBtn" value="1" name="level" id="level1Radio" /> 
                          <p>Intermediate</p>
                        </div>
                        <div className="radioText">
                          <input type="radio"  className="chooseBtn" value="2" name="level" id="level2Radio" /> 
                          <p>Expert</p>
                        </div>  
                      </div>    
                    </div> */}
                          
                    <div className="editBtn">
                      <div>
                        <button className="editUpAndRe"
                        id="btn-user-update"
                        onClick={() => update()}
                        disabled={disabled}
                        type="submit"
                        >
                          Update
                        </button>
                      </div>
                      <div>
                        <button className="editUpAndRe"
                        onClick={resetPassword}
                        disabled={disabled}
                        type="submit"
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="second">
          <div className="pt-5">
            <h2 className="studayingHeader">Studying Courses</h2>

            <div className="separators">
              <div className="lines line-lefts"></div>
              <div className="asterisks"><FontAwesomeIcon icon={faAsterisk} /></div>
              <div className="lines line-rights"></div>
            </div>

            {(answered === false) && (
                <div className="w-full lg:p-5" style={{paddingTop: '32px'}}>
                  <div className="alert alert-success w-full text-center text-lg">
                    You don't have any course yet! Why not study some courses and get points? 
                    <br />Please browse <a className="underline" href="/dashboard">here</a>
                  </div>
                </div> 
            )}

            {(answered === true) && (
                <div className="editMainTable">
                  <table className="editSecondTable">
                    <thead className="bg-gray-400">
                      <tr>
                        <th scope="col" className="courseAndCompletement">
                          Course Title
                        </th>
                        <th scope="col" className="courseAndCompletement">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-200 courseAndTotal">
                    {userAnswers.map((ans, index) => {
                      let percent = 100 * (ans.totalStudiedSyllabus/ans.totalSyllabus).toFixed(2);
                      let bgcolor = 'bg-green-100';
                      if (percent < 75) {
                        bgcolor = 'bg-yellow-200';
                      }
                      if (percent < 40) {
                        bgcolor = 'bg-red-200';
                      }
                      return (
                      <tr className="" key={ans.id}>
                        <td className="editTable">
                          <div className="courseTitle"><Link to={"/topic/"+ans.topicId}>
                            {/* {ans.courseCode} :  */}
                            {ans.topicTitle}</Link></div>
                          <div className="courseSyllabus">{ans.totalSyllabus} Syllabus</div>
                        </td>
                        <td className="editTable">
                          <span className="editPercentProgress">
                            {!ans.orderstatus &&
                            <>
                              <div className="editPer">{ans.totalStudiedSyllabus}/{ans.totalSyllabus}</div>
                              <Progress value={percent}><p className="progressBarPer">{percent}%</p></Progress>
                            </>}
                            {ans.orderstatus && 
                            <>
                              <span className={((ans.orderstatus == 'pending')? 'bg-yellow-500' : 'bg-green-500' )+" w-16 text-white text-sm rounded-lg"}>{ans.orderstatus}</span>
                            </>}
                          </span>
                        </td>
                      </tr>      
                      );
                    })}                
                    </tbody>
                  </table>
                </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default EditAccount;
