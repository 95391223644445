import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
import joslogo2 from '../assets/image/joslogo.png'
import NavBar from "./NavBar"

import './styles/certificate.css'

const Certificate = () => {
    return (
        <>
            <NavBar />
            <div className="navOverlay">
                <div className="certificate">
                    <h2>Certificate</h2>
                    <div className="cSeparator">
                        <div className="cLine cLine-left"></div>
                        <div className="cAsterisk"><FontAwesomeIcon icon={faAsterisk} /></div>
                        <div className="cLine cLine-right"></div>
                    </div>

                    <div className="certificateBody">
                        <h3>Hayden Christensen</h3>
                        <div className="bodyLine"></div>
                        <p className="certificateText">Lorem ipsum dolor, sit amet consectetur adipisicing elit.aut labore cumque voluptate quibusdam magni natus cum, consectetur, nulla dolorem alias tempore recusandae consequuntur. Quisquam?</p>
                        <div className="certificateImg">
                            <img className="certificateLogo" src={joslogo2} alt="" />
                        </div>
                        <div className="certificateFooter">
                            <div className="certificateLeft">
                                <p className="footerText">10/05/2021</p>
                                <div className="footerLine"></div>
                                <p className="footerBottom">Date</p>
                            </div>
                            <div className="certificateRight">
                                <p className="footerText">Teacher Soe</p>
                                <div className="footerLine"></div>
                                <p className="footerBottom">Sign</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-btn">
                    <button className="certificate-button">Print</button>
                </div>
            </div>
        </>
    )
}

export default Certificate;