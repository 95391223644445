import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const saveSyllabus = (data, token) => {
  return axios
      .post(env.apiLink+"syllabus", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteSyllabus = (id, token) => {
  return axios
      .delete(env.apiLink+"syllabus/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getSyllabus = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"syllabus?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

export { saveSyllabus, getSyllabus, deleteSyllabus }