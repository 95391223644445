import React, { useState, useEffect } from "react";
import { NavLink, useHistory, Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminNavBar from "../AdminNavBar";
import { getAnswerDetail } from "../../endpoints/answers";
import { getQuestions } from "../../endpoints/questions";
import { showMmSsFromSec } from "../../common/timeFunc";

const AnswerDetail = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [user, setUser] = useState({ name: "", email: "" });
  const history = useHistory();
  const { answerSheetId } = useParams();
  const [answers, setAnswers] = useState([]);
  const [answerSheet, setAnswerSheet] = useState({});
  const [question, setQuestion] = useState([]);

  useEffect(() => {
    getAnswerDetail(answerSheetId, cookies["auth"].token).then(async res => {
      if (res.data.data == null) {
        history.replace('/admin/answers');
      }
      setAnswerSheet(res.data.data);
      setAnswers(res.data.data.answers);
      const questions = await getQuestions({ topicId : res.data.data.topicId }, cookies["auth"].token);
      setQuestion(questions.data.data);
    }).catch(err => {
      history.replace('/admin/answers');
    });
  }, [cookies]);

  return (
    <>
      <AdminNavBar page="answers" />
      <div className="m-10">
        <h1 className="text-gray-600 m-2 font-mono text-lg">Answer sheet from {(typeof answerSheet.user != 'undefined')? answerSheet.user.name : ''}</h1>
        <br />
        Topic Name : {(typeof answerSheet.topic != 'undefined')? answerSheet.topic.topicTitle : ''}
        <br/><br/>
        Candidate Name : {(typeof answerSheet.user != 'undefined')? answerSheet.user.name : ''}
        <br/><br/>
        Correct Answer : {(typeof answerSheet.correctAnswer != 'undefined')? answerSheet.correctAnswer : ''}
        <br/><br/>
        {answers.map((ans, index) => {
          return (
            <div key={index}>
              {(question.length > 0)?
                  <div className={(question[index].bestAnswer == ans.userAnswered)? 'bg-green-200 mb-6 p-2 border-2' : 'mb-6 p-2 border-2'}>
                    Question 1 :  {question[index].questionTitle}
                    <br />
                    <p>Ans1 : {question[index].answer1}</p>
                    <p>Ans2 : {question[index].answer2}</p>
                    <p>Ans3 : {question[index].answer3}</p>
                    <p>Ans4 : {question[index].answer4}</p>
                    <p>Best Answer : {question[index].bestAnswer}</p>
                    <p>User Answer : {ans.userAnswered}</p>
                    <p>Time Spent : {showMmSsFromSec(ans.timespent)}</p>
                  </div>
              : ''}
            </div>
          );
        })}        
              
        <br/>      
        <Link to="/admin/answers"><button className="bg-red-600 p-2 rounded text-lg text-gray-100">Back</button></Link>            
      </div>    
    </>
  );
};

export default AnswerDetail;
