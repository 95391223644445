import React, { useEffect, useState } from "react";
import "../App.css";
import { Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";

const AdminNavBar = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();
  const path = history.location.pathname;    
  
  //detecting if user is logged in by checking the cookies
  useEffect(() => {
    if (cookies["auth"]) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [cookies]);
  return (

    <nav className="bg-gray-600 text-white">
      <div className="mx-auto px-6">
        <div className="flex justify-between">
          <div className="items-start">
            <Link to="/admin" className={((props.page == 'home')? "active-nav" : "")+" cus-nav-link"}>Home</Link>
            <Link to="/admin/orders" className={((props.page == 'orders')? "active-nav" : "")+" cus-nav-link"}>Orders</Link>
            <Link to="/admin/users" className={((props.page == 'users')? "active-nav" : "")+" cus-nav-link"}>User</Link>
            <Link to="/admin/topics" className={((props.page == 'topic' || props.page == 'question')? "active-nav" : "")+" cus-nav-link"}>Courses</Link>
            <Link to="/admin/questions" className={((props.page == 'questions')? "active-nav" : "")+" cus-nav-link"}>Questions</Link>        
            <Link to="/admin/answers" className={((props.page == 'answers')? "active-nav" : "")+" cus-nav-link"}>Answers</Link>        
            <Link to="/admin/codes" className={((props.page == 'codes')? "active-nav" : "")+" cus-nav-link"}>Codes</Link>
            <Link to="/admin/career" className={((props.page == 'careers')? "active-nav" : "")+" cus-nav-link"}>Course Series</Link>
          </div>
          <div className="items-end">          
            <Link to="/admin/logout" className="cus-nav-link text-right">logout</Link>
          </div>
        </div>                  
      </div>
    </nav>
  );
};

export default AdminNavBar;
