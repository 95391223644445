import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions'
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB4nN60WZ5CSQXv8xa0xAOeeZwWLyd0UB4",
  authDomain: "quiz-ans-bf958.firebaseapp.com",
  projectId: "quiz-ans-bf958",
  storageBucket: "quiz-ans-bf958.appspot.com",
  messagingSenderId: "301848527562",
  appId: "1:301848527562:web:76ab78a728ad2e6c0e6a33"
};
const app = initializeApp(firebaseConfig);

const saveTopic = (data, token) => {
  return axios
      .post(env.apiLink+"topics", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getTopics = (token, filter = {}) => {
  let queryStr = "";
  if (typeof filter.publish != 'undefined') {
    queryStr = queryStr + "?publish=" + filter.publish;
  }
  if (typeof filter.topicTitle != 'undefined') {
    queryStr = queryStr + "&topicTitle=" + filter.topicTitle;
  }
  if (typeof filter.codeId != 'undefined' && filter.codeId != null) {
    queryStr = queryStr + "&codeId=" + filter.codeId;
  }
  if (typeof filter.free != 'undefined' && filter.free != null) {
    queryStr = queryStr + "&free=" + filter.free;
  }
  return axios
      .get(env.apiLink+"topics"+queryStr, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getTopicDetail = (topicId, token) => {
  return axios
      .get(env.apiLink+"topics/"+topicId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const fbPhotoUpload = (imgref, photoBlob) => {
  const storage = getStorage();
  const storageRef = ref(storage, imgref);
  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef, photoBlob);

    uploadTask.on('state_changed', (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
      }
    }, 
    (error) => {
      console.log(error)
      reject(false)
    }, 
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
        resolve(downloadURL)
      });
    });    
  }).then(photoUrl => {
    return photoUrl
  }).catch(err => {
    console.log(err)
  })
}

const topicPhotoUpload = async (topicId, token, photo) => {
  const imgref = 'photos/topic/' + topicId + '/courseGridPhoto-' + Date.now()
  fbPhotoUpload(imgref, photo).then(photoUrl => {
    axios.post(env.apiLink+"topics/photo/"+topicId, { photoUrl : photoUrl }, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
  })  
}

const textEditorPhotoUpload = async (editorComponent, photo) => {
  const photoOrMedia = (photo.type.includes('image'))? 'photos' : 'media'
  const imgref = photoOrMedia + '/' + editorComponent + '/' + Date.now()
  return fbPhotoUpload(imgref, photo).then(photoUrl => {
    return {
      data : {
        photoUrl : photoUrl
      }
    }
  })
  // const data = new FormData();
  // data.append('photo', photo);  
  // return axios
  //     .post(env.apiLink+"topics/upload/", data, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-type': 'multipart/form-data'
  //       },
  //     });
}

const deleteTopic = (topicId, token) => {
  return axios
      .delete(env.apiLink+"topics/"+topicId, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'multipart/form-data'
        },
      });
}

const orderTopic = (data, token) => {
  return axios
      .post(env.apiLink+"topics/order", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const approveOrderTopic = (data, token) => {
  return axios
      .post(env.apiLink+"topics/order-approve", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const getOrders = (token, filter = {}) => {
  return axios
      .get(env.apiLink+"topics/orders?"+objectToQueryString(filter), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

export { saveTopic, getTopics, getTopicDetail, topicPhotoUpload, textEditorPhotoUpload, fbPhotoUpload, deleteTopic, orderTopic, getOrders, approveOrderTopic }