const setWithExpiry = function(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = function(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

const covertUrlParamToArr = function(url) {
	url = url.split('?')[1]
	var arr1 = url.split('&')
	let obj = {}
	for(var str of arr1){
		var arr2 = str.split('=')
		obj[arr2[0]] = arr2[1]                
	}
	return obj
}

const blobToDataURL = (blob, callback) => {
    var a = new FileReader();
    a.onload = function(e) {
        callback(e.target.result);
    }
    a.readAsDataURL(blob);
}

const dataURItoBlob = function(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


}

const convertLocalDate = function(utcDate) {
    let utcDateStr = new Date(utcDate).toString();
    let arr = utcDateStr.split(' ');
    let strCon = arr[0]+', '+arr[2]+' '+arr[1]+' '+arr[3]+' '+arr[4]+' GMT';
    return new Date(strCon).toString();
}

export { setWithExpiry, getWithExpiry, covertUrlParamToArr, dataURItoBlob, convertLocalDate, blobToDataURL }