import React, { useState, useEffect } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import AdminNavBar from "./../AdminNavBar";
import { getAnswers } from "../../endpoints/answers";
import { showMmSsFromSec, showYmDhm } from "../../common/timeFunc";

const Answers = () => {
  const [cookies] = useCookies(["cookies"]);
  const [user, setUser] = useState({ name: "", email: "" });
  const history = useHistory();
  const [token, setToken] = useState("");
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setToken(cookies["auth"].token);
    getAnswers(cookies["auth"].token).then(res => {
      setAnswers(res.data.data);
    });
  }, [cookies]);

  return (
    <>
      <AdminNavBar page="answers" />
      <div className="m-10">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Date</th>
              <th>Candidate Name</th>
              <th>Topic</th>
              <th>Time Spent</th>
              <th>Questions</th>
              <th>Correct Answer</th>
            </tr>
          </thead>
          <tbody>
            {answers.map((ans, index) => {
              return (
                <tr key={index}>
                  <td className="border-2 p-2">{showYmDhm(ans.createdAt)}</td>
                  <td className="border-2 p-2"><Link to={"/admin/user/"+ans.user.id}>{ans.user.name}</Link></td>
                  <td className="border-2 p-2">{ans.topic.topicTitle}</td>
                  <td className="border-2 p-2">{showMmSsFromSec(ans.totalTimeSpent)}</td>
                  <td className="border-2 p-2">{ans.totalQuestion}</td>
                  <td className="border-2 p-2 flex justify-between">
                      <Link className="items-start" to={"/admin/answer/"+ans.id}>{ans.correctAnswer}</Link>
                      <Link className="items-end" to={"/admin/answer/"+ans.id}>See Answer</Link>
                  </td>
                </tr>
              );
            })}            
          </tbody>
        </table>
    </div>  
    </>
  );
};

export default Answers;
