import axios from "axios";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, Link } from "react-router-dom";
import NavBar from "./NavBar";
import env from "../env";
import testsvg from "./../test.svg";
import "./styles/Login.css"

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [success, setSucess] = useState(false);
  const [sentResetpw, setSentResetpw] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["cookies"]);
  const history = useHistory();
  const path = history.location.pathname;
  const [redirectLink, setRedirectLink] = useState(null);

  useEffect(() => {
    if (cookies["auth"]) {
      if(cookies["auth"].user.role === 'admin'){
        history.replace("/admin");
      }else{
        history.replace("/dashboard");
      }
    }    
    if (typeof props.location.state != 'undefined') {
      setRedirectLink(props.location.state.from)
    }
  }, [cookies]);

  const performLogin = (e) => {
    e.preventDefault();
    setErrors([]);
    setSucess(false);
    const newErrors = [];
    if (!email) {
      newErrors.push("email is required");
    }
    if (!password) {
      newErrors.push("password is required");
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    setDisabled(true);
    setSucess(false);
    axios
      .post(`${env.apiLink}users/login`, { email, password })
      .then((res) => {
        const role = res.data.user.role;
        if(path == '/admin/login' || path == '/admin/login/'){
          if(role == 'admin'){
            setSucess(true);
            setDisabled(false);
            setCookie("auth", res.data,  { path: '/admin' });
            history.replace("/admin");                        
          }else{
            setDisabled(false);
            setErrors(["Unauthorize User!"]);
          }          
        }else{
          if(role == 'user'){
            setSucess(true);
            setDisabled(false);
            setCookie("auth", res.data);
            if (redirectLink != null) {
              history.replace(redirectLink.pathname);
            } else {
              history.replace("/dashboard");
            }            
          }else{
            setDisabled(false);
            setErrors(["Unauthorize User!"]);
          }
        }
      })
      .catch((err) => {
        setDisabled(false);
        if (err.response.status == 422) {
          if (err.response.data.err.includes("wrong email or password")) {
            setErrors(["Email or Password is wrong!"]);    
          } else {
            setErrors(["Oops! Something went wrong, please try again later."]);
          }
        } 
        else if (err.response.status == 403) {
          setErrors([err.response.data.err]);
        } else {
          setErrors(["Oops! Something went wrong, please try again later."]);
        }
      });
  };

  const forgetPassword = async () => {
    setDisabled(true);
    const newErrors = [];
    if (!email) {
      newErrors.push("email is required");
      setErrors(newErrors);
      setDisabled(false);
      return;
    }
    try {
      const user = await axios.post(`${env.apiLink}users/email-exist`, { email : email });
      if ( user.data.success == true ) {
        axios
          .post(
            `${env.apiLink}users/reset-password`, { id : user.data.data.id }
          )
          .then(() => {
            setDisabled(false);
            setSentResetpw(true);
          })
          .catch((error) => {
            console.error(error);
            setDisabled(false);
          });
      }
    } catch(err) {
      newErrors.push("email is not exist!");
      setErrors(newErrors);
      setDisabled(false);
    }
  };

  return (
    <>
      <NavBar />
      <div className="navOverlay loginMain">
        {/* <div className="lg:w-1/2 hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
          <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
            <img className="w-5/6 mx-auto mb-20" src={testsvg} />
          </div>
        </div> */}
        <div className="loginContainer">
          <div className="loginCard">
            <div>
            <h2>Log in</h2>
            {errors.map((error, index) => {
              return (
                <div
                  className="alert alert-danger mt-2"
                  key={index}
                >
                  {error}
                </div>
              );
            })}
            {success && (
              <div className="alert alert-success mt-2">
                successfully loggedin
              </div>
            )}  
            {sentResetpw && (
              <div className="alert alert-success mt-2">
                We have sent an email link to reset your password
              </div>
            )}    
            <form onSubmit={performLogin}>
              <div>
                <label className="loginEmail">Email Address or Phone Number</label>
                <input 
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="regLoginInput" type="email"
                />
              </div>

              <div>
                <div className="loginPassFor">
                  <label className="loginPass">Password</label>
                  <div>
                    <a onClick={forgetPassword} className="loginForPass">Forgot Password?</a>
                  </div>
                </div>
                <input 
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}    
                  className="regLoginInput" type="password"
                />
              </div>

                <button className="loginBtn" disabled={disabled} onClick={performLogin}>Log In</button>
              </form>
              <div className="loginAccSign">
                <p className="loginAcc">Don't have an account ?</p>  <Link to={{ pathname: "/register", state: { from: props.location } }} className="loginSign">Sign up</Link>
              </div>
            </div>
          </div>    
        </div>
      </div>
    </>
  );
};
export default Login;
