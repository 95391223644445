import "./App.css";
import NavBar from "./components/NavBar";
import Register from "./components/Register";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/home";
import About from "./components/about";
import Login from "./components/Login";
import Dashboard from "./components/dashboard";
import Logout from "./components/logout";
import Users from "./components/users";
import Mark from "./components/Mark";
import Certificate from "./components/Certificate";
import ResetPassword from "./components/resetPassword";
import AdminDashboard from "./components/adminDashboard";
import BritishTeach from "./components/BritishTeach";
import Examination from "./components/Examination";
import Topics from "./components/admin/topics";
import Answers from "./components/admin/answers";
import Answer from "./components/admin/answer";
import Question from "./components/admin/question";
import UserDetail from "./components/admin/UserDetail";
import Payment from "./components/Payment";
import UserQuestion from "./components/userQuestion";
import EditAccount from "./components/EditAccount";
import Teaching from "./components/Teaching";
import TopicDetail from "./components/TopicDetail";
import Codes from "./components/admin/codes";
import Questions from "./components/admin/Questions";
import Order from "./components/admin/Order";
import { useCookies } from "react-cookie";
import Career from "./components/admin/career";
import Careers from "./components/careers";
import CareerCourse from "./components/admin/CareerCourse";
import CareerDetail from "./components/CareerDetail";

function AdminRoute({ children, ...rest }) {
  const [cookies] = useCookies(["cookies"]);
  let isAdmin = false;
  if (cookies["auth"] && cookies["auth"].user.role == "admin") {
    isAdmin = true; 
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (isAdmin == true) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/admin/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function AuthUserRoute({ children, ...rest }) {
  const [cookies] = useCookies(["cookies"]);
  let isAuthUser = false;
  if (cookies["auth"] && cookies["auth"].user.role == "user") {
    isAuthUser = true; 
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (isAuthUser == true) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function App() {  

  return (
    <div>
      <Switch>
        
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/payment/:topicId" component={Payment} />
        <Route exact path="/britishTeach" component={BritishTeach} />
        <Route exact path="/examination" component={Examination} />        
        <Route exact path="/dashboard" component={Dashboard} />        
        <Route exact path="/dashboard/:codeId" component={Dashboard} />        
        <Route exact path="/certificate" component={Certificate} />        
        <Route exact path="/topic/:topicId" component={TopicDetail} />        
        
        <AuthUserRoute exact path="/logout">
          <Logout />
        </AuthUserRoute>
        <AuthUserRoute exact path="/question/:topicId/:syllabusId">
          <UserQuestion />
        </AuthUserRoute>
        <AuthUserRoute exact path="/learn/:topicId/:syllabusId">
          <Teaching />
        </AuthUserRoute>
        <AuthUserRoute exact path="/account">
          <EditAccount />
        </AuthUserRoute>
        <AuthUserRoute exact path="/my-courses">
          <EditAccount />
        </AuthUserRoute>        
        <AuthUserRoute exact path="/mark">
          <Mark />        
        </AuthUserRoute>
        <AuthUserRoute exact path="/careers">
          <Careers />
        </AuthUserRoute>
        <AuthUserRoute exact path="/careers/:id">
          <CareerDetail />
        </AuthUserRoute>

        <Route exact path="/reset-password" component={ResetPassword} />

        <Route exact path="/admin/login" component={Login} />
        
        <AdminRoute exact path="/admin">
          <AdminDashboard />
        </AdminRoute>
        <AdminRoute exact path="/admin/users" >
          <Users />
        </AdminRoute>
        <AdminRoute exact path="/admin/user/:userId" >
          <UserDetail />
        </AdminRoute>
        <AdminRoute exact path="/admin/topics" >
          <Topics />
        </AdminRoute>
        <AdminRoute exact path="/admin/answers" >
          <Answers />
        </AdminRoute>
        <AdminRoute exact path="/admin/answer/:answerSheetId" >
          <Answer />
        </AdminRoute>
        <AdminRoute exact path="/admin/question" >
          <Question />
        </AdminRoute>
        <AdminRoute exact path="/admin/questions" >
          <Questions />
        </AdminRoute>
        <AdminRoute exact path="/admin/question/:questionId" >
          <Question />  
        </AdminRoute>
        <AdminRoute exact path="/admin/logout" >
          <Logout />       
        </AdminRoute>
        <AdminRoute exact path="/admin/codes" >
          <Codes />
        </AdminRoute>
        <AdminRoute exact path="/admin/orders" >
          <Order />
        </AdminRoute>
        <AdminRoute exact path="/admin/career">
          <Career /> 
        </AdminRoute>
        <AdminRoute exact path="/admin/career/:id">
          <CareerCourse /> 
        </AdminRoute>
        
        <Redirect to="/login" from="/" />
      </Switch>
    </div>
  );
}

export default App;
