import axios from 'axios';
import env from '../env';
import { objectToQueryString } from './questions';

const saveUserStudies = (data, token) => {
  return axios
      .post(env.apiLink+"user/courses", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteUserStudies = (id, token) => {
  return axios
      .delete(env.apiLink+"user/courses/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getUserStudies = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"user/courses?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const getUserStudiesTopics = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"user/courses/study-topics?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};


const saveUserStudiesCareer = (data, token) => {
  return axios
      .post(env.apiLink+"user/careers", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const deleteUserStudiesCareer = (id, token) => {
  return axios
      .delete(env.apiLink+"user/careers/"+id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

const getUserStudiesCareerTopics = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"user/careers?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
}

const getUserStudiesCareers = (filter, token) => {
  const query = objectToQueryString(filter);
  return axios
      .get(env.apiLink+"user/careers/study-careers?"+query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
};

export { saveUserStudies, getUserStudies, deleteUserStudies, getUserStudiesTopics, saveUserStudiesCareer, deleteUserStudiesCareer, getUserStudiesCareers, getUserStudiesCareerTopics }